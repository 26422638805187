<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Patient')"
    appShortcutKey
    [AltKey]="'x'"
>
    <span>X</span> Pat. Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Prescriber')"
    appShortcutKey
    [AltKey]="'y'"
>
    <span>Y</span> Doc Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Drug')"
    appShortcutKey
    [AltKey]="'z'"
>
    <span>Z</span> Drug Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Insurance')"
    appShortcutKey
    [AltKey]="'i'"
>
    <span class = "padding_left_10px"> I </span> Ins. Info
</button>
<span *ngIf="transmResp?.transmissionDetails?.ResponseStatus === 'R' && showPA && !isProrAuth">
<button type="button" class="pull-right pa-{{paStatusId}}-hotkey_success hotkey_success" appShortcutKey [AltKey]="'P'" (click)="PAVendorId && (paStatusId === 0 || paStatusId === 11) ? sendPARequest(PAVendorId) : (PriorApp || cmmResponse || validationErrors) && paStatusId != 11 ? naviagteToPaInfo() : startPADrop.toggle()">
    <span>O</span> {{paStatusName}} 
</button>
<span ngbDropdown class="view--Trans__icon padding_bottom_6px padding_top_7px background-White view--Trans-{{paStatusId}}" #startPADrop="ngbDropdown" *ngIf="paStatusId === 0 || paStatusId === 11 || paStatusId === 7">
    <button class="btn view-trans-arrow view-Trans-arrow-{{paStatusId}}" id="dropdownBasic3" ngbDropdownToggle container="body"> </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
        <span *ngIf="paStatusId === 0 || paStatusId === 11">
            <span *ngFor="let list of PAVendorsList">
                <a class="enable--hotkey__buttons " appShortcutKey placement="top" ngbTooltip="{{list?.vendorName}}" (click)="sendPARequest(list?.vendorId)" container="body">
                    <span class="save--buttons">{{list?.vendorName}} </span>
                </a>
            </span> 
        </span>  
        <span *ngIf="paStatusId === 7">
            <a ngbTooltip="Retry" class="mr-1 enable--hotkey__buttons small_HotKeybuttons padding_bottom_3px" (click)="sendPARequest(PAVendorId)" container="body">
                <span class="save--buttons">Retry</span>
            </a>         
        </span>
    </div>
</span>
</span>
<app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint" [PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

