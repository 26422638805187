





import { PrescAddress } from "./prescriber-info.model";

export class RxModel {
    Drug: EDrug = null;
    DueDtTm: any = null;
    FileDtTm: Date = null;
    FollowUpStatus: string = null;
    Patient: EPatient = null;
    PrescReFill: EPrescReFill = null;
    PrescRefill_RxTags: EPrescRefillRxTag = null;
    Prescriber: EPrescriber = null;
    Prescription: EPrescription = null;
    RefillInsu: ERefillInsu = null;
    ResponseStatus: string = null;
    RphAppUserId: number = null;
    RxAdminDoseSchedule: ERxAdminDoseSchedule = null;
    RxBill: ERxBill = null;
    RxExpirationDate: Date = null;
    RxOtherAmount: EOtherAmnt = null;
    RxPay: ERxPay[] = [];
    SigCodes: ESigCodes = null;
    compounddrugs: string = null;
    dme: string = null;
    drugPickVerif: EDrugPickVerif[] = [];
    drugPickVerificationStatus: string = null;
    eRxDetailsViewModel: string = null;
    insuranceInfo: EInsuranceInfo = null;
    isOriginalRx: boolean = null;
    islatestrefill: boolean = null;
    isnewpatient: boolean = null;
    pharmacy: string = null;
    PickupRx: EPickupRx = null;
    pickupRx: EPickupRx = null;
    prescRefill_Observtions: EPrescRefillObservtions = null;
    rPHVerifs: ERPHVerif = null;
    refillNumberasPerSettings: number = null;
    rph1VerificationStatus: string = null;
    rph2VerificationStatus: string = null;
    rxDelivery: ERxDelivery = null;
    trackRx: string = null;
    workmancomp: string = null;
    drugpickverifdttm: Date = null;
    rxVerifDtTm: Date = null;
    rphverifDtTm: Date =  null;
    isRxTransferred: boolean = false;
    refillinsu: RefillInsuPriority[] = [];
    SubClarifIdComb: any = null;
    RefillInsuList :any = null;
    isSupplyDaysChanged: boolean = false;
    PriorApp: any = null;
    // XmlFileId: number = null;
    // Price: number = null;
    // PaidPrice: number = null;
    // Profit: number = null;
    // ProfitMargin: number = null;
    // DrugTechName: string = null;
    // DrugVerifiedDate: Date = null;
    // DrugStatus: string = null;
    // RphTechName: string = null;
    // RphVerifiedDate: Date = null;
    // RphStatus: string = null;
    // Allergies: string[] = [];
    // facility: string = null;
    // remarks: string = null;
}

export class EPrescription {
    Id: number = null;
    TenantId: number = null;
    PrescNum: number = null;
    PrescSN: string = null;
    PrescriberId: number = null;
    PatientId: number = null;
    DrugId: number = null;
    Qty: number = null;
    ReFillNum = null;
    Remarks: string = null;
    Days: number = null;
    IsDiscontinued: boolean = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    RxRecdById: number = null;
    UpdatedById: number = null;
    RxReceiptDtTm: Date = null;
    RxStatusId: number = null;
    PAType: string = null;
    IsAllType4AllRef: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    UserName: string = null;
    PrescriberAddressId: any = null;

}

export class EPrescReFill {
    IsNewPrescRefill: boolean = null;
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    DrugId: number = null;
    ReFillNum: number = null;
    RxBillId: number = null;
    SalesPerId: number = null;
    DeliveryAddsId: number = null;
    DeliverModeId: number = null;
    FillDtTm: Date = null;
    NextFillDt: Date = null;
    StatusId: number = null;
    DeliveryStatusId: number = null;
    DeliveryPerId: number = null;
    LotNum: string = null;
    ExpDt: Date = null;
    OrderDtTm: Date = null;
    OrderDays = 0;
    SupplyDays = 0;
    PriorAuthNum: string = null;
    TechInitials: string = null;
    RxOriginId: number = null;
    RegPharmacist: string = null;
    UnitPriceAWP: number = null;
    UnitPriceUNC = 0;
    PriceSchId: number = null;
    DiscSchId: number = null;
    Price = 0;
    ProfFee = 0;
    OtherAmnt = 0;
    Discount: number = null;
    CoPay: number = null;
    DispQty = 0;
    OrdQty = 0;
    NoOfLabels: number = null;
    IsPRN: boolean = null;
    IsNonRx: boolean = null;
    IsHOADefined:boolean = null;
    TechnicianId: number = null;
    PharmacistId: number = null;
    RxTransStateId: number = null;
    IsDeleted: boolean = null;
    DeleteDtTm: Date = null;
    IsRxHold: boolean = null;
    DAWId: number = null;
    UpdatedById: number = null;
    Is340B: boolean = null;
    BucketId: number = null;
    SplProgramId: number = null;
    IsControlSubmitted: boolean = null;
    IsControlSubmittedToPh:boolean= null;
    IsCopayPaid: boolean = null;
    ControlSubmittedToPhDtTm: Date = null;
     ControlSubmittedDtTm: Date = null;
     SubmissionStatusId: any = null;
     PhSubmissionStatusId:any = null;
    PAType: string = null;
    BalDue = 0;
    TotalAmt = 0;
    isVerified: boolean = null;
    PrescNum: number = null;
    DawCode: any = null;
    TechFirtsName: string = null;
    TechLastName: string = null;
    PharmFirstName: string = null;
    PharmLastName: string = null;
    GroupTransStatus: string = null;
    OrderStatus: string = null;
    BucketName: string = null;
    OweQty = 0;
    ImmunizationId: any = null;
    NumberOfFills: any = null;
    IsEquivalentDisp: boolean = false;
    ReferencePrescReFillId : number = null;
    CarryFwdBillSequence:boolean = true;
}

export class ERxBill {
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    DrugId: number = null;
    Qty: number = null;
    DosageInstId: number = null;
    DrugPriceId: null;
    ServiceChrg: number = null;
    DeliveryChrg: number = null;
    InsuChrg: number = null;
    CoPayChrg = 0;
    StatusId: number = null;
    BillAs: string = null;
}

export class ERefillInsu {
    Id: number = null;
    TenantId: number = null;
    RefillId: number = null;
    InsuId: number = null;
    InsuPriId = 1;
}

export class ESigCodes {
    Id: number = null;
    TenantId: number = null;
    RxRefillId: number = null;
    SigCode: string = null;
    Description: string = null;
    AltDescription: string = null;
    AlternateLangDescription: string = null;
}

export class EOtherAmnt {
    Id: number = null;
    TenantId: number = null;
    PrescReFillId: number = null;
    ProfFee: number = null;
    Incentive: number = null;
    FlatSalesTax: number = null;
    SalesTaxPer: number = null;
    SalesTax: number = null;
    SalesTaxBasis: number = null;
    PaidAmnt: number = null;
    CostBasisCatId: number = null;
    Name: string = null;
    OtherAmntMisc: any = null;
    Remarks: string = null;
}

export class OtherAmntMisc {
    OtherAmntCatId = 1;
    Amount = 0.00;
}

export class EPrescriber {
    prescriberid: number = null;
    prescriberfirstname: string = null;
    prescriberlastname: string = null;
    prescriberquickcode: string = null;
    prescriberaddressid: number = null;
    prescriberphoneid: number = null;
    prescriberemailid: number = null;
    prescribernotes: string = null;
    activestatusid: number = null;
    prescriberlicensenumber: string = null;
    medaidnum: string = null;
    prescriberdeanum: string = null;
    otheridnum: string = null;
    statespid: string = null;
    prescribercatckde: string = null;
    prescribercatname: string = null;
    prescribertypeid: number = null;
    prescribercatcode: string = null;
    prescidtypename: string = null;
    prescriberidtypeid: number = null;
    prescriberemail: string = null;
    prescriberaddress1: string = null;
    prescriberaddress2: string = null;
    prescriberstateid: number = null;
    prescribercityid: number = null;
    prescriberzipcode: string = null;
    prescribercity: string = null;
    prescriberstate: string = null;
    prescribernpinum: string = null;
    prescriberspinum: string = null;
    presoncreateddate: Date = null;
    personmodifieddate: Date = null;
    citycreateddate: Date = null;
    citymodifieddate: Date = null;
    statecreateddate: Date = null;
    statemodifieddate: Date = null;
    prescribercatcreateddate: Date = null;
    prescribercatmodifieddate: Date = null;
    prescriberidcatcreateddate: Date = null;
    prescriberidcatmodifieddate: Date = null;
    mobile: string = null;
    telephone: string = null;
    worknumber: string = null;
    faxnumber: number = null;
    fulladdress: string = null;
    fullname: string = null;
    prescprimaryspeciality: any = null;
    prescprimaryspecialityid: number = null;
    prescribermiddlename: any = null;
    prescsecondaryspeciality: any = null;
    prescsecondaryspecialityid: number = null;
    presctertiaryspeciality: any = null;
    presctertiaryspecialityid: number = null;
    PrescriberAddresses: PrescAddress[] = [];
    facilityId: number = null;
}

export class EPatient {
    mobilemodifieddate: Date = null;
    personcreateddate: Date = null;
    telephonemodifieddate: Date = null;
    telephone: string = null;
    address1: string = null;
    fulladdress: string = null;
    worknumber: string = null;
    emailmodifieddate: Date = null;
    personmodifieddate: Date = null;
    gender: string = null;
    gendername: string = null;
    workmodifieddate: Date = null;
    dob: Date = null;
    city: string = null;
    lastname: string = null;
    zipcode: string = null;
    telephonecreateddate: Date = null;
    mobile: string = null;
    mobilecreateddate: Date = null;
    email: string = null;
    patientid: number = null;
    addresscreateddate: Date = null;
    workcreateddate: Date = null;
    addressmodifieddate: Date = null;
    chartnum: string = null;
    address2: string = null;
    firstname: string = null;
    emailcreateddate: Date = null;
    langname: string = null;
    fullname: string = null;
    state: string = null;
    middlename: string = null;
    isdeceased: boolean = null;
    insuranceid: number = null;
    insurance: PatInsurance[] = [];
    PatientFacilityInfo: any = null;
    CustomCategory: any[] = [];
    notes: string = null;
    isPatientAck: boolean = null;
    Charge: any = null;
    RegPharmacist: any = null;
    patallergies: any[] = [];
    NcpdpId: any = null;
    Is340B: boolean = null;
}


export class EDrug {
    awppack: number = null;
    costpack: number = null;
    directunitprice: number = null;
    discontdt: Date = null;
    drugbandname: string = null;
    drugclass: number = null;
    drugcode: string = null;
    drugcreateddate: Date = null;
    drugformid: number = null;
    drugname: string = null;
    drugtypeid: number = null;
    drugtype: string = null;
    drugunitid: number = null;
    drugunit: string = null;
    isdiscontdate: string = null;
    genericname: string = null;
    id: number = null;
    isactive: boolean = null;
    ispreferred: boolean = null;
    isdeleted: boolean = null;
    manufactid: number = null;
    ndc: string = null;
    qtypack: number = null;
    quickcode: string = null;
    strength: string = null;
    unitpricecost: number = null;
    manufacturername: string = null;
    drugformname: string = null;
    unitpriceawp = 0;
    therapeutic: number = null;
    therapeuticcode: number = null;
    drugdescription: EDrugdescription[] = [];
    dosageinformation: EDosageinformation[] = [];
    drugsuggest: EDrugSuggest[] = [];
    bucketList: null;
    dispensedDate: Date = null;
    drugfullname: string = null;
    druglabelname: string = null;
    lastDispensedDate: Date = null;
    billingndc: string = null;
    brandorgenndc: string = null;
    defaultSigCode: string = null;
    defaultdays: number = null;
    defaultqty: number = null;
    drugCounsellingDescription: any = null;
    drugformSigCodeId: number = null;
    drugformsigcode: any = null;
    druguseSigCodeId: any = null;
    drugusesigcode: any = null;
    elasticsearchid: any = null;
    instructions: any = null;
    isFDAapprovednull: any = null;
    isMaintainanceDrug: any = null;
    isbranded: boolean = null;
    isdiscountable: boolean = null;
    isincinrefillrem: boolean = null;
    isexclfrmupdate: boolean = null;
    isinvmangd: boolean = null;
    ispriceupdateauto: boolean = null;
    iswarningcodechkd: boolean = null;
    measureunitid: number = null;
    measureunitname: string = null;
    ndcqualiId: number = null;
    notes: any = null;
    oldndc: any = null;
    perofbrand: any = null;
    priceschdid: any = null;
    qtyInHand: any = null;
    quantityInHandEqui: any = null;
    replacendc: any = null;
    sigid: any = null;
    surcharge: any = null;
    unitdoseind: any = null;
    unitdoseindid: any = null;
    upc: any = null;
    warningcode: any = null;
    manageinventory: any = null;
    Is340B: boolean = null;
    ExpirationDate:string = null;
    GTIN:string = null;
    LotNum:string = null;
    SerialNumber:string = null;
    licenseTypeId: number = null;
    equivalenceCodes: any = null;
    productModifierIds: any = null;
    productId: number = null;
    DiagnosisId: any = null;
}

export class EDrugSuggest {
    input: string = null;
    weight: string = null;
}

export class EDrugdescription {
    ndc: string = null;
    drugitemid: number = null;
    version: number = null;
    descrip: string = null;
    shape: string = null;
    color_frnt: string = null;
    color_back: string = null;
    id_front: string = null;
    id_back: string = null;
    flavorname: string = null;
    coatingname: string = null;
    scoringname: string = null;
}

export class EDosageinformation {
    AGEBANDNAME: string = null;
    FromAgeDays: string = null;
    ToAgeDays: string = null;
    MINDAILYUNITS: string = null;
    MAXDAILYUNITS: string = null;
    USUALDAILYUNITS: string = null;
    INGREDIENTNAME: string = null;
    MINDAILYDOSE: string = null;
    MAXDAILYDOSE: string = null;
    USUALDAILYDOSE: string = null;
    FREQUENCY: number = null;
    FREQUENCYDAYS: number = null;
    LIFETIMEMAX: string = null;
    PATIENTEXPERIENCE: string = null;
    DOSINGTYPE: string = null;
    HEPATICIMPAIRMENTMAYREQUIREDOSEADJUSTMENT: boolean = null;
    RENALIMPAIRMENTMAYREQUIREDOSEADJUSTMENT: boolean = null;
}

export class ERxAdminDoseSchedule {
    RxAdmin: ERxAdmin = null;
    RxDoseSchs: ERxDoseSchs[] = [];
}

export class ERxAdmin {
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
    PrescRefillId: null;
    StartDt: Date = null;
    EndDt: Date = null;
    RepeatDays: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    DoseScheduleId: number = null;
    dosePerDay: any = null;
}

export class ERxDoseSchs {
    Id: number = null;
    TenantId: number = null;
    RxAdminId: number = null;
    DoseSeqNum: number = null;
    DoseQty = 0;
    DoseTime: any = null;
    DoseTimeCatId = 1;
    DoseSlot: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}


export class EInsuranceInfo {
    Insurance: EInsurance = null;
    organization: EOrganization = null;
    insuCarrier: EInsuCarrier = null;
    insuPlan: EInsuPlan = null;
}

export class EInsurance {
    Id: number = null;
    TenantId: number = null;
    InsurerId: number = null;
    InsuGrpId: number = null;
    RelCatId: number = null;
    InsuCardId: number = null;
    InsuPriId: number = null;
    IdProofId: number = null;
    CoPayCatId: number = null;
    CPBrandVal: number = null;
    CPGenericVal: number = null;
    PerNum: string = null;
    Remarks: string = null;
    IsPatAssigned: boolean = null;
    ActiveStatusId: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    UpdatedById: number = null;
    CreatedById: number = null;
}

export class PatInsurance {
    cardholderfirstname: string = null;
    chlastname: string = null;
    copaycatid: number = null;
    cpbrandval: number = null;
    expirydt: Date = null;
    groupname: string = null;
    insuType: string = null;
    insuplanId: any = null;
    insuranceid: number = null;
    insurancename: string = null;
    patientidtype: any = null;
    policynum: string = null;
    relation: string = null;
    cpgenericval: number = null;
    idproofid: number = null;
    insurerid: number = null;
    pernum: string = null;
    relcatid: number = null;
}

export class EOrganization {
    Id: number = null;
    TenantId: number = null;
    Name: string = null;
    ContPerson: string = null;
    WebURL: string = null;
    PrimeAddsId: number = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class EInsuCarrier {
    Id: number = null;
    TenantId: number = null;
    InsurerCode: string = null;
    BINNum: string = null;
    Remarks: string = null;
}

export class EInsuPlan {
    Id: number = null;
    TenantId: number = null;
    InsuCarrierId: number = null;
    PlanCode: string = null;
    PharmacyNum: string = null;
    InsuPlanIdQualifId: number = null;
    ProcessorNum: string = null;
    ProcCatId: number = null;
    PayorId: number = null;
    IsMedicaidSup: boolean = null;
    IsMedigapSup: boolean = null;
    IsMedicareSup: boolean = null;
    MedicaidAgNum: string = null;
    Remarks: string = null;
    IsDeleted: boolean = null;
    IsActive: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class EInsuTransOthers {
        Id: number = null;
        TenantId: number = null;
        InsuPlanId: number = null;
        BatchQty: number = null;
        IsPASupport: boolean = null;
        IsRebillSupport: boolean = null;
        IsPartialFill: boolean = null;
        IsPatPaidAsCopay: boolean = null;
        VendCert: string = null;
        PayorIdQualifId: number = null;
        PayorNum: string = null;
        ReceiverNum: string = null;
        COBHCReqQualif: any = null;
        DefOthrCovCode: any = null;
        OthrPayorCovCode: any = null;
        ForceCoBSeg: any = null;
        NCDPId: number = null;
        COBMethodId: number = null;
        DefServPlace: any = null;
        IsOPPRAFoced: boolean = null;
        IsQualifForced: boolean = null;
        IsOthConvCodeSent: boolean = null;
        IsSubClarifCodeSent: boolean = null;
        IsLeadingZeroSent: boolean = null;
}

export class EDrugPickVerif {
    UserName: string = null;
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    PrescRefillId: number = null;
    Result: string = null;
    BottleNDC: string = null;
    VerifiedById: number = null;
    VerifDtTm: Date = null;
}

export class EPrescRefillObservtions {
    Id: number = null;
    PrescRefillId: number = null;
    IsDrugDisContraInd: boolean = null;
    IsDrugToDrugIntra: boolean = null;
    IsDupDrugTherapy: boolean = null;
    IsDrugAllergy: boolean = null;
    IsPregLact: boolean = null;
    IsAdvrDrugReaction: boolean = null;
    IsDrugLifeStyle: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class ERxDelivery {
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
    IsShipped: boolean = null;
    IsFacility: boolean = null;
    FacilityId: number = null;
    AddressId: number = null;
    PriorityId: number = null;
    ReqdDtTm: Date = null;
    DeliveryInstru: null;
    Remarks: string = null;
}

export class ERPHVerif {
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    PrescRefillId: number = null;
    VerifiedById: number = null;
    VerifDtTm: Date = null;
    Observation: string = null;
    Status: string = null;
    Reason: string = null;
}

export class EPrescRefillRxTag {
    Id: number = null;
    TenantId: number = null;
    PrescRefillId: number = null;
    RxTagId: number = null;
    IsDeleted: boolean = null;
}

export class EPickupRx {
    Id: number = null;
    TenantId: number = null;
    RxRefillId: number = null;
    ExpDtTm: Date = null;
    IsPickedUp: boolean = null;
    ActDtTm: Date = null;
    PkFirstName: string = null;
    PkLastName: string = null;
    RelationCatId: number = null;
    PkIdNum: string = null;
    PkIdProofId: number = null;
    PkIdIssuer: string = null;
    Remarks: string = null;
    HandOverById: number = null;
}

export class NoteOrderStatus {
    Id: number = null;
    RxId: number = null;
    DiscontReasonId: number = null;
    PrescReFillId: number = null;
    TenantId: number = null;
    Note: string = null;
}

export class ERxPay {

}


export class CloneRx {
    PatientId: number = null;
    PrescriberId: number = null;
    DrugId: number = null;
    RxId: number = null;
    IsProcessed: boolean = null;
    eRxId: number = null;
}

export class RxFollowTag {
    Name: string = null;
    Action: string = null;
    Id: number = null;
    TenantId: number = null;
    PrescRefillId: number = null;
    RxTagId: number = null;
    IsDeleted: boolean = null;
}

export class RxInputParams {
    patientId: number = null;
    prescriberId: number = null;
    drugId: number = null;
    prescNum: string = null;
    refillNum: number = null;
    remarks: string = null;
    rxId : number = null;
    prescRefillId: number = null;
}

export class RefillRxStats {
    rxId: number = null;
    status: string = null;
}


export class RefillInsuPriority {
    CreatedDtTm: any = null;
    Id: any = null;
    InsuId: any = null;
    InsuPriId: any = null;
    InsurerId: any = null;
    ModifiedDtTm: any = null;
    RefillId: any = null;
    TenantId: any = null;
 }

 export class IntakeQueue {
    FromDate : any = null;
    ToDate : any = null;
    StatusId: any = null;
    TypeIds: any = null;
    SearchString: string = "";
    PageNumber: number = null;
    PageSize: number = null;
    DefaultERxActionListSort: any = null;
    isInsuranceVisible: boolean = true
}
export class FileGeneratorList {
    StartDate : any = null;
    EndDate : any = null;
    patientId: any = null;
    HOATypeId: boolean = false;
    PageNumber: number = null;
    PageSize: number = null;
}

export class VaccineImmunization {
    Id: any = null;
    TenantId: any = null;
    PatientId: any = null;
    PrescRefillId: number = null;
    PrescNum: any= null;
    RefillNum: any = null;
    FillNo: any = null;
    MinorConsentId: number = null;
    PatientConsentId: number = null;
    Incentive: any = null;
    CostBasisId: number = null;
    SubClarifId: number = null;
    AdminRouteId: number = null;
    AdminSiteId: number = null;
    UserId: number = null;
    AdminProvSuffixId: number = null;
    CompletionStatusId: number = null;
    MissedVaccineApprId: number = null;
    CVXCodeDesc: string = null;
    DoseNum: number = null;
    VISPresentDtTm: Date = null;
    VISPublishDtTm: Date = null;
    VFCEligibilityId: number = null;
    PriorityGrpId: any = null;
    SerologyResultsId: number = null;
    VaccinationRefusalId: number = null;
    GuardienLastName: string = null;
    GuardienFirstName: string = null;
    MaidenMiddleName: string = null;
    PatientRaceId: any = null;
    PatientRaceName: string = null;
    PatientEthnicityId: any = null;
    PatientComorbidityStatusId: any = null;
    PatientRelationId: any = null;
    Incentive_Old: number = null;
    drugLot: any = null;
    lotExpiryDate: Date = null;
    drugBillingUnits: any = null;
    DrgManufactId: any =null;
    FundingSrcId: any = null;
    DrugLotId: any = null;
    DiagnosisId: any= null;
    // NDC: any = null;
    // drugName: any =null;
}

export class ImmunizationGeneratorList {
    fromDate : any = null;
    toDate : any = null;
    fromTime: any = null;
    toTime: any= null;
    patientId: any = null;
    vaccineType: any = null;
    completeStatus: any = null;
    PageNumber: number = null;
    PageSize: number = null;
    FrmTime1:any=null;
    ToTime1:any=null;
}

export class PAQueue {
    FromDate : any = null;
    ToDate : any = null;
    PAVendorIdentifier : any = null;
    PAQueueFilterIdentifier : any = null;
    PAStatus : any = null;
    SearchString: string = "";
    PageNumber: number = null;
    PageSize: number = null;
}