import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService, DocumentsService, AlertService, UserService, DMSService } from "src/app/services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NewAuditUtils, AuditLogUtils, ValidateFormFieldsUtils, WijimoUtil, CommonUtil } from "src/app/utils";
import * as moment from "moment";
import { RegEx } from "src/app/app.regex";
import { constant, MsgConfig } from "src/app";
import { SystemData, SharedDocuments, DocumentInputParams, Documents, RxModel, AccCategoryE, EditRxE } from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import { ImageEditorComponent } from "..";
import { PrivMaskGuard } from "src/app/guards";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { PrintService } from "src/app/services/print.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-document-ref",
  templateUrl: "./document-ref.component.html"
})
export class DocumentRefComponent implements OnInit {
    today: string;
    imageToShow: any;
    route: string;
    isImageUpload : boolean = false;
    systemData: SystemData;
    getDocParams = new DocumentInputParams();
    docUploadFG: FormGroup;
    selectedDocuement: any;
    insuranceID: number;
    documentsWJ: CollectionView;
    wjHeaders: any;
    functionScreen: string;
    documentsList: any;
    showGrid: boolean;
    subCatDropdowns: any[];
    subCatDropdownsList: any[];
    fileTypeToShow: any;
    editMode = false;
    uploadedFile: any;
    src: any;
    modalRef: any;
    originalData: any;
    innerHtml: SafeHtml;
    showEditImage = false;
    rxFG: FormGroup;
    subscriptions = new Subscription();
    @Input() documentFr: any;
    documentEdited: boolean;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    @Input() set RxFG(fg: FormGroup) {
        if (fg) {
            this.rxFG = fg;
            let fgVal: RxModel = fg.getRawValue();
            this.documentFr = { From: 'Rx', Id: fgVal.Prescription.PrescNum, Refill: fgVal.PrescReFill.ReFillNum , FillNum: fgVal.PrescReFill.PartialFillNo, PrescRefillId: fgVal.PrescReFill.Id}
        }
    }

    @Output() RefreshParentEntity = new EventEmitter<boolean>();
    addFileType: string;

    @Output() IsPopUpClosed = new EventEmitter<any>();
    
    constructor(
        private modalService: NgbModal,
        private _commonServ: CommonService,
        private _fb: FormBuilder,
        private _docService: DocumentsService,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private sanitizer: DomSanitizer,
        private _actvRoute: ActivatedRoute,
        private _NewAuditUtils: NewAuditUtils,
        private _auditUtils: AuditLogUtils,
        private _userService: UserService,
        private _dmsSer: DMSService,
        private _formUtils:ValidateFormFieldsUtils,
        private _wijimoUtils: WijimoUtil,
        private _prvMskGrd: PrivMaskGuard,
        private _alertSvc: AlertService,
        private _printServ: PrintService,
        public _commonUtil: CommonUtil
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.imageToShow = "";
        this.route = window.location.href;
    }

    ngOnInit() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        this.createFG();
        this.getInfo();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "DocumentsListWJ") {
                this.patchDefaultValHeaders();
            }
        });
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    createFG() {
        this.docUploadFG = this._fb.group(new Documents());
    }

    getInfo() {
        this.getDocParams.DocSubCatName = "All";
        if (this.documentFr && this.documentFr["From"] === "Patient") {
            this.getDocParams.PatientId = this.documentFr["Id"];
            this.docUploadFG.controls.PatientId.patchValue(this.documentFr["Id"]);
            this.getDocParams.DocCatId = SharedDocuments.Patient;
            this.functionScreen = SharedDocuments[1];
            this.getDocs();
        } else if (this.documentFr && this.documentFr["From"] === "Prescriber") {
            this.getDocParams.PrescId = this.documentFr["Id"];
            this.docUploadFG.controls.PrescId.patchValue(this.documentFr["Id"]);
            this.getDocParams.DocCatId = SharedDocuments.Prescriber;
            this.functionScreen = SharedDocuments[2];
            this.getDocs();
        } else if (this.documentFr && this.documentFr["From"] === "Drug") {
            this.getDocParams.DrugId = this.documentFr["Id"];
            this.docUploadFG.controls.DrugId.patchValue(this.documentFr["Id"]);
            this.getDocParams.DocCatId = SharedDocuments.Drug;
            this.functionScreen = SharedDocuments[3];
            this.getDocs();
        } else if (this.documentFr && this.documentFr["From"] === "Insurance") {
            this.getDocParams.InsuranceId = this.documentFr["Id"];
            this.docUploadFG.controls.InsuranceId.patchValue(this.documentFr["Id"]);
            this.getDocParams.DocCatId = SharedDocuments.Insurance;
            this.functionScreen = SharedDocuments[4];
            this.getDocs();
        } else if (this.documentFr && this.documentFr["From"] === "Rx") {
            this.getDocParams.PrescNum = this.documentFr["Id"];
            this.getDocParams.RefillNum = this.documentFr["Refill"];
            this.getDocParams.FillNum = this.documentFr["FillNum"];
            this.docUploadFG.controls.PrescNum.patchValue(this.documentFr["Id"]);
            this.getDocParams.DocCatId = SharedDocuments.Prescription;
            this.functionScreen = SharedDocuments[5];
            this.getDocs();
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("DocumentsListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("DocumentsListWJ", storedWJ);
    }

    async getDocs() {
        if (!this.subCatDropdowns) {
            await this.getSubCats();
        } else {
            this.docUploadFG.controls.DocSubCatIdForDefault.patchValue(
                (this.getDocParams.DocSubCatName === "All") ? 10456 : this.getDocParams.DocSubCatId );
        }
        this.getDocParams.DocSubCatId  = (this.getDocParams.DocSubCatName === "All") ? null : this.getDocParams.DocSubCatId;
        this._docService.getDocList(this.getDocParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (resp && resp.Documents && resp.Documents.length) {
                    let document1: any = [], document2: any = [];
                    document1 = resp.Documents.filter((ele: any) => {
                        return (ele.Priority === 0);
                    });
                    document2 = resp.Documents.filter((ele: any) => {
                        return (ele.Priority !== 0);
                    });
                    document2.sort((a, b) => {
                        return a.Priority  - b.Priority;
                    });
                    resp.Documents = [...document2, ...document1];
                    this.documentsList = resp.Documents;
                }
                if (this.documentsList && this.documentsList.length) {
                    this.showGrid = true;
                    this.isDataExists = true;
                    this.generateList(this.documentsList);
                    if (this.documentsList[0].Extn === "pdf") {
                        this.fileTypeToShow = "pdf";
                    } else {
                        this.fileTypeToShow = "image";
                    }
                    this.previewSelectedFile(this.documentsList[0])
                    this.patchDocumentExistsValue(true);
                } else {
                    this.showGrid = false;
                    this.isDataExists = false;
                    this.generateList(null);
                    this.documentsWJ = new CollectionView(null)
                    this.imageToShow = "";
                    this.fileTypeToShow = null;
                    this.patchDocumentExistsValue(false);
                }
            }
        });
    }

    selectSubCategory(event) {
        if (event && event.value && event.value.Id) {
            this.getDocParams.DocSubCatId = event.value.Id;
            this.getDocParams.DocSubCatName = event.value.Name;
            this.getDocs();
        }
    }
    patchDocumentExistsValue(type) {
        if (this.documentFr && this.documentFr["From"] === "Rx") {
            this.rxFG.patchValue({
                IsDocumentExists : type
            });
        }
    }
    generateList(data: any) {
        if (data) {
            this.documentsWJ = new CollectionView(
                data.map((document, i) => {
                    const j = {};
                    j["data"] = document;
                    j["row"] = i;
                    j["Id"] = document.Id;
                    j["Date"] = moment.utc(document.CreatedDtTm).local().format("MM/DD/YYYY");
                    j["Document Name"] = document.DocFileName;
                    j["Title"] = document.Name;
                    j["Category"] = SharedDocuments[document.DocCatId];
                    let subcat: any;
                    if (this.subCatDropdowns && this.subCatDropdowns.length) {
                         subcat = this.subCatDropdowns.find(elm => elm.Id === document.DocSubCatId);
                    }
                    j["Subcategory"] = subcat ? subcat.Name : "";
                    j["Comment"] = document.Remarks === "null" ? "" : document.Remarks;
                    j["extn"] = document.Extn;
                    j["FileName"] = document.FileName;
                    j["Print With Label"] = document.PrintWithLabel ? "Yes" : "No";
                    j["TenantId"] = document.TenantId;
                    j["CreatedDtTm"] = document.CreatedDtTm;
                    j["ModifiedDtTm"] = document.ModifiedDtTm;
                    return j;
                })
            );
        }
    }

    async getSubCats() {
        let resp = await this._docService.getDocumentSubCats({ CatId: this.getDocParams.DocCatId }).toPromise();
        resp = resp.filter((i: any) => i.Name !== 'eRx Image' && i.Name !== 'Auto Generated Image');
        if (resp) {
            this.subCatDropdowns = resp;
            this.subCatDropdownsList = [...resp];
            const defaultAll: any =      {
                        "CreatedDtTm" : "2021-08-09T12:46:20.787",
                        "DocCatId" : 1,
                        "DocCatName" : null,
                        "Id" : 10456,
                        "IsActive" : true,
                        "IsDefault" : false,
                        "ModifiedDtTm" : "2021-08-09T12:46:20.787",
                        "Name" : "All",
                        "Remarks" : null,
                        "TenantId" : -7101307930,
                    }
            this.subCatDropdowns.unshift(defaultAll);
            let rxSubCat  = resp.find(elm => elm.Name === 'All') ;
            if (rxSubCat && rxSubCat.Id) {
                this.docUploadFG.controls.DocSubCatId.patchValue(rxSubCat.Id);
                this.docUploadFG.controls.DocSubCatIdForDefault.patchValue(rxSubCat.Id);
                this.getDocParams.DocSubCatId = rxSubCat.Id ;
            }
        }
    }


    downloadSelectedFile(val: any) {
        this._docService.getDocumentByName(val)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.setUpForDownload(resp, val["FileName"]);
                this.createImageFromBlob(resp);
            }
        });
    }

    previewSelectedFile(val: any) {
        this._docService.getDocumentByName(val)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (resp.type === "application/pdf") {
                    this.fileTypeToShow = "pdf";
                } else {
                    this.fileTypeToShow = "image";
                }
                this.createImageFromBlob(resp);
            }
        });
    }
    printTheDocOrImage(val) {
        this._docService.getDocumentByName(val)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.size > 0) {
                this.printDocument(resp);
            }
        })
    }
    printDocument(resp) {
        const newBlob = new Blob([resp], { type: resp.type});
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            if(resp.type === "application/pdf"){
                this._printServ.printPdf(base64data);
            } else{
                this._printServ.print(base64data);
            }
        }.bind(this);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    fileUploaded(inputFile) {
        this.isImageUpload = true;
        this.uploadedFile = inputFile;
        if (inputFile.type === "application/pdf") {
            // this.fileTypeToShow = "pdf";
            this.addFileType = "pdf";
        } else {
            // this.fileTypeToShow = "image";
            this.addFileType = "image";
        }
        this.readsUrl(inputFile);
    }

    opendocModal(docData) {
        if (this._prvMskGrd.canActivate(AccCategoryE.EditRx, EditRxE.AttachDocumentToRx)) {
            this.editMode = false;
            this.resetAndPatchDefaults();
            this.modalRef=this.modalService.open(docData, { size: "lg", keyboard: false, backdrop: 'static',centered: true ,
            windowClass: "max-modal-75",});
        } else {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
    }

    resetAndPatchDefaults() {
        this.docUploadFG.reset();
        this.docUploadFG.patchValue(this.getDocParams);
        this.addFileType = null;
        // this.fileTypeToShow = null;
        this.src = "";
        this.uploadedFile = null;
    }

    saveDocuments() {
        if (this.docUploadFG.valid) {
            if (!this.isImageUpload) {
                this._alertService.error("Please Upload the  Document or Scan  the Document ");
                return;
            }
            let fd = new FormData();

            const docSubCatId =  this.subCatDropdowns.filter((item: any) => {
                return (item.Id === this.docUploadFG.value.DocSubCatId)
           });
            if(docSubCatId && docSubCatId.length) {
                if(docSubCatId[0]["Name"] === "delivery slip" || docSubCatId[0]["Name"] === "drug pick vrf") {
                    this.docUploadFG.controls["FillNum"].patchValue(this.rxFG.value.PrescReFill.PartialFillNo);
                    this.docUploadFG.controls["RefillNum"].patchValue(this.rxFG.value.PrescReFill.ReFillNum);
                } else {
                    this.docUploadFG.controls["FillNum"].patchValue(null);
                    this.docUploadFG.controls["RefillNum"].patchValue(null);
                }
            }
            this.docUploadFG.value["isRotate"] = false;
            this.docUploadFG.value["degree"] = 360;
            this.docUploadFG.value["PrintWithLabel"] = !(this.docUploadFG.value["PrintWithLabel"]) ? false : true;
            this.docUploadFG.value["FileName"] = this.uploadedFile?.name;
            fd.append("DocInputParams", JSON.stringify(this.docUploadFG.value));

            this._commonUtil.convertFile(this.uploadedFile).subscribe(base64 => {
                fd.append("fileKey",base64);
                this._docService.uploadNewDocument(fd,this.uploadedFile?.size)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (!this.documentsList || this.documentsList.length === 0) {
                        this.RefreshParentEntity.emit(true);
                    }
                    this.modalRef.close();
                    this.saveAuditChange(null);
                    this.getDocs();
                }); 
            });
        } else {
            this._formUtils.validateAllFormFields(this.docUploadFG);
        }
    }

    

    editDocPopup(docData, val: any) {
        if (this._prvMskGrd.canActivate(AccCategoryE.EditRx, EditRxE.EditADocument)) {
            this.documentEdited = false;
            this.editMode = true;
            this.resetAndPatchDefaults();
            this.selectedDocuement = val.data;
            this.docUploadFG.patchValue(val.data);
            this.editSelectedFile(val.data);
            this.modalRef = this.modalService.open(docData, {
            size: "lg",
            centered: true,
            keyboard: false,
            windowClass: "max-modal-75",
            backdrop: 'static'
            });
            this.originalData = Object.assign({}, val.data);
        } else {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        
    }

    editSelectedFile(val: any) {
        this._docService.getDocumentByName(val)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (resp.type === "application/pdf") {
                    this.addFileType = "pdf";
                } else {
                    this.addFileType = "image";
                }
                this.uploadedFile = resp;
                this.readsUrl(resp);
            }
        });
    }
   onRowDblclicked(s: any, e: any) {
       const ht = s.hitTest(e);
        if (s.selectedRows && s.selectedRows.length && s.selectedRows[0].dataItem && ht.cellType === 1) {
            const index = this.documentsList.findIndex((ele: any) => {
                return (ele.Id === s.selectedRows[0].dataItem.Id);
            });
            this.previewSelectedFile(this.documentsList[index]);
        }
    }
    updateDocuments() {
        if (this.docUploadFG.valid) {
            if (this.documentEdited) {
                this.docUploadFG.controls.Name.patchValue(this.docUploadFG.value.Name +"_1");
                this.saveDocuments();
            } else {
                this._docService
                .updateDocuments(this.docUploadFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.saveAuditChange(this.originalData);
                        this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                        this.modalRef.close();
                        this.getDocs();
                    } else {
                        this._alertService.error(MsgConfig.UPDATE_FAIL);
                    }
                });
            }
        } else {
            this._formUtils.validateAllFormFields(this.docUploadFG);
        }
    }
    saveAuditChange(originalData) {
    if (this.getDocParams.PatientId) {
        this.saveAuditDoucmentChanges(originalData, this.getDocParams.PatientId, "Patient");
    } else if (this.getDocParams.PrescId) {
        this.saveAuditDoucmentChanges(originalData, this.getDocParams.PrescId, "Prescriber");
    } else if (this.getDocParams.InsuranceId) {
        this.saveAuditDoucmentChanges(originalData, this.getDocParams.InsuranceId, "Insurance");
    } else if (this.getDocParams.DrugId) {
        this.saveAuditDoucmentChanges(originalData, this.getDocParams.DrugId, "Drug");
    } else if (this.getDocParams.PrescNum) {
        this.saveAuditDoucmentChanges(originalData, this.documentFr.PrescRefillId, "Rx");
    }
}
saveAuditDoucmentChanges(originalData, id, type) {
    const documentModel = [{
        HeaderName: "Name",
        ControlName: "Name"
    }, {
        HeaderName: "Remarks",
        ControlName: "Remarks"
    }, {
        HeaderName: "Subcategory",
        ControlName: "DocSubCatId"
    }];
    this._NewAuditUtils.saveAuditChange(
        originalData,
        this.docUploadFG.value,
        "Documents",
        type,
        id,
        documentModel,
        false,
        this.subCatDropdownsList
    );
}
    deleteDocPopup(deleteInfo) {
        if (this._prvMskGrd.canActivate(AccCategoryE.EditRx, EditRxE.DeleteADocument)) {
            this.modalService.open(deleteInfo, { centered: true , keyboard: false, windowClass: "large--content" });
        } else {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
    }

    async deleteDoc(deleteItem, addToQueue?) {
        let resp;
        if (addToQueue) {
            resp = await this._docService.addDeletedDocToQueue(deleteItem["Id"]).toPromise();
        } else {
            resp = await this._docService.deleteDocument(deleteItem.data).toPromise();
        }
        if (resp) {
            if (this.documentsList && this.documentsList.length === 1) {
                this.RefreshParentEntity.emit(true);
            }
            if (this.getDocParams.PatientId) {
                this._NewAuditUtils.SaveAuditOnDelete(
                    null,
                    [{name: "Deleted Document Id", value: deleteItem["Id"]}],
                    "Delete Documents",
                    "Patient",
                    this.getDocParams.PatientId
                );
            } else if (this.getDocParams.DrugId) {
                this._NewAuditUtils.SaveAuditOnDelete(
                    null,
                    [{name: "Deleted Document Id", value: deleteItem["Id"]}],
                    "Delete Documents",
                    "Drug",
                    this.getDocParams.DrugId
                );
            } else if (this.getDocParams.PrescId) {
                this._NewAuditUtils.SaveAuditOnDelete(
                    null,
                    [{name: "Deleted Document Id", value: deleteItem["Id"]}],
                    "Delete Documents",
                    "Prescriber",
                    this.documentFr.PrescRefillId
                );
            } else if (this.getDocParams.InsuranceId) {
                this._NewAuditUtils.SaveAuditOnDelete(
                    null,
                    [{name: "Deleted Document Id", value: deleteItem["Id"]}],
                    "Delete Documents",
                    "Insurance",
                    this.getDocParams.InsuranceId
                );
            }  else if (this.getDocParams.PrescNum) {
                this._NewAuditUtils.SaveAuditOnDelete(
                    null,
                    [{name: "Deleted Document Id", value: deleteItem["Id"]}],
                    "Delete Documents",
                    "Rx",
                    this.documentFr.PrescRefillId
                );
            }
            this._alertService.success(MsgConfig.DELETE_SUCCESS);
            this.getDocs();
        }
    }

    createImageFromBlob(image: Blob) {
        const urlCreator = window.URL;
        let urlCre = urlCreator.createObjectURL(image);
        this.imageToShow = this.sanitizer.bypassSecurityTrustResourceUrl(urlCre);
    }

    setUpForDownload(response: any, fileName: any) {
        const extensionTag = fileName.split(".");
        const extensionTagChrtrs = extensionTag.pop();
        const a: any = document.createElement("a");
       // document.body.appendChild(a);
        a.style = "display: none";
        const data: any = response;
        const url = URL.createObjectURL(data);
        a.href = url;
        a.download = fileName;
        a.click();
       // a.parentNode.removeChild(a);
    }

    addEventonWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.opendocModal(content);
            }
        }
    }

    Init(content,grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                this.editDocPopup(content, grid.collectionView["_src"][grid.selection.row]);
            }
        });
    }

    // editEventOnWijimo(content, event, grid) {
    //     if (grid["selectedItems"][0]) {
    //         event.preventDefault();
    //         this.editDocPopup(content, grid["selectedItems"][0]);
    //     }
    // }

    focusOutFmAdd(event) {
        if (!this.showGrid) {
            this.focusOnCancel(event);
        }
    }

    focusOnCancel(event) {
        if (document.getElementById("docCancelBut")) {
            event.preventDefault();
            document.getElementById("docCancelBut").focus();
        }
    }

    editImage() {
        if (this.uploadedFile) {
            this.showEditImage = false;
            setTimeout(() => {
                this.showEditImage = true;
            }, 1000);
            this.showEditImage = true;
        }
    }
    onEditImageClose(base64) {
        this.showEditImage = false;
        if (base64) {
            this.documentEdited = true;
            this.urltoFile(base64, "Edited.png", "image/png")
                .then((file) => {
                    this.fileUploaded(file);
                });
        }
    }
    urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }))
        );
    }
    closeModal() {
            if (this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
            this.showEditImage = false;
            this.getSubCats();
    }
    readsUrl(event: any) {
        if (event) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                let urlCre = data.target.result;
                // this.src = this.sanitizer.bypassSecurityTrustResourceUrl(urlCre);
                if (this.addFileType == 'pdf') {
                    // urlCre = "<object style='width:600px' width='600' height='600' data='" + urlCre + "' type='application/pdf' class='embed-responsive-item'>" +
                    //     "Object " + urlCre + " failed" +
                    //     "</object>";
                    // this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(urlCre);
                    this.innerHtml = urlCre;
                    // this.innerHtml = this.sanitizer.bypassSecurityTrustResourceUrl(urlCre);
                } else {
                    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(urlCre);
                }
            };
            reader.readAsDataURL(event);
        }
    }
    previousPage() {
        this.IsPopUpClosed.emit("Patient1");
    }
}
