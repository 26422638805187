/*

  This is a common Input component. For this component props 'LabelText' has to send for Input label, 'IsError'
  to display input has errorm 'PlaceHolder' for place holder text and 'ControlName' for form control name.

  '@ContentChildren' is for accessing varibles or elements of 'ErrorsComponent

  Created a 'FormGroup' for storing 'FormControl' which is passed from parent component. Whenever there is a change in the value
  that will be reflected back to parentComponent because objects are Mutable.

  'addControl' will add the control to 'customFG' which is passes as input from parent component.
*/

import {
    Component,
    OnInit,
    Input,
    Output,
    ChangeDetectorRef,
    EventEmitter,
    ElementRef, 
    OnChanges, 
    NgZone
} from "@angular/core";
import { FormGroup, AbstractControl, ValidatorFn } from "@angular/forms";
import { MaskConstant } from "../../../app.mask-patterns";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { UdpCurrencyMaskPipe } from "../../../pipes";
import { CommonService, InsuranceService } from "src/app/services";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "eprx-input",
    templateUrl: "./input.component.html"
   
})
export class InputComponent implements OnInit, OnChanges {
    hasFGInvalid: boolean;
    dropSpclChar = true;
    autoFocus: boolean;
    formatType: string;
    OnChange: boolean;
    showMask = false;
    beforeDecValue: any;
    showClear=false;
    minValue: any;
    maxValue: any;
    partialStatus: any;
    rxType: any;
    OrdSupplyDaysValidate: any;
    barCodeDetector: any;
    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set PlaceHolder(ph: string) {
        this.placeHolder = ph;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input()
    set DebounceTime(dt: number) {
        this.debounceTime = dt;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
    }

    @Input()
    set DropSpclChar(ir: boolean) {
        this.dropSpclChar = ir;
    }
    @Input()
    set MinLength(ml: number) {
        this.minLength = ml;
    }

    @Input()
    set Title(title: string) {
        this.titleText = title;
    }

    @Input()
    set MaxLength(ml: number) {
        this.maxLength = ml;
    }

    @Input()
    set ValidPattern(dt: string) {
        this.validPattern = dt;
    }

    @Input()
    set MaskPattern(mp: string) {
        this.maskPattern = mp;
    }

    @Input()
    set ReadOnly(mp: boolean) {
        this.isReadOnly = mp;
    }

    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set ShowClear(hc: boolean) {
        this.showClear = hc;
    }

    @Input() set IsPartialFillStatus(partial: any) {
        this.partialStatus = partial;
    }
   
    @Input() set RxType(rxType: any) {
        this.rxType = rxType;
    }

    @Input()
    set MinValue(hc: any) {
        this.minValue = hc;
        // this.inputFormGroup.controls[this.custContrlName].setValidators([this.minValueValidator(this.minValue)]);
    }

    @Input()
    set MaxValue(hc: any) {
        this.maxValue = hc;
    }
    @Input() isFromErx: boolean;

    @Input() PrescReFill: any;
    @Input() Prescription: any;

    @Input() IsFromFill: boolean;
    @Input() IsSpace: boolean;
    
    @Input() isRxNumber: boolean;

    @Input() EnteredMaxLength: number;
    @Input()
    set InputType(it: string) {
        // this.inputType = it;
        if (it.toUpperCase() === "CURRENCY") {
            this.formatType = "currency";
            this.isNumberType = true;
        } else if (it.toUpperCase() === "NUMBER") {
            this.inputType = "tel";
            this.isNumberType = true;
        } else if (it.toUpperCase() === "NUMERIC") {
            this.inputType = "number";
            this.isNumberType = true;
        } else {
            this.maskPattern = MaskConstant[it.toUpperCase()];
            if (it.toUpperCase() === "IP") {
                this.dropSpclChar = false;
            } else if (it.toUpperCase() === "PASSWORD") {
                this.inputType = "password";
            } else {
                this.inputType = "text";
            }
        }
    }
    @Input() cssStyle: boolean

    @Input()
    set FormGroupInvalid(hc: boolean) {
        this.hasFGInvalid = hc;
        if (this.hasFGInvalid) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set stopMarkAsTouched(val: any) {
        this.stopMarkAsTouchedVal = val;
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        } else {
            this.errorMessage = null;
        }
    }

    @Input()
    set fromOrdSupplyDays(val) {
        if (val && this.isFromErx) {
        this.OrdSupplyDaysValidate = true;
        this.validateAllFormFields(
            this.inputFormGroup,
            this.custContrlName
        );
        } else {
            this.errorMessage = null;
        }
    }


    @Input()
    set IsDisabled(id: boolean) {
        this.isDisabled = id;
    }

    @Input()
    set InputValue(iv: any) {
        if (iv !== null && iv !=undefined) {
            this.inputValue = iv;
        } else {
            this.inputValue = null;
        }
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set IsInputElemHidden(id: any) {
        this.isInputElemHidden = id;
    }

    @Input() notDisEditIcon: boolean;
    @Input() showRefresh: boolean;

    @Input()
    set DecimalValues(dv: any) {
        this.decimalValue = dv;
    }

    @Input()
    set BeforeDecimal(dv: any) {
        this.beforeDecValue = dv;
    }

    @Input()
    set ShowMask(dv: any) {
        this.showMask = dv;
    }
    @Input() IsAwpPriceUpdate: boolean;
    @Input() forRxSerial: boolean;
    @Input()
    set BarCodeDetector(bc: any) {
        this.barCodeDetector = bc;
    }

    @Output()
    TriggerChangeValue = new EventEmitter();

    @Output()
    TriggerSearchValue = new EventEmitter<{ value: string }>();

    @Output()
    TriggerOnEnterValue = new EventEmitter<{ value: string }>();

    @Output()
    TriggerOnFocusValue = new EventEmitter();

    @Output()
    TriggerOnBlur = new EventEmitter();

    @Output()
    TriggerOnChange = new EventEmitter<any>();

    @Output()
    TriggerOnTabbing = new EventEmitter<any>();

    @Output()
    EmitOnChangeValue = new EventEmitter<any>();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    @Output()
    TriggerRefreshemit = new EventEmitter<any>();

    @Output()
    TriggerAltNdShortcutkeysClicked = new EventEmitter<{ value: string }>();

    @Output()
    TriggerValWhileEnter = new EventEmitter<any>();

    @Output()
    TriggerShortcutKeyValue = new EventEmitter<any>();

    @Output()
    barCodeEmit = new EventEmitter<any>();
    @Output()
    TriggerClear = new EventEmitter<any>();

    @Output() blurLicenseEvent = new EventEmitter<any>();
    
    labelText: string;
    titleText: string;
    placeHolder: string;
    inputErrors: any;
    errorDefs: any;
    errorMessage: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    debounceTime: any = 0;
    isRequired: boolean;
    validPattern: string;
    minLength: number;
    maxLength: number;
    maskPattern: any;
    prefix: string;
    value: any;
    isReadOnly: boolean;
    hasControl = true;
    inputType = "text";
    tabIndex: number;
    isDisabled: boolean;
    inputValue: any = null;
    rxID: any;
    isInputElemHidden = false;
    isTouched = false;
    decimalValue: any;
    isNumberType = false;
    numberPipe = new UdpCurrencyMaskPipe();
    twoKeysClick = 0;
    stopMarkAsTouchedVal = false;
    prescReFill: any;
    lastKeyPressTime: number = 0;
    barcodeThreshold = 50;
    constructor(
        private _cdr: ChangeDetectorRef,
        public _commonSer: CommonService,
        private _validFormFieldsUtils: ValidateFormFieldsUtils,
        private _element: ElementRef,
        private _insServ: InsuranceService,
        public zone: NgZone
    ) {
        // this._cdr.detach();
        // setInterval(() => {
        //   this._cdr.detectChanges();
        // }, 100);
        
    }

    ngOnInit(): void {
        if (this.isNumberType && this.hasControl) {
            let value = this.numberPipe.transform(this.inputFormGroup.value[this.custContrlName], this.decimalValue,
                 this.formatType, this.beforeDecValue);
            this.inputFormGroup.controls[this.custContrlName].setValue(value);
        }
        const element = document.getElementById("rxSerialNo") as HTMLInputElement;
        if (this.rxID === "rxSerialNo" && element && element.title === "rxSerial") {
            if (element && element.required) {
                this.isRequired = true;
            } else {
                this.isRequired =  false;
            }
        }
        this._cdr.detectChanges();
    }

    changeValue(value?: any): void {
        this.TriggerChangeValue.emit(value.target.value);
        this.TriggerOnChange.emit(value);
        this.OnChange = true;
    }

    triggerChange($event) {
        // if (this.inputType === "number" && this.custContrlName) {
        //     if (this.decimalValue) {
        //         const value = +parseFloat(
        //             this.inputFormGroup.value[this.custContrlName]
        //         ).toFixed(this.decimalValue);
        //         this.inputFormGroup.controls[this.custContrlName].setValue(
        //             value
        //         );
        //     } else {
        //         const value = +parseFloat(
        //             this.inputFormGroup.value[this.custContrlName]
        //         ).toFixed(2);
        //         this.inputFormGroup.controls[this.custContrlName].setValue(
        //             value
        //         );
        //     }
        // }
        if (!this.hasControl) {
            this.checkIsValidInput();
        }
        this.TriggerSearchValue.emit({ value: $event.target.value });
    }

    triggerOnBlur(value?: any): void {
        this.TriggerOnBlur.emit(value);
    }

    emitOnChange(value): void {
        this.EmitOnChangeValue.emit(value);
    }

    onEnter(value?: any): void {
        if (this.barCodeDetector) {
            const currentTime = new Date().getTime();
            const timeSinceLastKey = currentTime - this.lastKeyPressTime;
            if (timeSinceLastKey < this.barcodeThreshold) {
              event.preventDefault();
              return;
            } else {
                this.TriggerOnEnterValue.emit(value);
            }
        } else {
            this.TriggerOnEnterValue.emit(value);
        }
      }
    
      onInput() {
        this.lastKeyPressTime = new Date().getTime();
      }

    validateAllFormFields(formGroup: FormGroup, formCntrl: string) {
        if (this.hasControl && !this.stopMarkAsTouchedVal) {
            this.errorMessage = this._validFormFieldsUtils.validateFormFields(
                formGroup,
                formCntrl,
                this.errorDefs
            );
            if(this.rxID && this.rxID === 'rxTechInitials' && !this.isRequired && this.inputFormGroup && this.inputFormGroup.controls &&  this.inputFormGroup.controls[this.custContrlName]) {
                this.inputFormGroup.controls[this.custContrlName].setErrors(null);
                this.inputFormGroup.controls[this.custContrlName].setErrors(null);
                this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
                }
            if (!this.errorMessage && this.inputFormGroup && this.inputFormGroup.controls &&  this.inputFormGroup.controls[this.custContrlName] && (this.minValue !== null && this.minValue != undefined)) {
                const ipVal = this.inputFormGroup.controls[this.custContrlName].value;
                if (+ipVal <= this.minValue) {
                    this.inputFormGroup.controls[this.custContrlName].setErrors({ "minVal": true });
                    if (this.errorDefs && this.errorDefs["minVal"]) {
                    this.errorMessage = this.errorDefs["minVal"];
                    }
                }
            }
            if (!this.errorMessage && this.inputFormGroup && this.inputFormGroup.controls && this.inputFormGroup.controls[this.custContrlName] &&  (this.maxValue !== null && this.maxValue != undefined)) {
                const ipVal = this.inputFormGroup.controls[this.custContrlName].value;
                if (+ipVal > this.maxValue) {
                    this.inputFormGroup.controls[this.custContrlName].setErrors({ "maxVal": true });
                    if (this.errorDefs && this.errorDefs["maxVal"]) {
                        this.errorMessage = this.errorDefs["maxVal"];
                    }
                }
            }
        } else {
            this.checkIsValidInput();
        }
    }
    ngOnChanges() {
        // this._insServ.insuSettings$.subscribe((resp1: any) => {
        //     if (resp1) {
        //     const element = document.getElementById("rxSerialNo") as HTMLInputElement;
        //     if (this.rxID === "rxSerialNo" && element && element.title === "rxSerial") {
        //         if (element && element.required) {
        //             this.isRequired = true;
        //         } else {
        //             this.isRequired =  false;
        //         }
        //     }
        // }
        // });
    }
    checkIsValidInput() {
        if (this.inputErrors) {
            this.errorMessage = this.inputErrors.required ? "Required" : null;
        } else {
            this.errorMessage = null;
        }
    }

    setNumberFormat() {
        if (this.isNumberType) {
            this.value = this.numberPipe.transform(this.inputFormGroup.value[this.custContrlName], this.decimalValue);
        }
    }

    setInputElemHidden() {
        if (this.showRefresh && this.IsAwpPriceUpdate) {
            this.TriggerRefreshemit.emit(true);
        } else {
            this.isInputElemHidden = !this.isInputElemHidden;
        }
        this.isTouched = true;
    }

    setIsTouchedFalse() {
        this.isInputElemHidden = !this.isInputElemHidden;
        this.isTouched = false;
    }

    onFocus(value?: any): void {
        this.TriggerOnFocusValue.emit(value);
        if (this.rxID) {
            document.getElementById(this.rxID).scrollIntoView({ behavior: "smooth", block: "center" });
            // document.getElementById(this.rxID).scrollIntoViewIfNeeded(false);
        }
    }

    onEnterValue(e?: any): void {

        // if (this.inputType === "number" || this.formatType === "currency") {
        //     const amount = String(e.target.value);
        //     const afterPoint = amount.split(".")[1];
        //     let decimals = "";
        //     if (typeof afterPoint !== "undefined") {
        //         decimals = afterPoint.replace(/\D+/g, "");
        //     }
        //     if (this.decimalValue) {
        //         if (decimals.length < this.decimalValue) {
        //         } else {
        //             if (
        //                 !(
        //                     e.key === "Backspace" ||
        //                     e.key === "Delete" ||
        //                     e.key === "ArrowLeft" ||
        //                     e.key === "ArrowRight" ||
        //                     e.key === "Tab" ||
        //                     e.key === "Enter"
        //                 )
        //             ) {
        //                 if (e.target.selectionStart !== 0 ) {
        //                     e.preventDefault();
        //                 }
        //             }
        //         }
        //     } else {
        //         if (decimals.length >= 2) {
        //             if (
        //                 !(
        //                     e.key === "Backspace" ||
        //                     e.key === "Delete" ||
        //                     e.key === "ArrowLeft" ||
        //                     e.key === "ArrowRight" ||
        //                     e.key === "Tab" ||
        //                     e.key === "Enter"
        //                 )
        //             ) {
        //                 e.preventDefault();
        //             }
        //         } else {
        //             e;
        //         }
        //     }
        // }
    }
    
    onTabbingEvent(e) {
        this.validateAllFormFields(
            this.inputFormGroup,
            this.custContrlName
        );
        if (this.hasControl) {
            if (this.inputFormGroup.controls[this.custContrlName].invalid) {
                e.preventDefault();
                let input = this._element.nativeElement.getElementsByTagName('input');
                 input[0].focus();
            }
        }
        this.TriggerOnTabbing.emit(e);
    }

    checkMaxLength(e: any) {
        const value = e.target.value;
        if (this.maxLength <= ("" + value).length && !(
                                e.key === "Backspace" ||
                                e.key === "Delete" ||
                                e.key === "ArrowLeft" ||
                                e.key === "ArrowRight" ||
                                e.key === "Tab" ||
                                e.key === "Enter"
                            )) {
            e.preventDefault();
        }
    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab"){
            this.TriggerOnShiftTabbing.emit(event);
        }
    }
    clearSelection() {
        if (!this.forRxSerial) {
            if (this.hasControl) {
                this.inputFormGroup.controls[this.custContrlName].patchValue(null);
                this.validateAllFormFields( this.inputFormGroup, this.custContrlName);
            } else {
                this.TriggerChangeValue.emit(null);
            }
        } else {
            this.TriggerClear.emit(null);
        }
    }

    minValueValidator(minVal: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const numericVal = +control.value;
            if (!(numericVal > minVal)) {
                return { 'minVal': true };
            }
            return null;
        };
    }

    shortcutKeysTrigger(event) {
        const altkeys = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        const keys = ["a", "s"];
        const Inputs = ["QtyPack", "AWPPack", "CostPack", "UnitPriceAWP", "UnitPriceCost", "DirectUnitPrice", "DefaultQty", "costPackId"];
        const FormControls = ["UnitAWP", "AWP", "UnitCost", "PackCost", "Packs", "SalesTaxPer"];
        if(event.altKey && this.rxID === "rxDispQty") {
            this.twoKeysClick++;
        } else if (event.altKey || event.ctrlKey) {
            this.twoKeysClick++;
        }
        if ((this.twoKeysClick > 0) && (keys.includes(event.key)) && ((Inputs.includes(this.rxID)) || (FormControls.includes(this.custContrlName)))) {
            this.twoKeysClick = 0;
            this.TriggerSearchValue.emit(event);
        } else if((this.twoKeysClick > 0) && (altkeys.includes(event.key)) &&
         (this.rxID === "rxDispQty" || this.rxID === "TherapeuticCode" || this.rxID === "Therapeutic")) {
            this.TriggerAltNdShortcutkeysClicked.emit(event);
        }
    }

    shortcutKeysTrigger2(event) {
        const keys = ["s"];
        const Inputs = ["CashIngredientCost", "CashrxTotalAmt", "CashrxProfFee"];
        if (event.ctrlKey) {
            this.twoKeysClick++;
        }
        if (this.rxID === "rxSigCode")
            this.TriggerShortcutKeyValue.emit(event);
        if ((this.twoKeysClick > 0) && (keys.includes(event.key)) && (Inputs.includes(this.rxID))) {
            this.twoKeysClick = 0;
            this.TriggerShortcutKeyValue.emit(event);
        }
    }

    resetclicks(event) {
        this.twoKeysClick = 0;
        if(this.rxID &&  ["NDCMMS","DrugNameMMS","PrescLastNameMMS","FirstNameMMS","DEAMMS","PhoneMMS",'LicenseMMS',"NPIMMS","ZipMMS","BinMMS","ProcessorNumMMS"].includes(this.rxID)) {
            this.validateAllFormFields( this.inputFormGroup, this.custContrlName);
        }
        if (this.rxID === "rxSerialNo" || this.rxID === "TagNameFlup" || this.rxID === "DrugNameMMS"  || this.rxID === "NDCMMS" || this.rxID === "reportname" || this.rxID === "reporttitle") {
            this.TriggerValWhileEnter.emit(event);
        }
    }
    
    getBarcodeInfo(barocodeText) {
        if (barocodeText && barocodeText.length) {
            this.barCodeEmit.emit(barocodeText);
        }
    }
    emitBlurEvent(event: any) {
        this.blurLicenseEvent.emit(event);
    }
}
