import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { observable, Subject } from 'rxjs';
import { environment } from "../../environments/environment";
import { CommonPrintComponent } from '../components/common-print/common-print.component';
import { AsyncReportEnum, AsyncReportNameEnum, reportsEnum } from '../models/AsyncReports.enum';
import { MessageHubClass } from '../models/user.model';
import { PPatientTabsComponent } from '../modules/patient';
import { PMessagingComponent } from '../modules/patient/messaging/messaging.component';
import { CommonWarnorconfirmPopupComponent } from '../modules/shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { CommonStore } from '../store';
import { AlertService } from './alert.service';
import { CommonService } from './common.service';
import { UserService } from './user.service';
import { VerifyInfoComponent } from '../modules/drug-pick-verification';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class HubService {
  private connection: signalR.HubConnection;
  connectionEstablished = new Subject<Boolean>();
  messageHubClass = new Subject<MessageHubClass>();
    ReportName: any;
    data = [
        {ReportName: AsyncReportEnum.DailyLog, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.DeferredRx, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.RxByPharmacist, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
             EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.ControlRx, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RxByPrescriber, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.UnbilledRx, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RxNotPickedup, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RxTransferredReport, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.SalesTaxSummary, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.PartialFill, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.InventoryStatus, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Inventory, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.DrugDispensed, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.DrugDispensedEquivalency, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RefillExpiration, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RefillDue, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.PatientMedicationAdherenceReport, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, ReportId: null},
        {ReportName:  AsyncReportEnum.priceReport, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, ReportId: null},
        {ReportName:  AsyncReportEnum.AuditTrail, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RxsByInsurance, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.SummaryReports, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.ProfitRevenueReports, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.rxlistwithnoattdocs, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.DMS, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.rxlistwithattdocs, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.DMS, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.erx, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false,  Menu: reportsEnum.eRx, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RxFilledSummary, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.Rx, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.PatientHippaAckReport, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.electronicSignature, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.RxPickupLog, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.electronicSignature, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.SignatureClaimsLog, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.electronicSignature, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.pickUpDetails, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.electronicSignature, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.posCopayCollected, IsReportGenerated: false, Count: 0,
            AsyncReportId: null, EventKey: null, IsPrint: false, Menu: reportsEnum.electronicSignature, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.InventoryReconciliation, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Inventory, IsExport: false, ReportId: null},
        {ReportName:  AsyncReportEnum.EDI835Remittance, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.EDIReports, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.IOUReport, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.NotesReport, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.ReturnToStock, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Rx, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.ViewInventory, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.Inventory, IsExport: false, ReportId: null},
        {ReportName: AsyncReportEnum.ViewEDI835Data, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
            EventKey: null, IsPrint: false, Menu: reportsEnum.EDIReports, IsExport: false, ReportId: null}
    ];
    customRprtData:any= [];
    paQueueData;
    private componentInstance: CommonPrintComponent;
    private messagingComponentInstance : PMessagingComponent;
    private patientTabsComponentInstance : PPatientTabsComponent;
    public verifyInfoComponentInstance: VerifyInfoComponent;

  constructor(private _userService: UserService, private _commonServ: CommonService,
     private _modalService: NgbModal, private _router: Router, private _alertServ: AlertService, private _commonStore: CommonStore, private _storageSvc:StorageService){
        
  }
  setComponent(component : CommonPrintComponent) {
    this.componentInstance = component;
  }
  setMessagingComponent(component: PMessagingComponent){
    this.messagingComponentInstance = component;
  }
  setPatientTabsComponent(component : PPatientTabsComponent){
    this.patientTabsComponentInstance = component;
  }
  setVerifyComponent(component: VerifyInfoComponent) {
    this.verifyInfoComponentInstance = component;
  }
  storeReportsNames(reportNames) {
    if (localStorage.getItem("DeletedReportName")) {
        const delReportItem = this._commonServ.DecryptData(localStorage.getItem("DeletedReportName"));
        const existReportIndex = this.data?.length &&  this.data.findIndex((item: any) => item.ReportName === delReportItem);
        if (existReportIndex > -1) {
            this.data.splice(existReportIndex, 1)
        }
    }
    if (reportNames?.length) {
        const ReportNames = reportNames;
        if (ReportNames?.length && this.data?.length) {
            ReportNames.map((rprtName:any)=> {
                const isExistName = this.data.find((dataItem:any) => dataItem.ReportName === rprtName)
                if (!isExistName) {
                    this.data.push({ReportName:  rprtName, IsReportGenerated: false, Count: 0 , AsyncReportId: null,
                        EventKey: null, IsPrint: false, Menu: reportsEnum.Reports, IsExport: false, ReportId: null})
                }
            })
            this.data =  _.uniqBy( this.data, "ReportName");
        }
    }
  }
  connect() {
    if (!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.ePrimeRxSignalRUrl)
      .withAutomaticReconnect([1000,20000,30000,60000])
      .configureLogging(signalR.LogLevel.Information)
      .build();
      
      this.connection.start().then(() => {
        if(this._userService.getAccessToken() && localStorage.getItem("userLogged"))
        {
            let npi = this._userService.getToken("UniquePharmacyId");
            this.AddToGroup(npi);
        }
        console.log('Hub connection started');
        this.connectionEstablished.next(true);
      }).catch(err => console.log(err));

      
      this.connection.onreconnected(() => 
      {
        console.log("Reconnected"); 
        if(this._userService.getAccessToken() && localStorage.getItem("userLogged"))
        {
            let npi = this._userService.getToken("UniquePharmacyId");
            this.AddToGroup(npi);
        }
      });

      this.connection.on('MessageSent', (result:any) =>
      {
        // this.storeReportsNames();
         console.log(result.reportName, result.count, result.isNotificationReq, result.isPrint, result.eventKey,result.npi);
         const soundAlerts = +this._commonServ.getSetttingValue("ERxSettings", "SOUND_ALERTS_ERX");
        const userPrivs = this._userService.getUserPrivsFromCookies();
        if(result.npi == this._userService.getToken("UniquePharmacyId") && soundAlerts === 1 && result.erxType === "NewRx")
         {
            const audioPath = "assets/Audio/Achievement.mp3";
            fetch(audioPath) // here we are checking if the audio file exists before attempting to play it
                .then((response) => {
                    if (response.ok) { 
                        const audio = new Audio();
                        audio.src = audioPath;
                        audio.load(); // This is synchronous; no error is thrown here
                        audio.autoplay = true; 
                        audio.play();
                    } else {
                        console.log("Audio file missing:", audioPath);
                    }
            })
            .catch((error) => {
                console.log("Error checking audio file:", error);
            });
        }
        if(result.npi == this._userService.getToken("UniquePharmacyId") && result.refreshPriceSchedule) {
          this._commonServ.getPriceScheduleList();
        }
        const prevs = userPrivs;
        const noEvent = this._commonServ.DecryptData(localStorage.getItem("NOEVENT"));
        if (prevs && prevs.Roles && result.roleId && noEvent !== "true") {
                if (prevs.Roles[result.roleId] && prevs.Roles[result.roleId].RoleId === result.roleId && !this._router.url.includes("/login") ) {
                    this._commonServ.setPopup(false);
                    setTimeout(() => {
                        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                            backdrop: false, keyboard: false, windowClass: "large--content"
                        });
                        modelRef.componentInstance.warnMsg = "A change has been identified for the role <b>'" +
                         (prevs.Roles[result.roleId].RoleName).toUpperCase() + "'</b>. Please re-login to reflect the changes.";
                        modelRef.componentInstance.okButton = true;
                        modelRef.componentInstance.IsHeaderText = "Information";
                        modelRef.componentInstance.IsPopUpClosed.subscribe(() => {
                            modelRef.close();
                            this._commonServ.setPopup(true);
                        });
                    }, 10);
                }
        }
        const localUserId = this._commonServ.DecryptData(localStorage.getItem("UserId"));
        if (result.reportName && result.count && (+(localUserId) === result.userId)) {
           const reportNameDisplay = AsyncReportNameEnum[result.reportName] ? AsyncReportNameEnum[result.reportName] : result.reportName;
            if (result.isNotificationReq) {
                if (!result.eventKey) {
                this._alertServ.success(reportNameDisplay + " report is generated successfully.");
                } else {
                    this._alertServ.unsuccess(reportNameDisplay + " report is not generated due to exception.");
                }
            }
           console.log(this.data, "ReportHub")
           this.data.map(report => {
            if (report.ReportName === result.reportName.replace(/\s/g, "")) {
                console.log(result, "hubreportId")
                   report.IsReportGenerated = true;
                   report.Count = result.count;
                   report.AsyncReportId = result.asyncReportId ? result.asyncReportId : result.AsyncReportId;
                   report.EventKey = result.eventKey;
                   report.IsPrint = result.isPrint;
                   report.IsExport = result.isExport;
                   report.ReportId = result.reportId;
            }
           });
            this._commonStore._reportGenerated$.next(this.data.filter((item: any) => {return item.IsReportGenerated}));
        }
        if (result && result.prescNum && (result.npi == this._userService.getToken("UniquePharmacyId"))) {
            const signlRObservble = this._commonStore.signlrDPVData$["source"]["value"];
            if (localStorage.getItem("ImmediateMode") && (this._storageSvc.getDecryptLocalStorage("ImmediateMode") === "true")) {
                if (signlRObservble && (signlRObservble.Rx.toString() === result.prescNum && (signlRObservble.Refill === result.reFillNo && signlRObservble.Fill === result.partialFillNo) && signlRObservble.ActiveTab && (localStorage.getItem("EyeconIP") && (this._storageSvc.getDecryptLocalStorage("EyeconIP") === result.eyeconMachineIp)))) {
                    if (result.refreshDPVScreenAndPrintLabel) {
                        if (this.componentInstance && result.dpvVerifyId) {
                            console.log(result, "HubServiceEyecon")
                            this.componentInstance.eyconLabelPrint(result);
                        }
                        if (this.verifyInfoComponentInstance) {
                            this.verifyInfoComponentInstance.showRxRefill(false, result)
                        }
                    } else {
                        if (this.verifyInfoComponentInstance) {
                            this.verifyInfoComponentInstance.showRxRefill(false, result)
                        } 
                    }
                } else {
                    if (this.componentInstance && result.dpvVerifyId && (localStorage.getItem("EyeconIP") && (this._storageSvc.getDecryptLocalStorage("EyeconIP") === result.eyeconMachineIp))) {
                        console.log(result, "HubServiceEyecon")
                        this.componentInstance.eyconLabelPrint(result);
                    }
                }
            } else if (this.componentInstance && result.dpvVerifyId && !result.isImmediateMode) {
                console.log(result, "HubServiceEyecon")
                this.componentInstance.eyconLabelPrint(result);
            } 
        }
        if(result && result?.isReloadForPA) {            
            this.paQueueData = { isReloadForPA: result?.isReloadForPA, paStatusId: result?.paStatusId, prescrefillId: result?.prescrefillId, rxId : result?.rxId }
            this._commonStore._signlrPaQueueData$.next(this.paQueueData);
        }
        // if (this.componentInstance && result && result.prescNum && (result.npi == this._userService.getToken("UniquePharmacyId")) && result.dpvVerifyId){
        //     console.log(result, "HubServiceEyecon")
        //     this.componentInstance.eyconLabelPrint(result);
        // }
        if (this.patientTabsComponentInstance && result && result.patientId) {
            this.messagingComponentInstance.ChangeToDefaultValues(true);
          }
      });

      this.connection.on("GroupMessage", (message) => {
        console.log(`Received group message: ${message}`);
        // You can update your UI or perform other actions here.
    });
     }
  }

  AddToGroup(npi:any)
  {      
        this.connection.send('AddToGroup',npi).
        catch(error =>{
            console.log(error);
            this.connection.start().then(() => {
            this.AddToGroup(npi)
            })
            .catch(err => {
                console.log("AddToGroup - Error: Restart Failed: ");   
                console.log(err);});           
        })
  }

  RemoveFromGroup()
  {
        let npi = this._userService.getToken("UniquePharmacyId");
        this.connection.send('RemoveFromGroup',npi).
        catch(error =>{
            console.log(error);
            this.connection.start().then(() => {
            this.RemoveFromGroup()
            })
            .catch(err => {
                console.log("RemoveFromGroup - Error: Restart Failed: ");   
                console.log(err);});           
        })
  }

  disconnect() {
    if (this.connection) {
      this.connection.stop();
      this.connection = null;
    }
  }
}
