import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private _commonSer: CommonService) { }
  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  getLocalStorage(key) {
        return localStorage.getItem(key);
  }
  removedLocalStorage(key) {
    sessionStorage.removeItem(key);
  }
  setSessionStorage(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }
  removedSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
  setEncryptLocalStorage(key, value) {
    localStorage.setItem(key, this._commonSer.encryptData(value));
  }
  getDecryptLocalStorage(key) {
    return this._commonSer.DecryptData(localStorage.getItem(key));
}
}
