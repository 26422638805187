
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BehaviorSubject, of as observableOf } from 'rxjs';
import "rxjs/add/observable/of";
import { share } from "rxjs/internal/operators/share";
import { Observable } from "rxjs/observable";
import { map } from "rxjs/operators";
import { constant } from "../app.constants";
import { MsgConfig } from '../app.messages';
import { PatientInfo, SystemData } from "../models";
import { SettingKeysPipe } from "../pipes";
import { CommonStore } from '../store';
import {
    Cities,
    City,
    DrugData,
    RxDateInputParams,
    RxPatientModel,
    SearchInsurance,
    State,
    States,
    SystemSetting,
    ZipMaster
} from "./../models";
import { LoaderService } from "./loader.service";


const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class CommonService {
    systemSettingData: SystemSetting;
    systemData: SystemData;
    systemStngSearch: SystemSetting;
    settingsKeysFilter = new SettingKeysPipe();
    overrideObject = {
        overrideToken: null,
        overrideEndpoint: null
    };

    csldtData:any = [];
    ctrlarr = [0, 1];
    isInputFocus:boolean = false;
    displayClnclAlrts:boolean = false;
    prescNum:any;
    systemData$: Observable<SystemData>;
    _systemData$: BehaviorSubject<SystemData>;

    patientID$: Observable<number>;
    private _patientID$: BehaviorSubject<number>;

    inProgressRxs$: Observable<any>;
    private _inProgressRxs$: BehaviorSubject<any>;

    browseHistory$: Observable<any>;
    private _browseHistory$: BehaviorSubject<any>;

    rxId$: Observable<any>;
    private _rxId$: BehaviorSubject<any>;

    insuranceData$: Observable<any>;
    _insuranceData$: BehaviorSubject<any>;

    PMPNow$: Observable<any>;
    _PMPNow$: BehaviorSubject<any>;

    Dynamsoft$: Observable<any>;
    _Dynamsoft$: BehaviorSubject<any>;

    systemSettings$: Observable<any>;
    _systemSettings$: BehaviorSubject<any>;

    systemSettingsCount$: Observable<any>;
    _systemSettingsCount$: BehaviorSubject<any>;

    settingsSearch$: Observable<any>;
    _settingsSearch$: BehaviorSubject<any>;

    newTenatInfo$: Observable<any>;
    _newTenatInfo$: BehaviorSubject<any>;

    discountSchedule$: Observable<any>;
    _discountSchedule$: BehaviorSubject<any>;

    priceSchedule$: Observable<any>;
    _priceSchedule$: BehaviorSubject<any>;

    dashboardBillStat$: Observable<any>;
    _dashboardBillStat$: BehaviorSubject<any>;

    refillQueueDashboardCount$: Observable<any>;
    _refillQueueDashboardCount$: BehaviorSubject<any>;

    dashboardERxtat$: Observable<any>;
    _dashboardERxStat$: BehaviorSubject<any>;

    RefQueuePrc$: Observable<any>;
    _RefQueuePrc$: BehaviorSubject<any>;

    drugSuggVal$: Observable<any>;
    _drugSuggVal$: BehaviorSubject<any>;

    showInProgressRx$: Observable<any>;
    _showInProgressRx$: BehaviorSubject<any>;

    _patientInfo$: BehaviorSubject<any>;
    patientInfo$: Observable<any>;

    _drugInfo$: BehaviorSubject<any>;
    drugInfo$: Observable<any>;

    _setResetPresc$: BehaviorSubject<any>;
	setResetPresc$: Observable<any>;

    _setCsldtPopData$: BehaviorSubject<any>;
    setCsldtPopData$: Observable<any>;

    _drugUnitCostChange$: BehaviorSubject<any>;
    drugUnitCostChange$: Observable<any>;

    _dcdataStatus$:BehaviorSubject<boolean> = new BehaviorSubject(false);
    dcdataStatus$ = this._dcdataStatus$.asObservable();

    _showdcDataPop$:BehaviorSubject<boolean> = new BehaviorSubject(false);
    showdcDataPop$ = this._showdcDataPop$.asObservable();

    _isDoseDisabled$:BehaviorSubject<boolean> = new BehaviorSubject(true);
    isDoseDisabled$ = this._isDoseDisabled$.asObservable();

    _appUserData$: BehaviorSubject<any> = new BehaviorSubject(null);
    appUserData$ = this._appUserData$.asObservable();

    _controlFileSettings$: BehaviorSubject<any> = new BehaviorSubject(null);
    controlFileSettings$ = this._controlFileSettings$.asObservable();

    _bucketList$: BehaviorSubject<any> = new BehaviorSubject(null);
    bucketList$ = this._bucketList$.asObservable();

    _notesCatList$: BehaviorSubject<any> = new BehaviorSubject(null);
    notesCatList$ = this._notesCatList$.asObservable();

    _followUpList$: BehaviorSubject<any> = new BehaviorSubject(null);
    followUpList$ = this._followUpList$.asObservable();

    _wjSavedList$: BehaviorSubject<any> = new BehaviorSubject(null);
    wjSavedList$ = this._wjSavedList$.asObservable();

    _340BValForTab$: BehaviorSubject<any> = new BehaviorSubject(null);
    Is340BValForTab$ = this._340BValForTab$.asObservable();

    _Is340BVal$: BehaviorSubject<any> = new BehaviorSubject(null);
    Is340BVal$ = this._Is340BVal$.asObservable();

    _storeInprogressClickOnRxSideMenu$: BehaviorSubject<any> = new BehaviorSubject(null);
    storeInprogressClickOnRxSideMenu$ = this._storeInprogressClickOnRxSideMenu$.asObservable();

    _openPopup$: BehaviorSubject<any> = new BehaviorSubject(null);
    openPopup$ = this._openPopup$;

    pharmcistName$: Observable<any>;
    _pharmcistName$: BehaviorSubject<any>;

    _faciltityDropdownList$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    faciltityDropdownList$: Observable<any> = this._faciltityDropdownList$.asObservable();

    _overridePrivNotes$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    overridePrivNotes$: Observable<any> = this._overridePrivNotes$.asObservable();

    secretphrase: string = "x49e*wJVXr8BrALE";
    dcdata:any
    isAllergyChecked:boolean = false;
    //isDoseDisabled:boolean  =true;

    constructor(private _http: HttpClient,  private _commonStore: CommonStore, private _loadrServ: LoaderService) {
        this._systemData$ = new BehaviorSubject(null) as BehaviorSubject<any>; // value from child components
        this.systemData$ = this._systemData$.asObservable();

        this._patientID$ = <BehaviorSubject<number>>new BehaviorSubject(null);
        this.patientID$ = this._patientID$.asObservable();

        this._inProgressRxs$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.inProgressRxs$ = this._inProgressRxs$.asObservable();

        this._browseHistory$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.browseHistory$ = this._browseHistory$.asObservable();

        this._rxId$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.rxId$ = this._rxId$.asObservable();

        this._insuranceData$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.insuranceData$ = this._insuranceData$.asObservable();

        this._PMPNow$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.PMPNow$ = this._PMPNow$.asObservable();

        this._Dynamsoft$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.Dynamsoft$ = this._Dynamsoft$.asObservable();

        this._systemSettings$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.systemSettings$ = this._systemSettings$.asObservable();

        this._settingsSearch$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.settingsSearch$ = this._settingsSearch$.asObservable();

        this._newTenatInfo$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.newTenatInfo$ = this._newTenatInfo$.asObservable();

        this._discountSchedule$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.discountSchedule$ = this._discountSchedule$.asObservable();

        this._priceSchedule$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.priceSchedule$ = this._priceSchedule$.asObservable();

        this._dashboardBillStat$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.dashboardBillStat$ = this._dashboardBillStat$.asObservable();

        this._refillQueueDashboardCount$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.refillQueueDashboardCount$ = this._refillQueueDashboardCount$.asObservable();

        this._dashboardERxStat$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.dashboardERxtat$ = this._dashboardERxStat$.asObservable();

        this._RefQueuePrc$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.RefQueuePrc$ = this._RefQueuePrc$.asObservable();

        this._drugSuggVal$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.drugSuggVal$ = this._drugSuggVal$.asObservable();

        this._showInProgressRx$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.showInProgressRx$ = this._showInProgressRx$.asObservable();

        this._showInProgressRx$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.showInProgressRx$ = this._showInProgressRx$.asObservable();

        this._patientInfo$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.patientInfo$ = this._patientInfo$.asObservable();

        this._drugInfo$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.drugInfo$ = this._drugInfo$.asObservable();

        this._setResetPresc$ = <BehaviorSubject<any>>new BehaviorSubject(null);
	    this.setResetPresc$ = this._setResetPresc$.asObservable();

        this._setCsldtPopData$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.setCsldtPopData$ = this._setCsldtPopData$.asObservable();

        this._drugUnitCostChange$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.drugUnitCostChange$ = this._drugUnitCostChange$.asObservable();

        this._pharmcistName$ = <BehaviorSubject<any>>new BehaviorSubject(null);
        this.pharmcistName$ = this._pharmcistName$.asObservable();
    }

    setPopup(privChanged) {
        this._openPopup$.next(privChanged)
    }

    getpopup() {
        return this.openPopup$;
    }

    getSystemData() {
        this._http
            .get<SystemData>(constant.GET_Utility_SystemData)
            .subscribe(resp => {
                if (resp && resp.DAW && resp.DAW[0].Id === 4) {
                    // Sentry.captureException([
                    //     {GetSystemDawList: resp.DAW},
                    // ]);
                }
                (this.setSystemData(resp));
            });
    }

    storeRefillQueue(val) {
        this._RefQueuePrc$.next(val);
    }

    store340BValForTabs(val) {
        this._340BValForTab$.next(val);
    }

    afterSystemSettings(val) {
        this._systemSettingsCount$.next(val);
    }


    storeIs340BVal(val) {
        this._Is340BVal$.next(val);
    }

    storeDrugSuggVal(val) {
        this._drugSuggVal$.next(val);
    }

    storePharmcistNameForSsoLogin(val) {
        this._pharmcistName$.next(val);
    }

    getSystemDataForResolver(): Observable<SystemData> {
        return this._http.get<SystemData>(constant.GET_Utility_SystemData);
    }


    getCitiesData(citName: string, stateId: number) {
        return this._http.post<Cities>(constant.GET_Utility_Cities, {cityName: citName, stateId: stateId});
    }

    getStatesData(stateName: string) {
        return this._http.get<States>(
            constant.GET_Utility_States.replace("{text}", "" + stateName)
        );
    }

    getStateById(stateId: string) {
        return this._http.get<State>(
            constant.GET_Utility_StateById.replace("{stateId}", "" + stateId)
        );
    }

    getCityById(cityId: string) {
        return this._http.get<City>(
            constant.GET_Utility_CityById.replace("{cityId}", "" + cityId)
        );
    }

    getStateCityDataByZip(zipcode: string) {
        return this._http.get<ZipMaster>(
            constant.GET_Utility_StateCity.replace("{text}", "" + zipcode)
        );
    }

    getZipByStateACity(stateId, cityId) {
        return this._http.get(
            constant.GET_ZipCode_Value.replace("{stateId}", "" + stateId).replace("{cityId}", "" + cityId)
        );
    }

    getZipCodeByStateCity(data) {
        return this._http.post(constant.GET_Utility_ZipByStateCity, data);
    }


    getPatientsuggest(patInfo: any): Observable<any> {
        // const patientStrng = patInfo.trim().replace(" ", "_");
        return this._http.post<any>(
            constant.GET_Patient_Suggest, { text: patInfo },reqOptns
        ).pipe(
            map(res => {
                return res.map((item, i) => {
                    item["index"] = i;
                    return item;
                });
            }),
            share());
    }

    getPatientsuggestByRxNo(rxNo: any): Observable<any> {
        let rxNoForPat = rxNo.trim();
        // rxNoForPat = rxNoForPat.replace("/", "");
        return this._http.post<any>(
            constant.GET_Patient_SuggestByRxNo, {rxNo: rxNoForPat}
        ).pipe(
            share());
    }

    getDrugSuggest(drugId): Observable<any> {
        return this._http.post<any>(
            constant.GET_Drug_Suggest, {text: drugId}
        ).pipe(
            share());
    }
    getGlobalSearchDrugSuggest(drugId, filters?): Observable<any> {
        const payload = !filters ? {text: drugId} :
                            Object.assign({}, {text: drugId}, filters);
        return this._http.post<any>(
            constant.GET_Drug_Suggest, payload, reqOptns
        ).pipe(
            map(res => {
                if (res && res["result"]) {
                    return res["result"].map((item, i) => {
                        item["index"] = i;
                        return item;
                    });
                }
                this.storeDrugSuggVal(res && res["IsDrugPresentInDB"] ? true : false);
            }),
            share());
    }

    getDrugByBarcode(barcodeText): Observable<any> {
        return this._http.post<any>(
            constant.POST_DRUG_BY_BARCODE, { BarCodeInputString: barcodeText }
        ).pipe(
            map(res => {
                if (res) {
                    return res.map((item, i) => {
                        item["index"] = i;
                        return item;
                    });
                } else {
                    return res;
                }
            }),
            share());
    }

    getBarcodeTokenValidation(payLoad): Observable<any> {
        return this._http.post<any>(constant.GET_BARCODE_VALIDATE,payLoad);
    }

    saveDscsaRx(payLoad): Observable<any> {
        return this._http.post<any>(constant.SAVE_DSCSA_RX,payLoad);
    }

    getDscsaPrescrefillData(PrescRefillId): Observable<any> {
        return this._http.get<any>(constant.GET_DSCSA_PRESCREFILL_DATA.replace("{PrescRefillId}", "" + PrescRefillId));
    }

    getDscsaData(DSCSAId): Observable<any> {
        return this._http.get<any>(constant.GET_DSCSA_DATA.replace("{DSCSAId}", "" + DSCSAId));
    }

    deleteDscsa(DrugId) : Observable<any> {
        return this._http.delete<any>(constant.DELETE_DSCSA_DATA.replace("{DrugId}", "" + DrugId));
    }

    getPrescriberSuggest(prescriberId,isFromDashboard?): Observable<any> {
        const prescStrng = prescriberId.trim().replace("_", ",");
        return this._http.post<any>(
            constant.GET_Prescriber_Suggest, { text: prescStrng, isFromDashboard : isFromDashboard}, reqOptns).pipe(
                map(res => {
                    return res.map((item, i) => {
                        item["index"] = i;
                        return item;
                    });
                }),
                share());
    }

    getEditPatientInformation(editPatientId: number, noLoader?): Observable<PatientInfo> {
        if (noLoader) {
            return this._http.get<PatientInfo>(
                constant.GET_Patient.replace("{patientID}", "" + editPatientId), reqOptns
            );
        } else {
            return this._http.get<PatientInfo>(
                constant.GET_Patient.replace("{patientID}", "" + editPatientId)
            );
        }
    }

    getPatientInformationForRx(patientId: number): Observable<RxPatientModel> {
        return this._http.get<RxPatientModel>(
            constant.GET_RX_Patient.replace("{patientID}", "" + patientId)
        );
    }

    getEmptyObservable(): Observable<any> {
        return observableOf([]);
    }

    getDrugInfo(id: any) {
        return this._http.get<DrugData>(
            constant.GET_Drug.replace("{drugId}", "" + id)
        );
    }

    getDrugLotDescpInfo(drugId : any, buckId: any) {
        return this._http.get<DrugData>(
            constant.GET_Drug_LOT_DESC.replace("{drugId}", "" + drugId).replace("{bucketId}", "" + buckId)
        );
    }

    isFileExist(filename: string, extension: string) {
        return this._http.get(
            constant.GET_Drug_IsFileExist.replace(
                "{filename}",
                filename
            ).replace("{extension}", "" + extension)
        );
    }

    getDrugLots(payLoad: any) {
        // return this._http.get<any>(
        //     constant.GET_Drug_DrugLot.replace("{term}", term).replace(
        //         "{drugId}", "" + drugId).replace("{bucketId}", bucketId ? "" + bucketId : "")
        // );
        return this._http.post<any>(
            constant.POST_Drug_DrugLot,
            payLoad
        );
    }

    storePatientIdinBS(patientId: number) {
        this._patientID$.next(patientId);
        localStorage.setItem("patientId", this.encryptData("" + patientId));
    }
    getPrescriberData(Id: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Prescriber.replace("{prescriberId}", "" + Id)
        );
    }
    getPrescriberTypeCheckData(payload: any): Observable<any> {
        return this._http.post<any>(
            constant.GET_Prescriber_PrescriberTypeCheck,
            payload, reqOptns
        );
    }
    getPrescriberTypeDeleteResponse(Id : string):Observable<any>{
        return this._http.delete<any>(constant.GET_Prescriber_PrescTypeDeleteResponse.replace(
            "{prescriberTypeId}",Id
        ))
    }
    addPresciberTypeFile(PrescCatCode: any):Observable<any> {
        return this._http.post<any>(constant.POST_Prescriber_AddTypeFile, PrescCatCode)
    }
    addandSavePresciberTypeFile(PrescCatCode: any):Observable<any> {
        return this._http.get<any>(constant.POST_Presciber_ADD_Type_Check.replace("{prescriberCatCode}", "" + PrescCatCode.PrescCatCode));
    }
    updatePrescriberFileType(payload:any):Observable<any>{
        return this._http.put<any>(constant.PUT_Prescriber_File_Type,payload)
    }
    storeRxIdinBS(rxID: number) {
        localStorage.setItem("RxID", this.encryptData("" + rxID));
        this._rxId$.next(rxID);
    }

    // getRxDetailsByPrescNo(PrescNum: number) {
    //     return this._http.get<any>(
    //         constant.GET_Rx_GetRxDetails.replace("{PrescNum}", "" + PrescNum)
    //     );
    // }

    getRxDetailsByPrescNos(PrescNumarray: any, PrescRefillId: any , isRecall: boolean = false, rfNo?: any, isFromEditRx: boolean = false, allDetailsNotNeeded: boolean = false,isRecallDrugLotDeleted?: boolean, IsFromCreateNewFlow?: boolean, PartialNo?:any,changeLoaderMsg?,PrescRefills?: any) {
        const rxDetObj = {"PrescNums": PrescNumarray, IsRecall: isRecall, PrescRefillId: PrescRefillId,
        IsRefilllatest: rfNo || rfNo === 0 ? true : false, RefillNum: rfNo, IsFromEditRx: isFromEditRx, AllDetailsNotNeeded: allDetailsNotNeeded,isRecallDrugLotDeleted:isRecallDrugLotDeleted, IsFromCreateNewFlow: IsFromCreateNewFlow,PartialNo:PartialNo, Prescrefills: PrescRefills};
        if(IsFromCreateNewFlow) sessionStorage.setItem("ReferencePrescReFillId", this.encryptData(PrescRefillId));
        if (changeLoaderMsg) {
            const loaderOptns = {
                headers: new HttpHeaders().set("loadingMesssage", "Loading Prescription Details")
            };
            return this._http.post<any>(constant.POST_Rx_GetRxDetails, rxDetObj,loaderOptns);
        } else {
            return this._http.post<any>(constant.POST_Rx_GetRxDetails, rxDetObj);
        }
    }

    getMultipleRxDetails(payload:any) {
        return this._http.post<any>(constant.POST_Rx_GetRxDetails, payload);
    }

    storeUnitPriceCost(cost) {
        this._drugUnitCostChange$.next(cost)
    }

    storeInprogressRxs(rx: any) {
        this._inProgressRxs$.next(rx);
    }

    storeInprogressBrowseHistory(browHist: any) {
        this._browseHistory$.next(browHist);
    }

    getRxInfoByRxNumber(rxNum: number) {
        return this._http.get<any>(
            constant.GET_Rx_CloneRxByID.replace("{PrescNum}", "" + rxNum)
        );
    }

    //TODO: Remove this duplicate values
    async getInsuranceData(insuSearch: SearchInsurance, addHdrs?:boolean) {
        let resp;
        if (addHdrs) {
            resp = await this._http.post<any>(constant.POST_Insurance_Search, insuSearch, reqOptns).toPromise();
        } else {
            resp = await this._http.post<any>(constant.POST_Insurance_Search, insuSearch).toPromise();
        }
        if (resp && resp.Records) {
            this._insuranceData$.next(resp.Records);
        }
    }

    getInsuData(searchParams: SearchInsurance): Observable<any> {
        return this._http.post<any>(
            constant.POST_Insurance_Search,
            searchParams
        );
    }

    checkIs340BPhar(systmStng?: any) {
        let result = false;
        const sysData = systmStng ? systmStng : this.systemSettings$["source"]["value"];
        if (sysData && sysData["ThreeFourtyBSettings"] && sysData["ThreeFourtyBSettings"].length) {
            const Is340B = sysData["ThreeFourtyBSettings"].find(v => v["Key"] === "340B_PHARM");
            if (Is340B) {
                result = Is340B["ToggleValue"] ? true : false;
            }
        }
        return result;
    }

    checkAndStore340BValue() {
        this.storeIs340BVal(this.checkIs340BPhar());
    }

    async getSystemSettingsWithBS(settingsval: any) {
      const resp =  await this._http.post<any>(constant.GET_System_Settings, settingsval).toPromise();
      if (resp) {
        this.setSystemSettings(resp);
      }
      return resp;
    }

    getDashboardStatsfAfterSettings() {
        const defaultDaysForDateRange = this.getSetttingValue("WorkFlowSettings", "Default_days_for_Dashboard_date_range");
        const dateObj = {StartDate : moment().subtract(defaultDaysForDateRange, "days").format("MM/DD/YYYY") , EndDate : moment().subtract(0, "days").format("MM/DD/YYYY")};
        this._commonStore.storeDashFilters(dateObj);
        this.getDashboardStats(dateObj);
    }
     setSystemSettings(resp) {
        this.systemSettingData = resp;
        this._systemSettings$.next(resp);
        if(resp)
            this.checkAndStore340BValue();
    }

    setSystemStngSearch(resp) {
        this.systemStngSearch = resp;
        this._settingsSearch$.next(resp);
    }

    setSystemData(resp) {
        this.systemData = resp;
        if (resp && resp.DAW && resp.DAW[0].Id === 4) {
            // Sentry.captureException([
            //     {SetSystemDawList: resp.DAW},
            // ]);
        }
        this._systemData$.next(resp);
    }

    getSystemSettingsWithoutBS(settingsval: any): Observable<SystemSetting> {
        return this._http.post<any>(constant.GET_System_Settings, settingsval);
        // return this._http.get<SystemSetting>(constant.GET_Utility_SystemData);
    }

    getSystemSettingsWithFilter(settingsval: any, searchKey): Observable<SystemSetting> {
        return this._http.post<any>(constant.GET_System_Settings, settingsval)
            .pipe(map(data => { return this.settingsKeysFilter.transform(data, searchKey)}));
    }

    getSystem(settingsval: any) {
        return this._http
        .post<any>(constant.GET_System_Settings, settingsval);
    }

    getSystemSetData(settingsval: any) {
        return this._http
            .post<any>(constant.GET_System_Settings, settingsval)
            .subscribe(resp => this.setSystemStngSearch(resp));
    }
    getSystemSetDataForSameUser(settingsval: any) {
        return this._http
            .post<any>(constant.GET_System_Settings, settingsval);
    }
    storeSettings(settingsData: SystemSetting) {
        return this._http.put(constant.PUT_Save_System_Settings, settingsData);
    }

    getMonogramDropdownData() {
        return this._http.get(constant.GET_MonogramDropdownData);
    }

    getaLabelMonogramData(PharmId) {
        return this._http.get(
            constant.GET_LabelMonogramData.replace("{pharmId}", "" + PharmId)
        );
    }

    getDashboardStats( dateParams: RxDateInputParams ): any {
        return this._http.post<any>(constant.POST_Rx_RxDashboardStats, dateParams, reqOptns);
    }

    /*getRefillsQueueDataForDashBoard( dateParams: RxDateInputParams, addHdrs?:boolean ): any {
        if (addHdrs) {
            return this._http.post<any>(constant.POST_Rx_RefillsQueueDataForDashBoard, dateParams, reqOptns);
        } else {
            return this._http.post<any>(constant.POST_Rx_RefillsQueueDataForDashBoard, dateParams);
        }
    }*/

    getDashboardeRxStats(
        dateParams: RxDateInputParams
    ): any {
        this._http.post<any>(
            constant.POST_Rx_RxDashboardErxStats,
            dateParams
        ).subscribe(resp => this._dashboardERxStat$.next(resp))
    }

    patientInsuranceList(insu: any): Observable<any> {
        return this._http.post<any>(
            constant.GET_Patient_Insurance_Search,
            insu
        );
    }

    checkIfPolicyNoExists(policyNo, insurerId,insuCardId) {
        return this._http.get(
            constant.GET_Check_If_PolicyNo_Exits.replace("{policyNum}", "" + policyNo)
                .replace("{insurerId}", "" + insurerId)
                .replace("{insuCardId}", "" + insuCardId)
        );
    }
    getSetttingValue(type: string, defKey: string, latestSysStngs?) {
        this.systemSettingData = latestSysStngs ? latestSysStngs : this.systemSettingData
        if (this.systemSettingData) {
            const defObj: any = _.filter(this.systemSettingData[type], {
                Key: defKey
            });
            const accountStngCount = this.systemSettingData["AccountSettingCount"];
            if (defObj.length > 0) {
                if (accountStngCount && accountStngCount === "0") {
                    if (defObj[0].InputType === "Toggle") {
                        defObj[0].DefaultValues = defObj[0].DefaultToggleValue ? "1" : "0";
                    }
                    return defObj[0].DefaultValues;
                } else {
                    if (defObj[0].InputType === "Toggle") {
                        defObj[0].Value = defObj[0].ToggleValue ? "1" : "0";
                    }
                    return defObj[0].Value;
                }
            }
            return null;
        }
    }
    getSystmDatVal(stmDat, type: string, defKey: string, value: any, returnKey?: any) {
        let defObj: any = null;
        const NUM_REGEXP = /^[0-9"]+$/;
        const isNumber = NUM_REGEXP.test(value);
        if (value) {
            if (isNumber) {
                defObj = stmDat[type].filter(x => x[defKey] === value);
            } else {
                defObj = stmDat[type].filter(x => x[defKey].toUpperCase() === value.toUpperCase());
            }

            if (defObj.length > 0) {
                if (returnKey) {
                    return defObj[0][returnKey];
                } else {
                    return defObj[0].Id;
                }
            }
        }
        return null;
    }

    getRxPatientDetailsById(patNum: number) {
        return this._http.get<any>(
            constant.GET_Rx_PatientById.replace("{patientId}", "" + patNum)
        );
    }

    getRxPrescriberDetailsById(patNum: number) {
        return this._http.get<any>(
            constant.GET_Rx_PrescriberById.replace(
                "{prescriberId}",
                "" + patNum
            )
        );
    }

    getRxDrugDetailsById(patNum: number) {
        return this._http.get<any>(
            constant.GET_Rx_DrugById.replace("{drugId}", "" + patNum)
        );
    }

    sendAuditChanges(auditInfo: any): Observable<any> {
        return this._http.post<any>(constant.POST_Utility_AuditLog, auditInfo, reqOptns);
    }

    saveAuditChanges(data: any): Observable<any> {
        return this._http.post<any>(constant.POST_Save_Audit, data, reqOptns);
    }

    getNewTenantinfo(addHdrs?:boolean) {
        if (addHdrs) {
            this._http.get(constant.GET_TenatInfo, reqOptns).subscribe(resp => {
                this._newTenatInfo$.next(resp);
            });
        } else {
            this._http.get(constant.GET_TenatInfo).subscribe(resp => {
                this._newTenatInfo$.next(resp);
            });
        }
        
    }

    getNewTenantinfoDetails() {
        return this._http.get<any>(constant.GET_TenatInfo);
    }

    async getCheckLogiCoyRegitration() {
        let state: any;
        this._newTenatInfo$.subscribe(resp => {
            if (resp && resp["PharmacyDetails"]) {
                state = resp["PharmacyDetails"]["StateId"];
                if (state) {
                    this._http.post(constant.POST_CheckLogiCoyRegitration.replace("{stateId}", "" + state),null, reqOptns).subscribe(res=>{
                        this._PMPNow$.next(res)  
                    })
                                    
                }
            }
          });
    }

    getLogiCoyPDMPReport() {
        let state: any;
        this._newTenatInfo$.subscribe(resp => {
            if (resp && resp["PharmacyDetails"]) {
                state = resp["PharmacyDetails"]["StateName"];
            }
          });
        return this._http.post(constant.POST_LogiCoyPDMPReport.replace("{stateCode}", "" + state),null)
    }

    getLogicoyUrl(username: string, password: string,url,payLoad): Observable<any> {
        const credentials = btoa(`${username}:${password}`);
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/json");
        headers = headers.set("logicoyToken", credentials);
        return this._http.post(url, payLoad,{ headers: headers});
      }

    async getDiscountScheduleList(addHdrs?:boolean) {
        let resp;
        if (addHdrs) {
            resp = await this._http.get(constant.GET_Rx_DiscountSchedule,reqOptns).toPromise();
        } else {
            resp = await this._http.get(constant.GET_Rx_DiscountSchedule).toPromise();
        }
      this._discountSchedule$.next(resp);
    }

    getPriceScheduleList(addHdrs?:boolean) {
        if (addHdrs) {
            this._http.get(constant.GET_Rx_PriceSchedule, reqOptns).subscribe(resp => {
                this._priceSchedule$.next(resp);
            });
        } else {
            this._http.get(constant.GET_Rx_PriceSchedule).subscribe(resp => {
                this._priceSchedule$.next(resp);
            });
        }
    }

    getDefPriceCodeValue(patID: number, drugNumber: number, insuId: number ): any {
        return this._http.get<any>(constant.GET_Rx_PriceCodePrec.replace("{DrugId}", "" + drugNumber)
            .replace("{PatientId}", "" + patID).replace("{InsuranceId}", "" + insuId));
    }

    startLoader(): void {
        this._loadrServ.display(true);
    }

    hideLoader(): void {
        this._loadrServ.display(false);
    }
    resetOverrideObj() {
        this.overrideObject = {
            overrideToken: null,
            overrideEndpoint: null
        };
    }

    removeEndpoint(endPointToRemove) {
        if (this.overrideObject.overrideEndpoint && Array.isArray(this.overrideObject.overrideEndpoint) && this.overrideObject.overrideEndpoint.length > 1) {
            this.overrideObject.overrideEndpoint = this.overrideObject.overrideEndpoint.filter(endP => endP !== endPointToRemove);
        } else {
            this.resetOverrideObj();
        }
    }

    setOverrideObj(obj) {
        if (obj.overrideToken && obj.overrideEndpoint) {
            this.overrideObject = {
                overrideToken: obj.overrideToken,
                overrideEndpoint: obj.overrideEndpoint
            };
        }
    }
    getOverrideObj() {
        return this.overrideObject;
    }

    checkIsLotInactive(drugId, drugLot) {
        return this._http.get<any>(
            constant.GET_Duplicate_DrugLot.replace("{drugid}", "" + drugId)
            .replace("{lotnum}", "" + drugLot)
        );
    }

    getLatestPrescriber(patId) {
        return this._http.get<any>(constant.GET_Latest_Prescriber.replace("{patientId}", "" + patId));
    }

    printErxReport(erxId) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.get(constant.GET_eRx_PrintErxReport.replace("{erxId}", "" + erxId), { headers: headers, responseType: "blob" });
    }

    getDocumentCategoryList():Observable<any[]> {
        return this._http.get<any[]>(constant.GET_DocumentCategory);
    }

    storeAppUserData(resp) {
        this._appUserData$.next(resp);
    }

    setControlFileSettings(resp: any) {
        this._controlFileSettings$.next(resp);
    }

    async getAndStoreBucketList(addHdrs?:boolean) {
        let resp;
        if (addHdrs) {
            resp = await this._http.get(constant.GET_Inventory_Buckets.replace("{drugId}" , null),reqOptns).toPromise();
        } else {
            resp = await this._http.get(constant.GET_Inventory_Buckets.replace("{drugId}" , null)).toPromise();
        }
       this._bucketList$.next(resp);
    }

    async getWjGridSavedDet(addHdrs?:boolean) {
        let resp;
        if (addHdrs) {
            resp = await this._http.get<any>(constant.GET_Saved_WjGrid_Det.replace("{wijmokey}" , null),reqOptns).toPromise();
        } else {
            resp = await this._http.get<any>(constant.GET_Saved_WjGrid_Det.replace("{wijmokey}" , null)).toPromise();
        }
        this._wjSavedList$.next(resp);
    }

    saveWJGridDet(data) {
        return this._http.put<any>(constant.POST_Save_WjGrid_Det, data);
    }

    async getRxFollowUpDropDown(addHdrs?:boolean) {
        let resp;
        if (addHdrs) {
            resp = await this._http.get<any>(constant.GET_Rx_Follow_Up_Tag_DropDownData,reqOptns).toPromise();
        } else {
            resp = await this._http.get<any>(constant.GET_Rx_Follow_Up_Tag_DropDownData).toPromise();
        }
        this._followUpList$.next(resp);
    }

    splitDocAndAdd(payload) {
        return this._http.post<any>(constant.POST_DOCUMENT_SPLIT_ASSIGN, payload);
    }

    getPatientDrugs(patientId) {
        return this._http.get<any>(constant.GET_PATIENT_RELATED_DRUGS.replace("{patientId}", "" + patientId));
    }

    getAppVersion(addHdrs?:boolean) {
        if (addHdrs) {
            let headers = new HttpHeaders();
            headers = headers.set("stopAddingDefURL", "true");
            headers = headers.set("isLoadNotReq", "true");
            return this._http.get<any>(constant.GET_APP_VERSION,{ headers: headers});
        } else {
            const reqOptns = {
                headers: new HttpHeaders().set("stopAddingDefURL", "true")
            };
            return this._http.get<any>(constant.GET_APP_VERSION,reqOptns);
        }
    }

    checkIsPartialFill(prescReFill, _Prescription?,rxType?) {
        let result = false;
        if ((rxType !== 'rf' &&  prescReFill?.NumberOfFills > 1 || (prescReFill?.PartialFillNo === 1 && prescReFill?.PartialIndStatus === "P"))) {
            result = true;
        }  else if((rxType === 'rf' && prescReFill?.PartialFillNo > 1 || (prescReFill?.PartialFillNo === 1 && prescReFill?.PartialIndStatus === "P"))) {
            result = true;
        }
        return result;
    }

    rxInsurance(rxInfo) {
        let insurance = null;
        if (rxInfo.Patient && rxInfo.Patient.insurance && rxInfo.Patient.insurance.length > 0) {
            insurance = _.filter(rxInfo.Patient.insurance, {
                insuranceid: +(rxInfo && rxInfo.RefillInsu && (rxInfo.RefillInsu.InsuId ? rxInfo.RefillInsu.InsuId : ""))
            })[0];
            return (insurance && insurance.insuType) ? insurance.insuType.toUpperCase() : ""
        }
    }

    setResetPrescriber(resp: any) {
        this._setResetPresc$.next(resp);
    }
    storeRxInprogessRxEntrySideMenu(val) {
        this._storeInprogressClickOnRxSideMenu$.next(val);
    }

    getPatientDataID(patId) {
        return this._http.get<any>(
            constant.GET_Rx_PatientDataId.replace("{patientId}", "" + patId)
        );
    }

    checkDrugPriceValsChanged(oldDrugInfo, currDrugInfo) {
        let valsChanged = false;
        const mainArr = ["UnitPriceAWP", "UnitPriceCost", "CostPack", "AWPPack", "DirectUnitPrice", "Surcharge", "PerOfBrand", "PriceSchId", "AlternateCost", "DrugTypeId"];
        if(oldDrugInfo && currDrugInfo) {
                for (const i of mainArr) {
                    if (+oldDrugInfo[i] !== +currDrugInfo[i]) {
                        valsChanged = true;
                    }
                }
        }
        return valsChanged;
    }

    checkPatientPriceCodesChange(oldPatInfo, currPatInfo, isBrandedDrug) {
        let valsChanged = false;
        if(oldPatInfo && currPatInfo) {
            const oldDiscCode = (oldPatInfo.PatPricings && oldPatInfo.PatPricings["DiscCode"]) ? oldPatInfo.PatPricings["DiscCode"] : "";
            const newDiscCode = (currPatInfo.PatPricings && currPatInfo.PatPricings["DiscCode"]) ? currPatInfo.PatPricings["DiscCode"] : "";
            const oldBrandCode = (oldPatInfo.PatPricings && oldPatInfo.PatPricings["BrandPriceCode"]) ? oldPatInfo.PatPricings["BrandPriceCode"] : "";
            const newBrandCode = (currPatInfo.PatPricings && currPatInfo.PatPricings["BrandPriceCode"]) ? currPatInfo.PatPricings["BrandPriceCode"] : "";
            const oldGenPriceCode = (oldPatInfo.PatPricings && oldPatInfo.PatPricings["GenericPriceCode"]) ? oldPatInfo.PatPricings["GenericPriceCode"] : "";
            const newGenPriceCode = (currPatInfo.PatPricings && currPatInfo.PatPricings["GenericPriceCode"]) ? currPatInfo.PatPricings["GenericPriceCode"] : "";
            if (oldDiscCode !== newDiscCode) {
                valsChanged = true;
            } else if (isBrandedDrug && oldBrandCode !== newBrandCode) {
                valsChanged = true;
            } else if (!isBrandedDrug && oldGenPriceCode !== newGenPriceCode) {
                valsChanged = true;
            }
        }
        return valsChanged;
    }

    checkInsPriceChanges(oldInsInfo, currInsInfo, isBrandedDrug) {
        let valsChanged = false;
        const priceFields = ["FlatSalesTaxAmt", "TaxRatePer", "SalesTaxBasisId", "IsTaxOnRx", "IsTaxOnOTC", "IsUNCSame", "IsPFForOTC"];
        const numArr = ["FlatSalesTaxAmt", "TaxRatePer", "SalesTaxBasisId"];
        if(oldInsInfo && oldInsInfo.InsuPlanPrice && currInsInfo && currInsInfo.InsuPlanPrice) {
            for (const i of priceFields) {
                if ((numArr.includes(i)) && +oldInsInfo.InsuPlanPrice[i] !== +currInsInfo.InsuPlanPrice[i]) {
                    valsChanged = true;
                } else if (!(numArr.includes(i)) && oldInsInfo.InsuPlanPrice[i] !== currInsInfo.InsuPlanPrice[i]) {
                    valsChanged = true;
                }
            }
            if(!valsChanged && isBrandedDrug && oldInsInfo.InsuPlanPrice["BrandPriceSchId"] !== currInsInfo.InsuPlanPrice["BrandPriceSchId"]) {
                valsChanged = true;
            }
            if(!valsChanged && !isBrandedDrug && oldInsInfo.InsuPlanPrice["GenPriceSchId"] !== currInsInfo.InsuPlanPrice["GenPriceSchId"]) {
                valsChanged = true;
            }
        }
        return valsChanged;
    }

    checkPriceValsChange(priceSheduleList, rxFG, oldPriceVals, newPriceVals) {
        const rxInfo = rxFG.getRawValue();
        let differ = [];
        if(rxInfo && (!(_.isEqual(newPriceVals, oldPriceVals)))) {
            Object.keys(newPriceVals).map(function(key) {
                let oldVal;
                let newVal;
                let hname = (key === "BalDue") ? "Balance Due" : (key === "Price") ? "Ingredient Cost" : (key === "ProFee") ? "Pro. Fee" : (key === "TotalAmt") ? "Total Price" : (key === "UnitPriceUNC") ? "U&C" : (key === "PriceSchId") ? "Price Schedule" : (key === "OtherAmount") ? "Oth.Amount + Oth.Fee + Tax" : "Awp";
                if(key === "PriceSchId" && priceSheduleList) {
                   if (priceSheduleList && priceSheduleList.length) {
                        const index = (priceSheduleList).findIndex(v => v.Id === +oldPriceVals[key]);
                        if (index > -1) {
                            oldVal = (priceSheduleList)[index]["PriceCode"];
                        }
                        const index1 = (priceSheduleList).findIndex(v => v.Id === +newPriceVals[key]);
                        if (index1 > -1) {
                            newVal = (priceSheduleList)[index1]["PriceCode"];
                        }
                    }
                    if (oldVal !== newVal && hname) {
                        differ.push({"Header": hname, "OldValue" : oldVal, "NewValue": newVal});
                    }
                } else {
                    oldVal = oldPriceVals[key] ? oldPriceVals[key] : "";
                    newVal = newPriceVals[key] ? newPriceVals[key] : "";
                    if (+oldVal !== +newVal && hname) {
                        differ.push({"Header": hname, "OldValue" : oldVal, "NewValue": newVal});
                    }
                }
            });
        }
        return differ;
    }
    getCommonDropDownValues(Object, loaderNotReq?, onlyFac?) {
        if (loaderNotReq) {
            return this._http.post<any>(constant.POST_GET_DROPDOWN_VALUES, Object, reqOptns)
        } else {
            return this._http.post<any>(constant.POST_GET_DROPDOWN_VALUES, Object);
        }
    }
    storeFacilityDropDowns(payload) {
        this._faciltityDropdownList$.next(payload)
    }
    storeOverridePrivNotes(notes) {
        this._overridePrivNotes$.next(notes)
    }
    encryptData(value: string) {
        if (value &&  this.secretphrase) {
            value = value +"";
            var CryptoJS = require("crypto-js");
            let _key = CryptoJS.enc.Utf8.parse(this.secretphrase);
            let _iv = CryptoJS.enc.Utf8.parse(this.secretphrase);
            let encrypted = CryptoJS.AES.encrypt(
              value, _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
              });
            return encrypted.toString();
        } else {
            return "";
        }
    }

    DecryptData(value: string, isbase64decodingRequired: boolean = false) {
        try {
        if (value &&  this.secretphrase) {
            if(isbase64decodingRequired){
                value = atob(value);
            }
            var CryptoJS = require("crypto-js");       
            let _key = CryptoJS.enc.Utf8.parse(this.secretphrase);
            let _iv = CryptoJS.enc.Utf8.parse(this.secretphrase);
            let decrypted = CryptoJS.AES.decrypt(
            value, _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
            return decrypted;
        } else {
            return "";
        }
    } catch (e) {
        console.log(e, "Exce");
    }
    }
    getSideNavCounts( dateParams: RxDateInputParams ): any {
        this._http.post<any>(
            constant.GET_SIDE_NAV_COUNTS,
            dateParams, reqOptns
        ).subscribe(resp => {
            const data = Object.assign({}, resp);
            const data1 = Object.assign(data, {modifiedData: moment.utc().local().format("MM/DD/YYYY hh:mm:ss a")});
            this._dashboardBillStat$.next(data1);
        });
        this._http.post<any>(
            constant.POST_Rx_RefillsQueueDataForDashBoard,
            dateParams, reqOptns
        ).subscribe(resp => {
            this._refillQueueDashboardCount$.next(resp);
        });
    }
    getSubcatAndStoreInLocalStorage(addHdrs?:boolean) {
        if (addHdrs) {
            this._http.get<any>(constant.GET_DMS_SUBCATS,reqOptns).subscribe((resp: any) => {
                if (resp && resp.length) {
                    let rxSubCat = resp.find(elm => elm.Name.toLowerCase() === 'prescription');
                    if (rxSubCat) {
                        localStorage.setItem("rxDefaultSubCat", this.encryptData(rxSubCat.Id));
                    }
                }
            });
        } else {
            this._http.get<any>(constant.GET_DMS_SUBCATS).subscribe((resp: any) => {
                if (resp && resp.length) {
                    let rxSubCat = resp.find(elm => elm.Name.toLowerCase() === 'prescription');
                    if (rxSubCat) {
                        localStorage.setItem("rxDefaultSubCat", this.encryptData(rxSubCat.Id));
                    }
                }
            });
        }
   }
   getDateFormat(date) {
             if(date)
                    return moment(date).format(MsgConfig.DATE_FORMAT);
             else
                 return "";
   }
   getCurrentDate() {
    return moment().format(MsgConfig.DATE_FORMAT);
   }
   setDoseCheckData(data:any){
    this.dcdata = data;
   }

   getDoseCheckData(){
    return this.dcdata;
   }
   setDoseCheckStatus(val:boolean){
    this._dcdataStatus$.next(val);
   }
   getDoseCheckStatus(){
    return this.dcdataStatus$;
   }

   setDoseCheckPopDisp(val:boolean){
    this._showdcDataPop$.next(val)
   }
   getDoseCheckPopDisp(){
    return this.showdcDataPop$;
   }

   setDoseCheckValidation(val:boolean){
    this._isDoseDisabled$.next(val);
   }
   getDoseCheckValidation(){
    return this.isDoseDisabled$;
   }
//    resetDoseCheckValidation(){
//     this.isDoseDisabled = true;
//    }
   setConsolidatedPopupData(val:any){
    //this._setCsldtPopData$.next(val)
    this.csldtData = val
   }
   getConsolidatedPopupData(){
    return this.csldtData;
   }
   resetConsolidatedPopupData(){
    this.csldtData = []
   }

   setInputFocus(val:boolean){
    this.isInputFocus = val
   }
   getInputFocus(){
    return this.isInputFocus
   }
   setClinicalAlertsDisplay(val:boolean){
    this.displayClnclAlrts = val
   }
   getClinicalAlertsDisplay(){
    return this.displayClnclAlrts;
   }
   resetClinicalAlertsDisplay(){
    this.displayClnclAlrts = false;
   }
   addDoseToClinicalAlerts(val:any){
    this.csldtData.push(val)
   }
   setAllergyChecked(val){
    this.isAllergyChecked = val;
   }

   getAllergyChecked(){
    return this.isAllergyChecked;
   }
   resetAllergyChecked(){
    this.isAllergyChecked = false;
   }

   getUserGuideDataWithId(pageId : string) {
        return this._http.get<any>(constant.GET_ReleaseNotes.replace("{pageId}",""+pageId));
    }
    getUserGuideDataWithKey(pageKey : string, addHdrs?:boolean) {
        if (addHdrs) {
            return this._http.get<any>(constant.GET_UserGuide.replace("{pageKey}",""+pageKey),reqOptns);
        } else {
            return this._http.get<any>(constant.GET_UserGuide.replace("{pageKey}",""+pageKey));
        }
    }

    getCtrlorNonctrlChange(oldrx,newrx){
        const setval = +this.getSetttingValue("PharmacySettings", "RX_NO_GEN_SYST")
        if( setval=== 5 ){
            const nctrlarr = [2, 3, 4, 5];
            return (this.ctrlarr.includes(oldrx)&& nctrlarr.includes(newrx) || (this.ctrlarr.includes(newrx) && nctrlarr.includes(oldrx)));
        } else if (setval === 6){
            const narcarr= [2]
            const nctrlarr = [3, 4, 5];
           if(this.ctrlarr.includes(oldrx)){
            return (narcarr.includes(newrx) || nctrlarr.includes(newrx));
           }else if(narcarr.includes(oldrx)){
            return (this.ctrlarr.includes(newrx) || nctrlarr.includes(newrx));
           }else {
            return (this.ctrlarr.includes(newrx) || narcarr.includes(newrx));
           }
        } else if(setval === 7){
            const narcarr= [2]
            const nctrlarr = [0,1,3, 4, 5];
            return (narcarr.includes(oldrx)&& nctrlarr.includes(newrx) || (narcarr.includes(newrx) && nctrlarr.includes(oldrx)));
        }
        else{
            return false;
        }
    }
    storePrescNum(val){
        this.prescNum = val
    }
    getPrescNum(){
       return this.prescNum ? this.prescNum : null;
    }

    getInterfacePharmSettings(data: any, addHdrs?:boolean): Observable<any> {
        if (addHdrs) {
            let headers = new HttpHeaders();
            headers = headers.set("doNotDefBaseUrl", "true");
            headers = headers.set("isLoadNotReq", "true");
            return this._http.post<any>(
                constant.POST_Interface_PharmacySettings,
                data, { headers: headers}
            );
        } else {
            const reqOptns = {
                headers: new HttpHeaders().set("doNotDefBaseUrl", "true")
            };
            return this._http.post<any>(
                constant.POST_Interface_PharmacySettings,
                data, reqOptns
            );
        }
    }

    modifyInterfacePharmSettings(data: any): Observable<any> {
        const reqOptns = {
          headers: new HttpHeaders().set("doNotDefBaseUrl", "true")
    
      };
        return this._http.post<any>(
            constant.POST_Interface_ModifyPharmacySettings,
            data, reqOptns
        );
    }
    getInterfaceToken() {
        // const reqOptns = {
        //     headers: new HttpHeaders().set("authorizeInterfaceToken", "true")
        // };
        return this._http.get<any>(constant.GET_Interface_Token, reqOptns);
    }

    generateInstallKey() {
        return this._http.get<any>(constant.GET_Installation_Key);
    }

    getrxDataRRR(payload:any) {
        return this._http.post<any>(constant.POST_Rx_RRR, payload);
    }

    getLoggedPharmacists(){
        return this._http.get<any>(constant.GET_LoggedPharmacists);
    }
    ssoTokenRegeneration(data){
        return this._http.post<any>(constant.POST_TokenRegeneration, data);
    }

    getVaccineImmunizationInfo(payLoad: any) {
        return this._http.post<any>(constant.POST_Rx_VaccineImmunization, payLoad);
    }

    updateVaccineImmunization(payLoad: any) {
        return this._http.post<any>(constant.Update_Rx_VaccineImmunization, payLoad);
    }

    getInterfaceRegenerateKey() {
        return this._http.get<any>(constant.GET_RegenerateKey);
    }
    getEquiDeferredRxs(payLoad : any){
        return this._http.post<any>(constant.POST_EquiDeferredRxs, payLoad);
    }
    getCmnAuthToken() {
        return this._http.get<any>(constant.GET_AUTH_TOKEN_MCSVC, reqOptns);
    }

    getInterfaceDscsaSettings() {
        return this._http.get<any>(constant.GET_Interface_DscsaSettings);
    }

    modifyInterfaceDscsaSettings(payLoad : any) {
        return this._http.post<any>(constant.POST_Interface_ModifyDscsaSettings,payLoad);
    }

    getFormsWithEntity(payLoad: any) {
        const reqOptns1 = {
            headers: new HttpHeaders().set("formsBaseUrl", "true")
      
        };
        return this._http.post<any>(constant.POST_GetFormsWithEntity,payLoad, reqOptns1);
    }
    getFormDetails(formID) {
        const reqOptns1 = {
            headers: new HttpHeaders().set("formsBaseUrl", "true")
      
        };
        return this._http.get<any>(constant.GET_FORMS.replace("{formId}",""+formID), reqOptns1);
    }

    saveFormDetails(payload) {
        const reqOptnssave = {
            headers: new HttpHeaders().set("formsBaseUrl", "true")
      
        };
        return this._http.post<any>(constant.SAVE_FORMS, payload, reqOptnssave)
    }
    viewSavedFormResponse(payload) {
        const reqOptns1 = {
            headers: new HttpHeaders().set("formsBaseUrl", "true")
      
        };
        return this._http.post<any>(constant.VIEW_SAVED_FORM,payload, reqOptns1);
    }
    updateFormDetails(payload) {
        const reqOptnssave = {
            headers: new HttpHeaders().set("formsBaseUrl", "true")
      
        };
        return this._http.put<any>(constant.UPDATE_FORMS, payload, reqOptnssave)
    }
    async getNoteCategoriesList(addHdrs?:boolean) {
        let resp;
        if (addHdrs) {
            resp = await this._http.get(constant.GET_NoteCategoriess, reqOptns).toPromise();
        } else {
            resp = await this._http.get(constant.GET_NoteCategoriess).toPromise();
        }
        this._notesCatList$.next(resp);
     }

    getPAQueueStats(isForQueueData): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        return this._http.get(constant.GET_PAQueueStats.replace("{isForQueueData}", "" + isForQueueData), {headers: headers});
    }

}

