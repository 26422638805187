<div class="exprx--common-block erx--body insurance--body patient-edit--transfer-in">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        Transfer Type
    </div>
    <div 
    class="eprx--block__content pt-0 px-0 height_300" [formGroup]="eRxModelFG">
    <div class="row col-11  ml-2 mt-3"  style="margin-top: 3.5rem !IMPORTANT;" [ngClass]="(IsHistory || IsfrmPatHis) ? 'transfer--type-disabled mt-5' : 'transfer--type-active'">
        <div class="col-10">
            <input  class="transfer_type" type="radio" name="user_level"  value="1" [formControlName]="'transferType'"  [disabled]="IsHistory || IsfrmPatHis"  id="rd1"  (click)="IsfrmPatHis ? '': openDrugSearchpopup()"/>&nbsp;&nbsp;
            <label for="rd1">Search from History</label> 
        </div>
        <div class="col-2">
            <i class="far fa-history  actions--Highlightfocus--icon" (click)="IsfrmPatHis ? '' : openDrugSearchpopup()"  title="Patient History"></i>
        </div>
    </div>
    <div class="row col-11  ml-2 mt-3" [ngClass]="(IsfrmPatHis) ? 'transfer--type-disabled' : 'transfer--type-active'">
        <div class="col-10">
            <input type="radio" class="transfer_type" name="user_level" [disabled]="IsfrmPatHis"  id="rd2" value="2" [formControlName]="'transferType'" (click)="IsfrmPatHis ? '' : openDrugSearchpopup('Drug')"/>&nbsp;&nbsp;
            <label for="rd2">Specific Drug/NDC</label> 
        </div>
        <div class="col-2">
            <i class="fa-kit fa-pills-regular" (click)="IsfrmPatHis ? '' : openDrugSearchpopup('DrugIcon')"></i> 
        </div>
    </div>
    <div class="row col-11  ml-2 mt-3" [ngClass]="(IsfrmPatHis) ? 'transfer--type-disabled' : 'transfer--type-active'">
        <div class="col-10">
            <input type="radio" class="transfer_type" name="user_level" [disabled]="IsfrmPatHis"  value="3"  id="rd3"  [formControlName]="'transferType'" (click)="IsfrmPatHis ? '' : openDrugSearchpopup('All')"/>&nbsp;&nbsp;
            <label for="rd3">All</label>
        </div>
        <div class="col-2">
            <i class="fa-kit fa-file-prescription-regular" (click)="IsfrmPatHis ? '' : openDrugSearchpopup('All')"></i>
        </div>
    </div>
    </div>
</div>
<app-searchbox-global [IsDrugPopup]="true" *ngIf="isDrugSearchopen" [IsFromTransferIn]="true" (SelectedDrug)="selectedDrug($event)"></app-searchbox-global>