<button footer class="secondary" appShortcutKey
    (click)="showConnectionString()" [InputKey]="'s'">
    <span>S</span> Trans. Data
</button>

<button footer class="secondary" appShortcutKey [InputKey]="'n'" *ngIf="transmissionType !== 'TransMessage'  && !isTransmsnPaid && !restrictRejectTrans"
    (click)="reTransmission()">
    <span>N</span> Tr-Now
</button>

<button footer class="secondary" appShortcutKey [InputKey]="'r'"
    (click)="redirectToEditRx()"
    *ngIf="transmissionType !== 'TransMessage' && oldRxInfo && oldRxInfo['rxType'] !== 'er' && isRjctActnEnbld && !restrictRejectTrans">
    <span>R</span> Correct
</button>

<button footer class="inactive"
    *ngIf="transmissionType !== 'TransMessage' && oldRxInfo && oldRxInfo['rxType'] === 'er' && isRjctActnEnbld && !restrictRejectTrans">
    <b>R</b>
    Correct
</button>

<button footer class="secondary" (click)="convertToCash(true)"
    *ngIf="isRjctActnEnbld && transmResp?.ClaimProcess?.BILLAS === 'P' && !isCashDisable && !restrictRejectTrans"
    appShortcutKey [InputKey]="'a'">
    <span>A</span> Cash
</button>

<button footer class="inactive"
    *ngIf="isRjctActnEnbld && transmResp?.ClaimProcess?.BILLAS === 'P' && isCashDisable && !restrictRejectTrans" >
    <span>A</span>
     Cash
</button>

<button footer class="secondary" appShortcutKey
    (click)="showActionModal('FileRx')" [InputKey]="'f'"
    *ngIf="isRjctActnEnbld &&
        transmResp?.transmissionDetails?.IsLatestRefill && transmResp?.transmissionDetails?.IsPartialLatestfill && !restrictRejectTrans">
    <span>F</span>Defer
</button>

<button footer class="secondary" appShortcutKey
    (click)="showActionModal('DUR')" [InputKey]="'b'"
    *ngIf="transmResp?.transmissionDetails?.ResponseStatus === 'R' ||
        transmResp?.transmissionDetails?.ResponseStatus === 'TRANSMISSION ERROR'">
    <span>B</span>DUR
</button>

<button footer class="secondary" appShortcutKey [InputKey]="'o'" *ngIf="isProrAuth"
    (click)="showActionModal('RejectPriorApp'); secondaryPopUpModal('RejectPriorApp')">
    <span>O</span>Prior APP
</button>
<button footer class="secondary" footer
    (click)="printScreen()" appShortcutKey [InputKey]="'t'">
    <span>T</span>Print Screen
</button>

<button footer class="secondary" appShortcutKey [InputKey]="'P'"
    (click)="accRejPopUp()"
    *ngIf="transmResp?.transmissionDetails?.ResponseStatus">
    <span>P</span>Print Acce/Rej
</button>

<button footer class="secondary" [InputKey]="'u'"
    (click)="initiateFollowupTag()"
    *ngIf="isRjctActnEnbld && !restrictRejectTrans" appShortcutKey>
    <span>U</span>Followup Tag
</button>

<button footer class="secondary" *ngIf="isRjctActnEnbld && !restrictRejectTrans"
    (click)="showActionModal('DeleteRx')"
    appShortcutKey [InputKey]="'d'">
    <span>D</span>Delete
</button>

<button footer class="secondary"
    (click)="revNoLablndContinueTransm()"
    *ngIf="isTransmsnPaid && transmissionType !== 'TransReverse' &&
        transmissionType !== 'TransMessage' && enablNoLabl"
    appShortcutKey [InputKey]="'n'">
    <span>N</span>No Label
</button>

<button footer ngbAutofocus class="secondary"
    (click)="reviewAndContinueTransm()"
    *ngIf="isTransmsnPaid || isTransmsnPaidRej"
    appShortcutKey [InputKey]="'c'">
    <span>C</span>Continue
</button>

<button footer ngbAutofocus class="secondary"
    (click)="reviewRejectedTransmModal($event)"
    *ngIf="(!isTransmsnPaidRej && transmResp?.transmissionDetails?.ResponseStatus === 'R') ||
            transmResp?.transmissionDetails?.ResponseStatus ===
                'TRANSMISSION ERROR'"
    appShortcutKey [InputKey]="'c'">
    <span>C</span> Continue
</button>

<app-view-trans-msg
    *ngIf="transActnType === 'transString'"
    [RxInfo]="rxInfo$ | async"
    [ViewTransString]="transmResp?.TransStrObject"
    [IsNotFromTrans]="false"
    (IsPopUpClosed)="clearActionModalType()"
></app-view-trans-msg>

<!-- <app-bill-to-cash
    *ngIf="transActnType === 'BillToCash'"
    [rxInfo]="rxInfo"
    [transmissionType]="transmissionType"
    (CloseBillToCashPopup)="closeBillToCash($event)"
    (CancelCashPopUp)="clearActionModalType()"
></app-bill-to-cash> -->

<app-common-modal
    *ngIf="secondModalName"
    [commonModalName]="commonModalName"
    [rxInfo]="rxInfo$ | async"
    (saveTransmitInfo)="saveTransmitClaims()"
    (CancelPopUp)="emitAsTransmissionCompleted()"
></app-common-modal>

<app-delete-rx  [systemData]="systemData" [RxInfo]="rxInfo$ | async"  *ngIf="transActnType === 'DeleteRx'"
    (IsPopUpClosed)="reviewRejectedTransmModal($event)" (IsPopUpCancelled)="closeActionModals($event)"></app-delete-rx>

<app-rx-file [rxInfo]="rxInfoData" *ngIf="transActnType === 'FileRx'" [rxFG]="rxFG"
    (IsPopUpClosed)="reviewRejectedTransmModal($event)" (CancelPopUpClosed)="closeActionModals($event)"></app-rx-file>

<app-prior-apprvl [rxAndActionType]="{rxType : rxFG?.value?.rxType, rxActnType : rxFG?.value?.rxActnType}"   [rxInfo]="rxInfo$ | async" *ngIf="transActnType === 'RejectPriorApp' && isProrAuth" [transmResp]="transmResp" [vendorId]="vendorId" (CancelPopUpClosed)="closeActionModals($event)"></app-prior-apprvl>

<app-rx-follow-tag
    *ngIf="transActnType === 'FWUPTAG'"
    (IsPopUpClosed)="reviewRejectedTransmModal($event)"
    (CancelFolwUpTag)="closeActionModals($event)"
    [rxInfo]="rxInfo$ | async"
    [frmTrans]="true"
    [folwUpList]="folwUpList$ | async">
</app-rx-follow-tag>

<app-trans-dur
*ngIf="transActnType === 'DUR'"
[rxno]="(rxInfo$ | async)?.Prescription?.Id"
[refId]="(rxInfo$ | async)?.PrescReFill?.Id"
[systemData]="systemData"
[openRxDur]="true"
(IsPopupClosed)="closeActionModals($event)">
</app-trans-dur>

<app-next-bill-to-insurance *ngIf="transActnType === 'NxtInsuBillTo'" [nxtInsuLst]="nxtInsuLst" [nxtInsuToBill]="nxtInsuToBill"
    [systemData]="systemData"  [rxAndActionType]="{rxType : rxFG?.value?.rxType, rxActnType : rxFG?.value?.rxActnType}"   [rxInfo]="rxInfo" [payRecords]="payRecords" (CanclSecndryInsPopUp)="emitTransComplted($event)"
    (NextBillToInsInfo)="checkAndInitiateNextTrans($event)"  (NextBillToInsuarnce)="checkAndInitiateNextForInsuarnce($event)"></app-next-bill-to-insurance>

    <ng-template #TRANSREJPOPUP let-c="close" let-d="dismiss" class="el--popup">
        <div class="modal-header">
            <h4 class="modal-title">Insurance Transmission Information</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');checkReset()">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <button *ngIf="hasFaxNumber" class="hotkey_primary" (click)="d('Cross click');sendFax()" appShortcutKey InputKey="s"><b>S</b>Send Fax </button>
                        <button class="hotkey_primary" (click)="d('Cross click');printRejTrans()" appShortcutKey InputKey="p"><b>P</b>Print to Printer
                        </button>
                        <button class="hotkey_primary" (click)="printRejTrans(true)" appShortcutKey InputKey="v"><b>V</b>Preview
                        </button>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 height_2_5rem" >
                    <input class="form-check-input" type="checkbox" [id]="'PrintCmsForm'" [(ngModel)]="cmsSelected"/>
                    <label class="form-check-label" [for]="'PrintCmsForm'" id="'Print Cms Form'">
                        Print CMS 10147 Form?
                    </label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="text-right pull-right col-md-10 padding-0">
                <div>
                    <button class="hotkey_primary" (click)="d('Cross click');checkReset()" appShortcutKey InputKey="c"><b>C</b>Cancel
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <app-print-fax-request [isFromTrans]="true" [transmResp]="transmResp" [isCmsChecked]="cmsSelected" [RxInfo]="rxInfo$ | async" (IsPopUpClosed)="closeSFax($event)" *ngIf="sendFaxFromTrans"></app-print-fax-request>

    <ng-template #PREVIEW let-c="close" let-d="dismiss" class="el--popup">
        <div class="modal-header">
            <h4 class="modal-title">Preview</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="pdfContent">
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="750px"
                    type="application/pdf"></iframe>
            </div>
        </div>
        <div class="modal-footer">
            <div class="text-right pull-right col-md-10 padding-0">
                <div>
                    <button *ngIf="hasFaxNumber" class="hotkey_primary" (click)="sendFax()" appShortcutKey InputKey="s"><b>S</b>Send Fax </button>
                    <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c"><b>C</b>Close
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
