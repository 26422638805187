import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
    providedIn: "root"
})

export class CommonStore {
    _insurancesList$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    insurancesList$: Observable<any> = this._insurancesList$.asObservable();

    _rxSelectedForRf$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    rxSelectedForRf$: Observable<any> = this._rxSelectedForRf$.asObservable();

    _rfProcessStage$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    rfProcessStage$: Observable<any> = this._rfProcessStage$.asObservable();

    _rphFilters$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    rphFilters$: Observable<any> = this._rphFilters$.asObservable();

    _dashFilters$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    dashFilters$: Observable<any> = this._dashFilters$.asObservable();

    _rxWJVal$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    rxWJVal$: Observable<any> = this._rxWJVal$.asObservable();

    _cancelForDocumentQueue$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    cancelForDocumentQueue$: Observable<any> = this._rxWJVal$.asObservable();

    _prevoiusScreenInRefill$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    prevoiusScreenInRefill$: Observable<any> = this._rxWJVal$.asObservable();

    _closeAddDrugBucket$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    closeAddDrugBucket$: Observable<any> = this._rxWJVal$.asObservable();

    _partialFillChangeinRxFlow$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    partialFillChangeinRxFlow$: Observable<any> = this._rxWJVal$.asObservable();

    _patientInfo$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    patientInfo$: Observable<any> = this._patientInfo$.asObservable();

    _prevoiusScreenPatientRxistory$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    prevoiusScreenPatientRxistory$: Observable<any> = this._rxWJVal$.asObservable();

    _labelDocs$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    labelDocs$: Observable<any> = this._labelDocs$.asObservable();

    _notifCount$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    notifCount$: Observable<any> = this._notifCount$.asObservable();

    _druLot$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    drugLotData$: Observable<any> = this._druLot$.asObservable();

    _druLotForBarCode$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    drugLotForBarCode$: Observable<any> = this._druLot$.asObservable();

    _afterLowInventory$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    afterLowInventory$: Observable<any> = this._druLot$.asObservable();

    _ngOnchangesRestriction$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    ngOnchangesRestriction$: Observable<any> = this._ngOnchangesRestriction$.asObservable();

    _reportGenerated$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    reportGenerated$: Observable<any> = this._reportGenerated$.asObservable();

    _viewReport$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    viewReport$: Observable<any> = this._viewReport$.asObservable();

    _afterPrint$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    afterPrint$: Observable<any> = this._afterPrint$.asObservable();

    _reportTab$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    reportTab$: Observable<any> = this._reportTab$.asObservable();

    _rejectReasonUpdate$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    rejectReasonUpdate$: Observable<any> = this._rejectReasonUpdate$.asObservable();

    _clinicalAddress$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    clinicalAddress$: Observable<any> = this._clinicalAddress$.asObservable();

    _intakeQueueRxs$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    intakeQueueRxs$: Observable<any> = this._intakeQueueRxs$.asObservable();

    _interfacePharmConfigSetting$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    interfacePharmConfigSetting$: Observable<any> = this._interfacePharmConfigSetting$.asObservable();

    _allPrinters$:BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    allPrinters$: Observable<any> = this._allPrinters$.asObservable();

    _custmReprtList$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    custmReprtList$: Observable<any> = this._custmReprtList$.asObservable();

    _timeOutSession$:BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    timeOutSession$: Observable<any> = this._timeOutSession$.asObservable();

    _signlrDPVData$:BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    signlrDPVData$: Observable<any> = this._signlrDPVData$.asObservable();

    _signlrPaQueueData$:BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    signlrPaQueueData$: Observable<any> = this._signlrPaQueueData$.asObservable();
    constructor() {}

    storeAllInsurancesList(data) {
        this._insurancesList$.next(data);
    }
    partialFillChange(data) {
        this._partialFillChangeinRxFlow$.next(data);
    }


    storeRefInfo(data) {
        this._rxSelectedForRf$.next(data);
    }

    storeRfStage(data) {
        this._rfProcessStage$.next(data);
    }

    storeRphFilters(data) {
        this._rphFilters$.next(data);
    }

    storeDocumentQueueCancel(data) {
        this._cancelForDocumentQueue$.next(data);
    }
    previousRefillScreen(val) {
        this._prevoiusScreenInRefill$.next(val);
    }
    previousScreenErxOrPatient(val) {
        this._prevoiusScreenPatientRxistory$.next(val);
    }
    closeAddDrugToBucket(val) {
        this._closeAddDrugBucket$.next(val);
    }
    storeDashFilters(data) {
        this._dashFilters$.next(data);
    }

    storeRxHisWj(data) {
        this._rxWJVal$.next(data);
    }
    storePatientInfo(data) {
        this._patientInfo$.next(data);
    }
    storeDrugLotData(data) {
        this._druLot$.next(data);
    }
    storeDrugLotDataForBarCode(data) {
        this._druLotForBarCode$.next(data);
    }
    saveOrTransmitAfterLowInventory(data) {
        this._afterLowInventory$.next(data);
    }
    ngOnchangesRestrictionAfterClosingEditRx(data) {
        this._ngOnchangesRestriction$.next(data);
    }
    updateRejectReasonList(data) {
        this._rejectReasonUpdate$.next(data);
    }
    storeInterfacePharmConfigSettings(data) {
        this._interfacePharmConfigSetting$.next(data);
    }
    storeAllPrinters(printersList) {
        this._allPrinters$.next(printersList);
    }
    storeAllCustmReportList(data) {
        this._custmReprtList$.next(data);
    }
    isTimeOutSession(data) {
        this._timeOutSession$.next(data);
    }
    storeSignlRDPVInfo(data) {
        this._signlrDPVData$.next(data);
    }
    storeSignlRPaQueueData(data) {
        this._signlrPaQueueData$.next(data);
    }
}
