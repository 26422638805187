import { AlertService } from "./../../../services/alert.service";
import { ErxProcess, ErxStatusAndMessageTypeEnums, ErxVersions } from "./../../../models/erx-filter.model";

import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    ViewChild,
    ElementRef,
    HostListener,
    ChangeDetectorRef
} from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ErxService, CommonService, PatPreDrugModalService, RxService } from "../../../services";
import "rxjs/add/operator/map";
import * as moment from "moment";
import { ErxFilter, AccCategoryE, RxE, RxDateInputParams, RxOrigin, eRxE, eRxActionListE, PaginationModel } from "../../../models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ColorCode, NRxUtils, RxUtils, WijimoUtil } from "src/app/utils";
import { PrintService } from "../../../services/print.service";
import { MsgConfig } from "../../..";
import { PrivMaskGuard } from "src/app/guards";
import { DrugInformationComponent } from "../../edit-drug";
import { NotesLogComponent } from "../../shared";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { debounceTime, takeUntil } from "rxjs/operators";
import { NewBenefitInsuranceComponent } from "../new-benefit-insurance/new-benefit-insurance.component";
import { AdditionalInsurancesCommonComponent } from "../../shared/additional-insurances-common/additional-insurances-common.component";
import { CommonStore, RxStore } from "src/app/store";
import { Subject, from } from "rxjs";
import { FormatsUtil } from "src/app/utils/formats.util";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-erx-listing",
    templateUrl: "./erx-listing.component.html"
})
export class ErxListingComponent implements OnInit, OnChanges {
    paginationModel: PaginationModel = new PaginationModel();
    eRxListingWJ: CollectionView;
    changes = [];
    pagenos: any[] = [];
    Size = this.paginationModel.pageSize;
    pageNu = this.paginationModel.pageNumber;
    totalCount: number;
    pageCount: number;
    errorCount = 0;
    awaitingCount = 0;
    readyCount = 0;
    processedCount = 0;
    PaToolTip: string;
    selectedErx: any;
    prToolTip: string;
    DrugToolTip: string;
    value = true;
    fromRph: boolean = false;
    warningPop: any;
    // data = this.getData(50);
    eRxData: any;
    transactionId: any;
    awaittransId: any;
    rxType: any;
    effectiveDate: any;
    erxRowInfo: any;
    erxNotes = false;
    erxProcess: ErxProcess = new ErxProcess();
    patPrecheck = false;
    closeIntakeModal = false;
    eRxRecord: any;
    isBenfitCheckShow: boolean = false;
    selectedBenefitCheckType = "";
    closeSupplyDaysPop: any;
    eRxIds: any = [];
    benefitCheckDetails: any;
    @Input()
    erxFilterData: ErxFilter;
    @Output() GetErxCount = new EventEmitter<any>();
    @Output()
    ErrorCount = new EventEmitter<any>();
    @Output()
    AwaitingCount = new EventEmitter<any>();
    @Output()
    ReadyCount = new EventEmitter<any>();
    @Output()
    ProcessedCount = new EventEmitter<any>();
    @Output()
    StoreFilters = new EventEmitter<any>();
    @Output()
    CloseErxMessageLinkModal = new EventEmitter<any>();
    @Output()
    CloseErxModal = new EventEmitter<any>();
    @Output()
    SelectedRecords = new EventEmitter<any>();
    @Output()
    emitEnableDisableEvnt = new EventEmitter<any>();
    wjHeaders: any;
    wjHeadersExtraInfo: any;
    wjHeadersExtraInfoInsurance: any;
    fromIntakeQueue: boolean;
    loading: boolean;
    progressValue: number;
    patientResp: any;
    displayGrid: boolean;
    transferInRequProcess: any;
    @Input()
    set RxType(lt: any) {
        this.rxType = lt;
    }
    @Input() RxExtraInfoObj: any;
    @ViewChild("erxActionlist")
    flex: wjcGrid.FlexGrid;
    @ViewChild("processInfo", { static: true })
    processInfo: ElementRef;
    @ViewChild("processStatus", { static: true })
    processStatus: ElementRef;
    @ViewChild("cancelRxExist", { static: true })
    cancelRxExist: ElementRef;
    @ViewChild("rxChangeRespTemp", { static: true })
    rxChangeRespTemp: ElementRef;
    @ViewChild("erxExtraInfo", { static: true })
    erxExtraInfo: ElementRef;
    @ViewChild("compList", { static: true })
    compList: ElementRef;
    @ViewChild("ErxAlreadyProcessed", { static: true })
    ErxAlreadyProcessed: ElementRef;
    @ViewChild("CantProcessTemp", { static: true })
    CantProcessTemp: ElementRef;
    @ViewChild("PatNotMatch", { static: true })
    PatNotMatch: ElementRef;
    @ViewChild("RecallErxProcessing", { static: true })
    RecallErxProcessing: ElementRef;

    @ViewChild("benfitCheckSupllyDays", { static: true })
    benfitCheckSupllyDays: any;

    @ViewChild("content2", { static: true })
    content2: any;


    @HostListener('window:resize', ['$event']) onResize(event) {
        this.gridHeightDefine();
    }
    @Input()
    set TransferInReq(transferInReq : any){
        if(transferInReq)
        this.openTransferInReq(transferInReq);
    }
    selectedValue: any;
    isFromBefitCheck: boolean = false;
    neglectProcessStatusPopup: any;
    @Input() rxFG: FormGroup;
    @Input() isPopErxModel: boolean;
    @Input() IsFromRxHistoryDisplay: any;
    @Input() CurrentDetails: any;
    supplyDaysFG: FormGroup;
    @Input()
    set FromRph(bl: boolean) {
        this.fromRph = bl;
    }
    @Input()
    set FromIntakeQueue(bl: boolean) {
        this.fromIntakeQueue = bl;
    }

    @Input()
    set RxInfo(data: any) {
        if (data) {
            this.rxInfo = data;
        }
    }
    errorMessage: any;
    awaitInfo: any;
    detailsInfo: any;
    responseState: any;
    currentItem: any;
    currentDetails: any;
    modalReference: any;
    processResponse: any;
    modalRef: any;
    selectedErxData: any;
    printErx = false;
    messageType: string;
    selectedrxNo: any;
    openOverride: boolean;
    privType: any;
    accessPrivCate: any;
    categoryId: any;
    rxPrivs: any;
    formattedResult: any;
    dateParams: RxDateInputParams;
    activeModal: any;
    erxChangeData: { erxId: any; patientId: any; prescriberId: any; drugId: any; messageType: any; };
    eRxRowData: any;
    changeRespStatus: any;
    cancelRxRequestData: any;
    changeRequestResp: any;
    totalHeaders: any;
    inactiveHeaders: string[];
    drugAddFrmPopup: any;
    insuranceWJ: CollectionView;
    activeHeaders: string[];
    inactiveHeader: string[];
    erxDetailsData: any;
    erxProcessIds: ErxProcess;
    printRefWJ: CollectionView;
    rxInfoArry: any;
    rxOriginList: RxOrigin[];
    errorMsg: string;
    rxInfo: any;
    eRxVersion: any;
    patientDetails: any;
    rxDays: any;
    erxChangeOpenType: any;
    _wijHeight: any;
    isBenefitCheckProcess: boolean = false;
    selectedInsuranceDetails: any = [];
    rxOrdDt: any;
    patientInsuranceWJ: CollectionView;
    patientInsuranceArray: any = [];
    insuranceData: any;
    processingeRxs: any = [];
    isDataExits = true;
    unsubscribe$: Subject<void> = new Subject();
    selectedItemList: any = [];
    IseRxTransferRequest: boolean = false;
    IseRxTRxREQ: boolean = false;
    ngOnChanges(changes: any): void {
        var isFiltersNotSame: boolean = false;
        if (changes) {
            if (this.isPopErxModel) {
                if (changes && changes.erxFilterData && (changes.erxFilterData.previousValue)) {
                    const o1 = changes.erxFilterData.previousValue;
                    const o2 = changes.erxFilterData.currentValue;
                    for (var p in o1) {
                        if (o1.hasOwnProperty(p)) {
                            if (o1[p] !== o2[p]) {
                                isFiltersNotSame = true;
                            }
                        }
                    }
                } else {
                    this.updateFilterData();
                }
                if (isFiltersNotSame) {
                    this.updateFilterData();
                }
            } else if (this.fromRph) {
                this.erxFilterData.FirstName = this.rxInfo["Patient"].firstname;
                this.erxFilterData.LastName = this.rxInfo["Patient"].lastname;
                this.erxFilterData.DOB = this.rxInfo["Patient"].dob;
                this.updateFilterData();
            } else if (this.fromIntakeQueue) {
                this.updateFilterData();
            } else {
                if (changes && changes.erxFilterData && (changes.erxFilterData.currentValue !== changes.erxFilterData.previousValue)) {
                    this.updateFilterData();
                }
            }
        }
    }

    constructor(
        private _erxService: ErxService,
        private modalService: NgbModal,
        private _router: Router,
        private _rxUtils: RxUtils,
        private _commonSvc: CommonService,
        private _privMaskGuard: PrivMaskGuard,
        private _printSer: PrintService,
        private _nrxUtls: NRxUtils,
        private _fb: FormBuilder,
        private _alertServ: AlertService,
        private _cdr: ChangeDetectorRef,
        private _patPreDrugService: PatPreDrugModalService,
        private _wijimoUtils: WijimoUtil,
        private _commonStore: CommonStore,
        private  _alertSvc: AlertService,
        private _rxService: RxService,
        private _rxStore : RxStore,
        private _colorCode: ColorCode,
        public _formatsUtil: FormatsUtil
    ) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.dateParams = {
            StartDate: null,
            EndDate: null
        };
        this.dateParams.StartDate = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD");
        this.dateParams.EndDate = moment()
            .subtract(0, "days")
            .format("YYYY-MM-DD");
    }

    ngOnInit() {
        this.IseRxTransferRequest = environment.IseRxTransferRequest;
        this._commonSvc.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.rxOriginList = resp.RxOrigin;
            }
        });
        this.createFG();
        const versionsetting = this._commonSvc.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
        if (versionsetting == 2) {
            this.eRxVersion = ErxVersions.NewerVersion;
        } else {
            this.eRxVersion = ErxVersions.OldVersion;
        }
        if (this.rxFG && this.rxFG.value["Patient"]) {
            this.patientDetails = this.rxFG.value["Patient"];
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "erxListingWJ" || w["WijmoKey"] === "eRxExtraInfoListWJ" || w["WijmoKey"] === "insuaranceListeRxListwj")) {
                this.patchDefaultValHeaders();
            }
        });
        this._commonSvc.setResetPresc$.pipe(
            debounceTime(1000)
        ).pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp) {
                this.gridHeightDefine();
            }
        });
        if (!sessionStorage.getItem("ProcessingeRxsRecords") || (sessionStorage.getItem("ProcessingeRxsRecords") && !JSON.parse(sessionStorage.getItem("ProcessingeRxsRecords"))))
            sessionStorage.removeItem("proccedeRxCount")
    }
    gridHeightDefine() {
        const element = document.getElementById("erxActionListGrid");
        if (element) {
            const height = window.innerHeight -
                element.getBoundingClientRect().top +
                window.scrollY - window.scrollY;
            let fitHeight;
            if (this.isPopErxModel) {
                fitHeight = height - 300;
            } else {
                fitHeight = (height > 400 && height < 650) ? height - 175 : (height >= 650 && height < 750) ? height - 235 : (height >= 750) ? fitHeight = height - 260 : height - 195;
            }
            if (this.isPopErxModel) {
                this._wijHeight = "40vh";
            } else {
                this._wijHeight = this.IsFromRxHistoryDisplay ? ((fitHeight>400 && fitHeight <600) ? fitHeight - 200 :  fitHeight- 500) + 'px' : "56vh";
            }
        }
    }
    createFG() {
        this.supplyDaysFG = this._fb.group({
            supplyDays: null,
            erxSig: null
        });
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("erxListingWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("erxListingWJ", storedWJ);
        const storedWJExtraInfo = await this._wijimoUtils.getWJSavedData("eRxExtraInfoListWJ");
        this.wjHeadersExtraInfo = this._wijimoUtils.patchDefHeader("eRxExtraInfoListWJ", storedWJExtraInfo);
        const storedWJExtraInfoInsurances = await this._wijimoUtils.getWJSavedData("insuaranceListeRxListwj");
        this.wjHeadersExtraInfoInsurance = this._wijimoUtils.patchDefHeader("insuaranceListeRxListwj", storedWJExtraInfoInsurances);

    }

    updateFilterData() {
        this.erxFilterData.PageNumber = this.pageNu;
        this.erxFilterData.PageSize = this.Size;
        this.getData(this.erxFilterData);
    }
    startProgress() {
        this.loading = true;
        this.progressValue = 0;
        const interval = setInterval(() => {
          if (this.patientResp) {
            this.progressValue = 100;
            clearInterval(interval);
          } else {
            this.progressValue += 25;
          }
        }, 100);
    }
    getData(data) {
        if (this.isPopErxModel) {
            data.isForErxLink = true;
        }
        if (!this.fromIntakeQueue) {
            if (document.getElementById('loader'))
                document.getElementById('loader').style.display = 'block';
            this.patientResp = false;
            this.startProgress();
            this.emitEnableDisableEvnt.emit(false);
            data.DefaultERxActionListSort = this._commonSvc.getSetttingValue("ERxSettings", "DEFAULT_ERX_ACTION_LIST_SORT");
            this._erxService.getErxActionList(data).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp) {
                    this.processSelectedRecords(true, true);
                    this._commonSvc.getSideNavCounts(this.dateParams);
                    this.eRxData = resp;
                    this.GetErxCount.emit(this.eRxData.TotalRecords);
                    this.generateErx(resp);
                }
            });
        } else {
            this.generateErx(this.rxInfo);
            if (this.fromIntakeQueue && !this.CurrentDetails) {
                this.processSelectedRecords();
                sessionStorage.setItem("fromIntakeQueue", "true");
            } else if (this.CurrentDetails) {
                this.currentDetails = this.CurrentDetails;
                this.closeSupplyDaysPop = this.modalService.open(
                    this.content2,
                    {
                        centered: true,
                        size: "lg",
                        backdrop: false,
                    }
                );
                this.getStatusDetails(this.CurrentDetails);
            }

        }
    }

    selectedItems(checkedVal,event) {
        if(!event) { // selected Item
            this.selectedItemList.push(checkedVal);
        } else {
            const index =  this.selectedItemList.filter((item: any) => { return item.row === checkedVal.row; });
            this.selectedItemList.splice(index,1);
        }
        if(this.selectedItemList?.length)
        {
            this.SelectedRecords.emit(true);
        } else {
            this.SelectedRecords.emit(false);
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
        this.value = false;
        this.gridHeightDefine();
    }

    setPage(page: number) {
        this.pageNu = page;
        this.erxFilterData.PageNumber = this.pageNu;
        this.getData(this.erxFilterData);
    }
    setSize(size: any) {
        this.Size = size;
        this.pageNu = this.paginationModel.pageNumber;
        this.erxFilterData.PageNumber = this.paginationModel.pageNumber;
        this.erxFilterData.PageSize = size;
        this.getData(this.erxFilterData);
    }

    generateErx(erx: any) {
        this.errorCount = 0;
        this.readyCount = 0;
        this.awaitingCount = 0;
        this.processedCount = 0;
        this.totalCount = erx && erx.TotalRecords ? erx.TotalRecords : 0;
        this.displayGrid = true;
        this.isDataExits = true;
        this.loaderDisplay();
        if (!this.totalCount && !this.fromIntakeQueue) {
            this.displayGrid = true;
            this.loaderDisplay();
            this.isDataExits = false;
            this.eRxListingWJ = new CollectionView(null)
            return;
        }
        this.pageCount = erx && erx.TotalRecords ? Math.ceil(erx.TotalRecords / this.Size) : 0;
        // this.formattedResult =
        if (erx && erx.Records) {
            this.maperxDataToObject(erx && erx.Records ? erx.Records : 0);
        }        // this.eRxListingWJ = new CollectionView(this.formattedResult);
        for (let p = 0; p < this.pageCount; p++) {
            this.pagenos[p] = p + 1;
            if (this.pagenos[this.pageCount]) {
                this.pagenos.splice(
                    this.pageCount,
                    this.pagenos.length - this.pageCount
                );
            }
        }
        this.ErrorCount.emit(this.errorCount);
        this.AwaitingCount.emit(this.awaitingCount);
        this.ReadyCount.emit(this.readyCount);
        this.ProcessedCount.emit(this.processedCount);
        if (this.closeIntakeModal) {
            this.CloseErxModal.emit(true);
        }
    }
    closeErxModal(type) {
        if (!type) {
            this.CloseErxModal.emit(true);
        }
    }

    closeErxMessageLinkModal() {
        this.CloseErxMessageLinkModal.emit("Cancel");
    }
    maperxDataToObject(data: any) {
        const mapDataSet = [];
        // let erxObjects =
        data.map((patient, i) => {
            const j = {};
            j["row"] = i;
            j["Message Type"] = patient.MessageType;
            j["Status"] = (patient.IsDeleted) ? "Removed" : patient.Status;
            j["DrugClass"]   = j["Drug Class"]  = patient.DrugClass;
            if (
                patient.Status === "Error In Transmission" ||
                patient.Status === "Denied"
            ) {
                this.errorCount = this.errorCount + 1;
            } else if (patient.Status === "Awaiting Response") {
                this.awaitingCount = this.awaitingCount + 1;
            } else if (patient.Status === "Ready To Process") {
                this.readyCount = this.readyCount + 1;
            } else if (patient.Status === "Already Processed") {
                this.processedCount = this.processedCount + 1;
            }
            j["Date & Time"] = moment.utc(patient.FileDtTm).local().format(
                "MM/DD/YY  hh:mm:ss"
            );
            j["Date Time"] = moment.utc(patient.FileDtTm).local().format(
                "MM/DD/YY  hh:mm:ss"
            );
            j["PatientId"] = patient.PatientId;
            j["PrescriberId"] = patient.PrescriberId;
            j["Patient Name"] = patient.PatientName;
            j["Patient Type"] = patient.PatType;
            j["PatientFound"] = patient.PatientCount;
            j["Prescriber Name"] = patient.PrescriberName;
            j["PrescriberFound"] = patient.PrescriberCount;
            j["Drug Name"] = patient.DrugName;
            j["Facility"] = patient.Facility;
            j["ErxFacility"] = patient.ErxFacility;
            j["eRxFacility"] = j["eRx Facility"] = patient.ErxFacility;
            j["DrugFound"] = patient.DrugCount;
            j["Processed"] = j["Processed?"] = patient.IsProcessed;
            j["Refill"] = j["Auth#"] = patient?.MessageType  && patient?.MessageType === "REFRES" && +(patient.RefillsValue) > 0 ?  (+(patient.RefillsValue) - 1 ) : 
            patient.RefillsValue;
            j["Last Filled"] = patient.dispensedHistory && patient.dispensedHistory.LastFilledDate ?
                moment.utc(patient.dispensedHistory.LastFilledDate).local().format("MM/DD/YY") : " ";
            this.PaToolTip = patient.PatientToolTip;
            this.prToolTip = patient.PrescriberToolTip;
            this.DrugToolTip = patient.ToolTip;
            j["RxNo"] = j["Rx#"] = patient.RxNo;
            j["EROPTransId"] = patient.EROPTransactionId;
            j["Printed"] = patient.IsPrinted ? "YES" : "NO";
            j["Mesg CatId"] = patient.MsgCatId;
            j["Speciality type"] = patient.SpecialityType;
            j["Spi Number"] = j["SPI#"] = patient.SpiNumber;
            //  j["Benefit check"] = patient.MsgCatId;
            j["Last Removed by"] = patient.LastRemovedBy;
            j["Nabp"] = patient.Nabp;
            j["ID"] = patient.Id ? patient.Id : patient.erxId;
            j["IsDeleted"] = patient.IsDeleted;
            j["PatSearchString"] = patient.PatSearchString;
            j["PrescSearchString"] = patient.PrescSearchString;
            j["DrugSearchString"] = patient.DrugSearchString;
            j["ErxOperationId"] = patient.ErxOperationId;
            // j["PatientMatch"] = patient.PatientCount;
            j["PrescriberMatch"] = patient.PrescriberCount;
            j["DrugMatch"] = patient.DrugCount;
            j["PatientPhone"] =  j["Patient Phone"] = patient.PatientPhone;
            j["PrescNotes"] =  j["Presc Notes"] = patient.PrescriberNotesFromErx ? patient.PrescriberNotesFromErx : null;
            j["PatientToolTip"] = !this.fromIntakeQueue && patient && patient.PatientToolTip ? patient.PatientToolTip.split("\u200B") : "";
            j["PrescriberToolTip"] = !this.fromIntakeQueue && patient && patient.PrescriberToolTip ? patient.PrescriberToolTip.split("\u200B") : "";
            j["ToolTip"] = patient.ToolTip.split("#");
            j["dispensedHistory"] = patient.dispensedHistory;
            j["messageId"] = j["message Id"] = patient.messageId;
            j["Version"] = patient.Version ? patient.Version : patient.VersionName;
            j["RefillResponseStatus"] =  j["Refill Response Status"]  = patient.RefillResponseStatus;
            j["RphNotes"] =  j["Rph Notes"] = patient.rphNotes;
            j["PrescrefillId"] = patient.PrescrefillId; 
            j["PatientDOB"] = patient.PatientDOB;
            j["IsSelected"] = this.fromIntakeQueue ? true : false;
            j["ErxRecordId"] = patient.ErxRecordId;
            j["DrugId"] = patient.DrugId;
            j["ERxOpStatusId"] = patient.ERxOpStatusId;
            j["frmErx"] = true;
            // return j;
            mapDataSet.push(j);
        });
        this.formattedResult = mapDataSet;
        this.eRxListingWJ = new CollectionView(mapDataSet,
            {
                sortComparer: (a: any, b: any) => {
                    return moment(a).isValid() && moment(b).isValid()
                        ? moment(a).diff(moment(b)) : null;
                }
            });
        return this.formattedResult;
    }

    deleteErx() {
        this._erxService
            .deleteErx(this.currentItem.EROPTransId, this.currentItem.ErxRecordId).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._commonSvc.getSideNavCounts(this.dateParams);
                    this._alertServ.success(MsgConfig.REMOVE_SUCCESS);
                    this.getData(this.erxFilterData);
                } else {
                    this._alertServ.success(MsgConfig.REMOVE_FAIL);
                }
            });
    }

    openPopUpModal(content, val: any) {
        if (!this._privMaskGuard.canActivate(AccCategoryE.ERxActionList, eRxActionListE.AllowRemovingErx)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else {
            this.currentItem = val;
            this.eRxRowData = val;
            if (this.currentItem["IsDeleted"]) {
                this.modalReference = this.modalService.open(this.RecallErxProcessing, {
                    centered: true, windowClass: "large--content"
                });
            } else {
                this.deleteErxList(content);
            }
        }

    }
    deleteErxList(content) {
        this.modalReference = this.modalService.open(content, {
            centered: true, backdrop: false, windowClass: "large--content"
        });
    }
    openPopUpModal1(content1, val: any) {
        this.currentDetails = val;
        this.getDetails(this.currentDetails);
          this.modalRef = this.modalService.open(content1, { centered: true, backdrop: false, keyboard: false });

    }

    openPopUpModal2(content2, val: any) {
        this.currentDetails = val;
        this.modalRef = this.modalService.open(content2, { size: "lg" });
    }

    getDetails(val: any, IseRxTRxREQ: boolean = false) {
        this._erxService.getAwaitErx(val.ID, val.Version, val.ErxOperationId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.awaitInfo = resp;
                this.IseRxTRxREQ = IseRxTRxREQ;
            }
        });
    }

    getStatusDetails(val: any) {
        this._erxService.getReadyErx(val.ID, val.Version).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.detailsInfo = resp;
                if (this.detailsInfo && this.detailsInfo.insuranceDetails) {
                    this.insuranceData = this._commonSvc.insuranceData$["source"]["value"];
                    this.GenerateInsuranceDetails(this.detailsInfo.insuranceDetails);
                    this.getRxPatientDetailsById();
                }
                if (this.detailsInfo && this.detailsInfo.RxOrdDt) {
                    this.rxOrdDt = moment.utc(this.detailsInfo.RxOrdDt).local().format("MM/DD/YYYY");
                }
                this.effectiveDate = this.detailsInfo.EffectiveDate;
                if (!this.effectiveDate || this.effectiveDate === "0001-01-01T00:00:00") {
                    this.effectiveDate = null;
                } else if (this.detailsInfo && this.detailsInfo.EffectiveDate) {
                    this.effectiveDate = moment.utc(this.detailsInfo.EffectiveDate).local().format("MM/DD/YYYY");
                }
                this.responseState = this.detailsInfo.PrsOrdRef;
                if (this.responseState.includes("EPCS")) {
                    this.responseState = val.Status + " " + "EPCS VERIFIED";
                } else {
                    this.responseState = val.Status + val.RefillResponseStatus;
                }
                this.detailsInfo.RefillNum = this.detailsInfo && this.detailsInfo?.MsgType === "RxRenewalResponse" && this.detailsInfo?.RefillNum != "0" ?  (+(this.detailsInfo?.RefillNum) - 1).toString() 
                : this.detailsInfo?.RefillNum;
            } else {
                this.detailsInfo = null;
            }
        });
    }

    clickOnWijimo(flex, e, noteslog) {
        const hti = flex.hitTest(e);
        const col = hti ? hti.col : 0;
        const row = hti ? hti.row : 0;
        if (flex && hti.panel === flex.cells && hti.panel.columns[col].binding === "Rph Notes") {
            const item = hti.panel.rows[row].dataItem;
            this.getErxInfo(item, noteslog);
        }
    }

    getErxInfo(val, noteslog) {
        if (!this._privMaskGuard.canActivate(AccCategoryE.ERxActionList, eRxActionListE.eRxRphNotes)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else {
            let modalRef = this.modalService.open(NotesLogComponent, { size: "lg" });
            modalRef.componentInstance.OpenAsModal = true;
            modalRef.componentInstance.NotesFr = "RPH";
            modalRef.componentInstance.ErxInfo = this.eRxData.Records[val.row];
            modalRef.componentInstance.CloseNotesValue.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                modalRef.close();
                this.getData(this.erxFilterData);
            });
            modalRef.componentInstance.CloseNotesValueWithList.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                modalRef.close();
                this.getData(this.erxFilterData);
            });
            
        }

        // this.modalRef = this.modalService.open(noteslog, { size: "lg" });
        // this.erxNotes = true;
        // const rowId = val.row;
        // this.erxRowInfo = this.eRxData.Records[rowId];
    }

    openPrescriberNotes(item, modalCont) {
        this.modalService.open(modalCont, { centered: true, keyboard: false, backdrop: "static" });
    }

    recallErxProcessingForregularFlow() {
        this._erxService.getProcessRecallERX({ "ErxOpTransactionId": this.eRxRowData["EROPTransId"] }).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if (res) {
                this.erxFilterData.PageNumber = this.pageNu;
                this.erxFilterData.PageSize = this.Size;
                this.getData(this.erxFilterData);
                this.selectedValue["IsDeleted"] = false;
                this.getProcessInfo(this.selectedValue, this.neglectProcessStatusPopup);
                if (this.modalRef) {
                    this.modalRef.close();
                }
            }
        });
    }
    recallErx() {
        if (this.eRxRowData["IsDeleted"]) {
            if (this.modalRef) {
                this.modalRef.close();
            }
            this.modalRef = this.modalService.open(this.RecallErxProcessing, {
                keyboard: false,
                backdrop: "static", windowClass: "medium-sm-modal large--content"
            });
        } else {
            this.getProcessInfo(this.selectedValue, true);
        }
    }
    getProcessInfo(val: any, neglectProcessStatusPopup: any, isFromBenefitCheck?) {
        if (!this._privMaskGuard.canActivate(AccCategoryE.ERxActionList, eRxActionListE.AllowProcessingErxPrescription)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else {
            if (this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
            this.isFromBefitCheck = isFromBenefitCheck ? isFromBenefitCheck : false;
            this.selectedValue = val;
            this.eRxRowData = val;
            this.neglectProcessStatusPopup = neglectProcessStatusPopup;
            this._rxStore.storeRxHistory(val);
            if (
                val["Message Type"] === "REFRES" ||
                val["Message Type"] === "NewRx" ||
                val["Message Type"] === "New Rx" ||
                val["Message Type"] === "RxChangeResponse" ||
                val["Message Type"] === "CancelRx"
            ) {
                if (val.Status === "Ready To Process" || val.Status === "Removed") {
                    this.eRxRowData = val;
                    this._erxService.getErxStatus(val.ErxOperationId).pipe(takeUntil(this.unsubscribe$)).subscribe(async statusResponse => {
                        if ((statusResponse.ErxStatus === 1) || neglectProcessStatusPopup === true) {
                            if (statusResponse.CancelERxData != null) {
                                const array = [];
                                array.push(statusResponse.CancelERxData);
                                this.cancelRxRequestData = this.maperxDataToObject(array) ? (this.maperxDataToObject(array))[0] : null;
                                this.modalRef = this.modalService.open(
                                    this.cancelRxExist,
                                    {
                                        centered: true, backdrop: false,
                                        windowClass: "large--content"
                                    }
                                );
                            } else {
                                // let response = null;
                                // if (val["Message Type"] === "REFRES" ||
                                // val["Message Type"] === "NewRx") {
                                //     response = await this._erxService.getErxChangeRespStatus(val.ErxOperationId).toPromise();
                                // }
                                if (statusResponse.RxChangeReponse) {
                                    this.changeRequestResp = statusResponse.RxChangeReponse;
                                    const array = [];
                                    array.push(statusResponse.RxChangeReponse);
                                    this.changeRespStatus = this.maperxDataToObject(array) ? (this.maperxDataToObject(array))[0] : null;
                                    this.modalRef = this.modalService.open(this.rxChangeRespTemp,
                                        { backdrop: false, windowClass: "large--content", centered: true });
                                } else if (this.eRxRowData["IsDeleted"]) {
                                    if (this.modalRef) {
                                        this.modalRef.close();
                                    }
                                    this.modalRef = this.modalService.open(this.RecallErxProcessing, {
                                        keyboard: false,
                                        backdrop: "static", windowClass: "medium-modal large--content"
                                    });
                                } else {
                                    this._erxService.getProcesErx(val.ID, val.Version).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                                        if (resp.DigitalSignatureError === "") {
                                            this.erxDetailsData = resp.ERxDetails;
                                            this.erxProcess.eRxId = val.ID;
                                            this.erxProcess.PatSearchString = val.PatSearchString;
                                            this.erxProcess.DrugSearchString = val.DrugSearchString;
                                            this.erxProcess.PrescSearchString =
                                                val.PrescSearchString;
                                            this.erxProcess.ErxOpTransactionId = val.EROPTransId;
                                            this.erxProcess.ErxOperationId = val.ErxOperationId;
                                            if (val.PatientPhone) {
                                                if (val.PatientPhone === "") {
                                                    this.erxProcess.PatientPhone = null;
                                                } else {
                                                    this.erxProcess.PatientPhone = val.PatientPhone;
                                                }
                                            }
                                            if (val["Message Type"] === "CancelRx") {
                                                this.StoreFilters.emit(null);
                                                this.selectedErxData = val;
                                                this.patPrecheck = false;
                                                this.patPrecheck = true;
                                                this.messageType = val;
                                            } else {
                                                this._erxService
                                                    .geteRxProcessDetails(this.erxProcess, val.Version).pipe(takeUntil(this.unsubscribe$))
                                                    .subscribe(response => {
                                                        if (response) {
                                                            this.erxProcessIds = response;
                                                            this.StoreFilters.emit(null);
                                                            this.selectedErxData = val;
                                                            this.patPrecheck = false;
                                                            this.patPrecheck = true;
                                                            this.messageType = val;
                                                        }
                                                    });
                                            }
                                        } else {
                                            this.processResponse = resp.DigitalSignatureError;
                                            this._erxService.updateErxProcessStatus(val.ErxOperationId, false).pipe(takeUntil(this.unsubscribe$)).subscribe(statusUpdateResponse => { });
                                            this.modalRef = this.modalService.open(
                                                this.processInfo,
                                                {
                                                    centered: true, backdrop: false,
                                                    windowClass: "large--content"
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        } else if (statusResponse.ErxStatus === 2) {
                            this.modalRef = this.modalService.open(
                                this.processStatus,
                                {
                                    centered: true, backdrop: false,
                                    windowClass: "large--content"
                                }
                            );
                        } else if (statusResponse.ErxStatus === 3) {
                            this.modalRef = this.modalService.open(
                                this.ErxAlreadyProcessed,
                                {
                                    centered: true, backdrop: false,
                                    windowClass: "large--content"
                                }
                            );
                            //this.getData(this.erxFilterData);
                        } else if (statusResponse.ErxStatus === 4) {
                            if (val["Message Type"] === "REFRES") {
                                this.errorMsg = "You can't process this Refill Response as corresponding refill request is cancelled.";
                            } else if (val["Message Type"] === "RxChangeResponse") {
                                this.errorMsg = "You can't process this Change Response as corresponding change request is cancelled.";
                            }
                            this.modalRef = this.modalService.open(this.CantProcessTemp,
                                { centered: true, backdrop: false, windowClass: "large--content" });
                        }
                    });
                } else {
                    if (this.fromIntakeQueue) {
                        this.CloseErxModal.emit(true);
                    }
                }
            }
        }
    }

    changeNotesPopup(val: any) {
        this.erxNotes = val;
    }

    // printDocument() {
    //     var data1 = document.getElementById("tab77");
    //     html2canvas(document.getElementById("tab77")).then(canvas => {
    //         // Few necessary setting options
    //         var imgWidth = canvas.width;
    //         var pageHeight = canvas.height;
    //         var imgHeight = canvas.height;
    //         var heightLeft = imgHeight;
    //         const contentDataURL = canvas.toDataURL("image/jpeg");
    //         this._printSer.print(contentDataURL);
    //     });
    // }

    async printDocument() {
        let ErxOperationId: any;
        if (this.eRxData && this.eRxData.Records && this.eRxData.Records.length) {
            this.eRxData.Records.find(resp => {
                if (resp && this.detailsInfo && (resp.Id == this.detailsInfo.ErxId)) {
                    ErxOperationId = resp.ErxOperationId;
                }
            })
        }
        if (this.detailsInfo) {
            let payload = [{
                eRxId: this.detailsInfo.ErxId ? this.detailsInfo.ErxId : 0,
                eRxOperationId: ErxOperationId ? ErxOperationId : 0
            }]
            const ErxResp = await this._erxService.getErxMultiReport(payload).toPromise();
            if (ErxResp && ErxResp.size > 0 && ErxResp.type === "application/pdf") {
                this.print(ErxResp);
            }
        }
    }

    print(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = async function () {
            const base64data = reader.result;
            await this._printSer.printPdf(base64data);
            this._commonStore._afterPrint$.subscribe((res) => {
                if (res) {
                    if (this.formattedResult && this.formattedResult.length) {
                        this.formattedResult.map((item: any) => {
                            item.IsSelected = false;
                            this._commonStore._afterPrint$.next(false)
                        })
                    }
                }
            })
        }.bind(this);
    }

    onRowDblclicked(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem) {
            this.selectedrxNo = s.selectedRows[0].dataItem.RxNo;
        }
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.RxEntry)) {
            this.routeToRx();
        } else {
            this.openOveride("RxEntry");
        }
    }


    routeToRx() {
        this._rxUtils.getRxDetailsByPrescNumber(this.selectedrxNo, "nr", null);
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
        }
    }

    openOveride(content) {
        this.privType = content;
        this.categoryId = "Rx";
        this.openOverride = true;
    }

    statusColorCodes(status) {
        if (status === "Error In Transmission" || status === "Denied" || status === "Error") {
            return { "warning-color-code": true, "waiting-color-code": false, "success-color-code": false }
        } else if (status === "AwaitingResponse" || status === "Awaiting Response") {
            return { "warning-color-code": false, "waiting-color-code": true, "success-color-code": false }
        } else if (status !== "Already Processed") {
            return { "warning-color-code": false, "waiting-color-code": false, "success-color-code": true }
        }
    }

    toggleColorLegend() {
        this._alertServ.showHelpText({ RxStatus: false, DrugName: true, Status: false, frmErx: true });
    }

    itemCheckColorCodes(check) {
        if (check > 1) {
            return { "color-red": false, "color-yellow": true, "color-green": false }
        } else if (check == 1) {
            return { "color-red": false, "color-yellow": false, "color-green": true }
        } else if (check == 0) {
            return { "color-red": true, "color-yellow": false, "color-green": false }
        }
    }

    focusToFirst(event) {
        if (document.getElementById("ErxiSearchBox")) {
            event.preventDefault();
            document.getElementById("ErxiSearchBox").focus();
        }
    }

    openActionModal(val, data?: any, type: string = "") {
        if (!this._privMaskGuard.canActivate(AccCategoryE.ERxActionList, eRxActionListE.AllowProcessingChangeRequest)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else {
            this.selectedBenefitCheckType = type;
            if (typeof val === "object") {
                this.selectedBenefitCheckType = val.selectedBenefitCheckType;
                val = val.activeModal;
            }
            if (data) {
                this._erxService.getErxChangeRespStatus(data.ErxOperationId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                    if (resp) {
                        this.changeRequestResp = resp; // FileDtTm
                        //this.changeRespStatus = data;
                        const array = [];
                        array.push(resp);
                        this.changeRespStatus = this.maperxDataToObject(array) ? (this.maperxDataToObject(array))[0] : null;
                        this.modalRef = this.modalService.open(this.rxChangeRespTemp, { backdrop: false, windowClass: "large--content", centered: true });
                    } else {
                        if (data) {
                            this.erxChangeData = {
                                erxId: data["ID"], patientId: data["PatientId"],
                                prescriberId: data["PrescriberId"], drugId: data["DrugId"], messageType: data["Message Type"]
                            };
                        }
                        this.activeModal = val;
                    }
                });
            } else {
                this.activeModal = val;
                if (this.erxChangeOpenType && this.selectedBenefitCheckType) {
                    this.isBenfitCheckShow = true;
                    this.activeModal = "BenfitCheck";
                }
            }
        }

    }
    openBenefitCheckModal(isFromBenefitCheck) {
        if (this.erxChangeOpenType && isFromBenefitCheck) {
            this.isBenfitCheckShow = true;
            this.activeModal = "BenfitCheck";
        } else if (isFromBenefitCheck !== null) {
            this.processSelectedRecords(true);
        }
    }

    openMoreInfo() {
        this.modalService.open(this.erxExtraInfo, { size: "lg", keyboard: false, backdrop: "static" });
    }

    openCompDrug() {
        this.modalService.open(this.compList, { size: "lg", keyboard: false, backdrop: "static" });
    }

    openDrugAddPopUp(value) {
        const modalRefDrug = this.modalService.open(DrugInformationComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-x-lg erx-to-add-modal",
            keyboard: false,
        });
        modalRefDrug.componentInstance.IsFromErx = true;
        modalRefDrug.componentInstance.openMMSPopup = value === "DrugMMS" ? true : false;
        modalRefDrug.componentInstance.IsAddNewDrugCompleted.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp === 1) {
                this._alertServ.error(
                    MsgConfig.PAT_PRE_DRUG_CHECK_DRUG_DUP_NDC
                );
            } else if (resp === 2) {
                this._alertServ.error(
                    MsgConfig.PAT_PRE_DRUG_CHECK_DRUG_DUP_QUICK
                );
            } else if (resp === -1) {
                modalRefDrug.close();
                this._alertServ.error(
                    MsgConfig.PAT_PRE_DRUG_CHECK_DRUG_CREATE_FAIL
                );
            } else {
                modalRefDrug.close();
                this._commonSvc.getRxDrugDetailsById(resp).pipe(takeUntil(this.unsubscribe$)).subscribe(drugDetails => {
                    if (drugDetails) {
                        this.drugAddFrmPopup = drugDetails;
                        if (document.getElementById("AuthRef")) {
                            document.getElementById("AuthRef").focus();
                        }
                    }
                });
            }
        });
    }

    GenerateInsuranceDetails(insuranceDetails) {
        this.insuranceWJ = new CollectionView(
            insuranceDetails.map((ins, index) => {
                const k = {};
                k["rowId"] = index;
                k["PAYER IDENTIFICATION"] = ins.PayerId ? ins.PayerId : "";
                k["Payer Identification"] = ins.PayerId ? ins.PayerId : "";
                k["PCN"] = ins.ProcessorIdentificationNumber ? ins.ProcessorIdentificationNumber : "";
                k["IIN/BIN"] = ins.BINLocationNumber ? ins.BINLocationNumber : "";
                k["BINLocationNumber"] = ins.BINLocationNumber ? ins.BINLocationNumber : "";
                k["ProcessorIdentificationNumber"] = ins.ProcessorIdentificationNumber ? ins.ProcessorIdentificationNumber : "";
                k["PAYER NAME"] = ins.PayerName ? ins.PayerName : "";
                k["IsSelected"] = false;
                k["Payer Name"] = ins.PayerName ? ins.PayerName : "";
                k["PAYER TYPE"] = ins.PayerType ? ins.PayerType : "";
                k["Payer Type"] = ins.PayerType ? ins.PayerType : "";
                k["CARD HOLDER ID"] = ins.CardHolderId ? ins.CardHolderId : "";
                k["Card Holder Id"] = ins.CardHolderId ? ins.CardHolderId : "";
                k["CARD HOLDER FIRSTNAME"] = ins.CardHolderFirstName ? ins.CardHolderFirstName : "";
                k["Card Holder First Name"] = ins.CardHolderFirstName ? ins.CardHolderFirstName : "";
                k["CARD HOLDER LASTNAME"] = ins.CardHolderLastName ? ins.CardHolderLastName : "";
                k["Card Holder Last Name"] = ins.CardHolderLastName ? ins.CardHolderLastName : "";
                k["CARD HOLDER SUFFIX"] = ins.CardHolderSuffix ? ins.CardHolderSuffix : "";
                k["Card Holder Suffix"] = ins.CardHolderSuffix ? ins.CardHolderSuffix : "";
                k["CARD HOLDER PREFIX"] = ins.CardHolderPrefix ? ins.CardHolderPrefix : "";
                k["Card Holder Prefix"] = ins.CardHolderPrefix ? ins.CardHolderPrefix : "";
                k["GROUP ID"] = ins.GroupId ? ins.GroupId : "";
                k["PBMMemberID"] = ins.PBMMemberID ? ins.PBMMemberID : "";
                k["Group Id"] = ins.GroupId ? ins.GroupId : "";
                k["GROUP NAME"] = ins.GroupName ? ins.GroupName : "";
                k["Group Name"] = ins.GroupName ? ins.GroupName : "";
                const aItem = this.insuranceData.filter((item) => {
                    return (((item['BinNum'] && ins['BINLocationNumber']) && item['BinNum'] == ins['BINLocationNumber']) &&
                        ((item['ProcessorNum'] || ins['ProcessorIdentificationNumber']) && item['ProcessorNum'] == ins['ProcessorIdentificationNumber']))
                });
                k["Ins. ID"] = aItem && aItem.length ? aItem[0]['InsuranceName'] : null;
                k["Ins. Code"] = aItem && aItem.length ? aItem[0]['InsurerCode'] : null;
                k['InsId'] = aItem && aItem.length ? aItem[0]['InsurerCode'] : null;
                k['InsuCarrierId'] = aItem && aItem.length ? aItem[0]['InsuCarrierId'] : null;
                if (this.patientInsuranceArray && this.patientInsuranceArray.length) {
                    const isInsu = this.patientInsuranceArray.find((item1: any) => { return k['InsuCarrierId'] == item1['insurerid'] });
                    k['isSameInsurance'] = isInsu ? true : false;
                }
                return k;
            })
        );
        this.activeHeaders = [
            "PAYER IDENTIFICATION",
            "PAYER NAME",
            "CARD HOLDER ID",
            "CARD HOLDER FIRSTNAME",
            "CARD HOLDER LASTNAME",
            "CARD HOLDER SUFFIX",
            "CARD HOLDER PREFIX",
            "GROUP ID"
        ];
        this.inactiveHeader = [
            "PCN",
            "IIN/BIN",
            "PAYER TYPE",
            "GROUP NAME"
        ];
    }

    routeToPatientHistory(item) {
        localStorage.setItem("toPatHisForUnbilledOrRefilll", this._commonSvc.encryptData("eRxActionList"));
        this._patPreDrugService._patPreDrugModal$.next("patHist");
        const modalRef = this._patPreDrugService.modalInstanceRef;
        modalRef.componentInstance.patientID = item ? item.PatientId : null;
        modalRef.componentInstance.popUpOpenFr = "rph";
        modalRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(closed => {
            modalRef.close();
            this._patPreDrugService._patPreDrugModal$.next(null);
        });
    }

    async sendFax() {
        let prescRefils = (this.formattedResult && this.formattedResult.length) ?
            this.formattedResult.filter(elm => elm.IsSelected && elm.PrescrefillId && (elm['Message Type'] === 'REFREQ') && (elm.Status === "Error In Transmission" || elm.Status === "Denied" || elm.Status === "Error")).map(elm => elm.PrescrefillId) : null;
        const selectedRxs = (this.formattedResult && this.formattedResult.length) ? this.formattedResult.filter(elm => elm.IsSelected) : [];
        if (prescRefils && prescRefils.length) {
            this.rxInfoArry = await this._commonSvc.getMultipleRxDetails({ Prescrefills: prescRefils }).toPromise();
            if (this.rxInfoArry && this.rxInfoArry.length > 0) {
                this.generatePrintRefWJ();
                this.activeModal = "faxPopUp";
            } else {
                this._alertServ.error("Please select only refill requests with error status.");
            }
        } else if (selectedRxs && selectedRxs.length) {
            this._alertServ.error("Please select only refill requests with error status.");
        } else {
            this._alertServ.error("Please select at least one Rx.");
        }
    }

    getMultiReport() {
        let newArr = [];
        if (this.formattedResult && this.formattedResult.length) {
            this.formattedResult.filter(elm => {
                if (elm && elm.IsSelected && elm.ID && elm.ErxOperationId) {
                    newArr.push({
                        eRxId: elm.ID,
                        eRxOperationId: elm.ErxOperationId
                    })
                }
            })
        }
        if (newArr && newArr.length) {
            this._erxService.getErxMultiReport(newArr).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp && resp.size > 0 && resp.type === "application/pdf") {
                    this.print(resp);
                    newArr = [];
                }
            });
        } else {
            this._alertServ.error("Please select at least one record to process.");
        }
    }
    getErxMessageLink() {
        if (this.formattedResult && this.formattedResult.length) {
            this.eRxRecord = this.formattedResult.filter(elm => elm.IsSelected && elm.ID);
        }
        if (this.eRxRecord && this.eRxRecord.length === 1) {
            this.eRxRowData = this.eRxRecord[0];
            const patName = this.patientDetails["lastname"].toLowerCase() +
                ", " + this.patientDetails["firstname"].toLowerCase();
            const pipe = new DatePipe("en-US");
            const patDOBFromErx = pipe.transform(this.eRxRowData["PatientDOB"], "MM/dd/yyyy");
            const patDOB = pipe.transform(this.patientDetails["dob"], "MM/dd/yyyy");
            if (
                (this.eRxRowData["Patient Name"].toLowerCase() === patName && patDOB === patDOBFromErx)
            ) {
                this.goToErxMessageLink();
            } else {
                this.modalRef = this.modalService.open(this.PatNotMatch,
                    { keyboard: false, backdrop: "static", windowClass: "medium-sm-modal-40 large--content", centered: true });
            }
        } else if (this.eRxRecord && this.eRxRecord.length >= 2) {
            this._alertServ.error("Please select Only one record to process.");
        } else {
            this._alertServ.error("Please select at least one record to process.");
        }
    }
    closeBenfitCheckModal(resp) {
        if (resp) {
            this.erxChangeOpenType = resp;
            this.isBenfitCheckShow = false;
            this._cdr.detectChanges();
            this.activeModal = null;
            if ((resp === "changeReq" || resp === "paReq") && this.selectedErx && this.selectedErx.length) {
                this.openActionModal("ERxChange", this.selectedErx[0], resp);
            } if (resp === "process" && this.selectedErx && this.selectedErx.length) {
                this.getProcessInfo(this.selectedErx[0], false, true);
            } else {
                this.getData(this.erxFilterData);
            }
        }
    }
    navigateToBenfitCheck(item?) {
        if (!this._privMaskGuard.canActivate(AccCategoryE.ERxActionList, eRxActionListE.AllowProcessingBenefitCheck)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else {
            if (item) {
                this.isBenefitCheckProcess = (item.Status === 'Already Processed') ? true : false;
                this.eRxIds = (this.formattedResult && this.formattedResult.length) ?
                    this.formattedResult.filter(elm => elm.ID === item["ID"]).map(elm => elm.ID) : null;
                this.selectedErx = (this.formattedResult && this.formattedResult.length) ?
                    this.formattedResult.filter(elm => elm.ID === item["ID"]).map(elm => elm) : null;
                const selectedErxObj = this.formattedResult.filter(elm => elm.ID === this.eRxIds).map(elm => elm);
                if (selectedErxObj) {
                    this._erxService.getReadyErx(this.eRxIds, this.eRxVersion).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                        if (resp) {
                            this.benefitCheckDetails = resp;
                            this.checkSupplyDaysExistOrNot(resp);
                        }
                    });
                }
            }
        }

    }
    checkSupplyDaysExistOrNot(resp) {
        if (!resp.RxDays) {
            this.supplyDaysFG.reset();
            this.supplyDaysFG.controls["erxSig"].patchValue(resp.RxSig);
            this.closeSupplyDaysPop = this.modalService.open(
                this.benfitCheckSupllyDays,
                {
                    centered: true,
                    keyboard: false,
                    size: "sm",
                    backdrop: false,
                    windowClass: "medium-sm-modal-30",
                }
            );
        } else {
            this.rxDays = resp["RxDays"];
            this.isBenfitCheckShow = true;
            this.activeModal = "BenfitCheck";
        }
    }
    closedSupplyDayaOpenedPops() {
        if (this.closeSupplyDaysPop) {
            this.closeSupplyDaysPop.close();
            this.closeSupplyDaysPop = null;
        }
    }
    openBenfitModal() {
        if (this.supplyDaysFG.valid && this.supplyDaysFG.value["supplyDays"] && this.supplyDaysFG.value["supplyDays"] <= 999) {
            this.closedSupplyDayaOpenedPops();
            this.rxDays = this.supplyDaysFG.value["supplyDays"];
            this.isBenfitCheckShow = true;
            this.activeModal = "BenfitCheck";
        } else {
            return;
        }
    }
    goToErxMessageLink() {
        if (this.eRxRowData["IsDeleted"]) {
            if (this.modalRef) {
                this.modalRef.close();
            }
            this.modalRef = this.modalService.open(this.RecallErxProcessing, {
                keyboard: false,
                backdrop: "static", windowClass: "medium-modal large--content"
            });
        } else {
            this.erxLinkProcess();
        }
    }
    recallErxProcessing() {
        this._erxService.getProcessRecallERX({ "ErxOpTransactionId": this.eRxRowData["EROPTransId"] }).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if (res) {
                this.erxLinkProcess();
            }
        });
    }
    erxLinkProcess() {
        if (this.rxType !== "er" && (this.rxFG.value["Drug"]["drugclass"] >= 2) && (this.rxFG.value["Drug"]["drugclass"] <= 5)) {
            this.pathSelectedErxValues();
            if (this.modalRef) {
                this.modalRef.close();
            }
            this.closeErxMessageLinkModal();
        } else {
            const erxObject = {
                "ErxId": this.eRxRowData["ID"],
                "ErxOpTransactionId": this.eRxRowData["EROPTransId"],
                "PatientId": this.patientDetails["patientid"],
                "PrescriberId": this.rxFG.value["Prescriber"]["prescriberid"],
                "DrugId": this.rxFG.value["Drug"]["id"], "RxNum": this.rxFG.value["Prescription"]["PrescNum"],
                "ErxOperationId": this.eRxRowData["ErxOperationId"]
            };
            this._erxService.getProcessERxMessageLink(erxObject, this.eRxVersion).pipe(takeUntil(this.unsubscribe$)).subscribe(async (res: any) => {
                if (res) {
                    if (this.rxType === "er") {
                        const resp = await this._rxService.SaveRxExtraValues(
                            this.RxExtraInfoObj["prescReFill"], this.RxExtraInfoObj['otherValFG']).toPromise();
                        if (resp) {
                            let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(+this.rxFG.value["Prescription"]["PrescNum"],
                                +this.rxFG.value["PrescReFill"]["Id"], false, null, null, null, true);
                            if (rxInfo) {
                                rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
                                this._nrxUtls.createPatchStoreRxFG(rxInfo);
                            }
                        }
                    }
                    this.pathSelectedErxValues();
                    if (this.modalRef) {
                        this.modalRef.close();
                    }
                    this.closeErxMessageLinkModal();
                }
            });
        }
    }
    pathSelectedErxValues() {
        this.rxFG.controls["erxDetailsInRx"].patchValue({
            erxId: this.eRxRowData["ID"],
            erxOpTransactionId: this.eRxRowData["EROPTransId"],
            erxOperationId: this.eRxRowData["ErxOperationId"]
        });
    }
    generatePrintRefWJ() {
        this.printRefWJ = new CollectionView(
            this.rxInfoArry.map((patRx, i) => {
                let k = {};
                k["RowId"] = i;
                k["PatientName"] =
                    patRx.Patient.lastname + ", " + patRx.Patient.firstname;
                k["PrescriberName"] =
                    patRx.Prescriber.prescriberlastname +
                    ", " +
                    patRx.Prescriber.prescriberfirstname;
                k["PrescPhone"] = (patRx.Prescriber.faxnumber && patRx.Prescriber.faxnumber.length === 10) ? "1" + patRx.Prescriber.faxnumber : patRx.Prescriber.faxnumber;
                k["RxNo"] = patRx.Prescription.PrescNum.toString();
                k["RxOrigin"] = this.getRxOrgNameById(
                    patRx.PrescReFill.RxOriginId
                );
                k["drugClass"] = patRx.Drug.drugclass;
                k["PrescriberId"] = patRx.Prescriber.prescriberid;
                k["FaxSubject"] = "Refill Request";
                k["Notes"] = "";
                k["DrugId"] = patRx.PrescReFill.DrugId;
                k["IsControlRx"] = patRx.Drug.drugclass >= 2 ? true : false;
                k["PrescRefillId"] = patRx.PrescReFill.Id
                return k;
            })
        );
    }

    getRxOrgNameById(id: number): string {
        let name = "";
        this.rxOriginList.forEach(element => {
            if (element.Id === id) {
                name = element.Name;
            }
        });
        return name;
    }

    closedFaxRequest() {
        this.activeModal = null;
        // this.getRefillData();
        this.updateFilterData();
    }
    checkAll(isChecked, item) {
        if (isChecked) {
            this.selectedInsuranceDetails.push(item);
        } else {
            const findIndex =
                this.selectedInsuranceDetails.findIndex((val: any) =>
                    (val["InsuCarrierId"] == item["InsuCarrierId"]));
            this.selectedInsuranceDetails.splice(findIndex, 1);
        }


    }
    openNewInsurancePopup() {
        if (this.selectedInsuranceDetails && !this.selectedInsuranceDetails.length) {
            this._alertServ.error("Please select at least one Record.");
        } else {
            const modalRef = this.modalService.open(NewBenefitInsuranceComponent, { windowClass: "max-modal-51" });
            modalRef.componentInstance.CurrentDetails = this.currentDetails;
            modalRef.componentInstance.selectedInsuranceDetails = this.selectedInsuranceDetails;
            modalRef.componentInstance.ClosePopup.pipe(takeUntil(this.unsubscribe$)).subscribe((close: any) => {
                if (modalRef) {
                    modalRef.close();
                }
                this.selectedInsuranceDetails = [];
                this.GenerateInsuranceDetails([]);
                if (this.detailsInfo && this.detailsInfo.insuranceDetails) {
                    this.GenerateInsuranceDetails(this.detailsInfo.insuranceDetails);
                }
            });
        }

    }
    async getRxPatientDetailsById() {
        if (this.currentDetails && this.currentDetails.PatientId) {
            const response = await this._commonSvc.getRxPatientDetailsById(this.currentDetails.PatientId).toPromise();
            if (response && response.insurance && response.insurance.length) {
                this.patientInsuranceArray = response.insurance;
                await this.patientInsuranceDetails(this.patientInsuranceArray);
                this.GenerateInsuranceDetails([]);
                if (this.detailsInfo && this.detailsInfo.insuranceDetails)
                    this.GenerateInsuranceDetails(this.detailsInfo.insuranceDetails);
            }
        }
    }
    patientInsuranceDetails(patientInsuranceDetails) {
        this.patientInsuranceWJ = new CollectionView(
            patientInsuranceDetails.map((ins, index) => {
                const k = {};
                k["rowId"] = index;
                k["IsPrimary"] = (ins.insuPriId && ins.insuPriId === 1) ? 'Y' : "N";
                k["C.H. First Name"] = ins.cardholderfirstname ? ins.cardholderfirstname : "";
                k["C.H. Last Name"] = ins.chlastname ? ins.chlastname : "";
                k["Group#"] = ins.groupname ? ins.groupname : "";
                k["BIN"] = ins.binnum ? ins.binnum : "";
                k["PCN"] = ins.ProcessorNum ? ins.ProcessorNum : "";
                k["C.H. DOB"] = ins.binnum ? ins.binnum : "";
                k["Ins. ID"] = ins.policynum ? ins.policynum : "";
                k["Ins. Code"] = ins.InsurerCode ? ins.InsurerCode : "";
                k["Relation"] = ins.relation ? ins.relation : "";
                const benfitCoordinations: any = this.insuranceWJ['_pgView']
                if (benfitCoordinations && benfitCoordinations.length) {
                    const isInsu = benfitCoordinations.find((item: any) => { return item['InsuCarrierId'] == ins['insurerid'] });
                    k['isSameInsurance'] = isInsu ? true : false;
                }
                return k;
            })
        );
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && row.dataItem["isSameInsurance"]) {
                cell.style.backgroundColor = "lightgreen";
                cell.style.color = "#000";
            } else {
                cell.style.backgroundColor = null;
            }
             if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors =  this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                cell.style.background = colors.bg;
                cell.style.color = colors.color;
                } else {
                cell.style.background = "#FFF";
                cell.style.color = "#000";
                }
                } 
        } 
    }

    additionalInsurances(IsPrimaryInsu) {
        if (this.selectedInsuranceDetails && this.selectedInsuranceDetails.length &&
            this.selectedInsuranceDetails.length > 1) {
            this._alertServ.error("Please only one record.");
        } else if (this.selectedInsuranceDetails && this.selectedInsuranceDetails.length) {
            const modalRef1 = this.modalService.open(AdditionalInsurancesCommonComponent,
                {
                    size: "lg", centered: true, backdrop: false, keyboard: false,
                    windowClass: "erx_extra_infoPopup"
                }
            );
            modalRef1.componentInstance.patientId = this.currentDetails.PatientId;
            modalRef1.componentInstance.selectedRow = this.selectedInsuranceDetails[0];
            modalRef1.componentInstance.IsPrimaryInsu = IsPrimaryInsu;
            modalRef1.componentInstance.selectedInsuranceDetails = this.selectedInsuranceDetails;
            modalRef1.componentInstance.closePopUp.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
                if (!res && modalRef1) {
                    modalRef1.close();
                } else if (res && modalRef1) {
                    modalRef1.close();
                }
                this.selectedInsuranceDetails = [];

                this.getRxPatientDetailsById();
            });
        } else {
            this._alertServ.error("Please select at least one Record.");
        }
    }
    updatePatientInfo() {
        if (false) {

        } else
            this._alertServ.error("Please select at least one Record.");
    }
    processSelectedRecords(isFromAlreadyProceed: boolean = false, stopListcall: boolean = false) {
        var selectedRecords = (isFromAlreadyProceed && sessionStorage.getItem("ProcessingeRxsRecords")) ?
            JSON.parse(sessionStorage.getItem("ProcessingeRxsRecords")) : this.getSelectedRecords();
        //Added Indexes to Selected eRx's
        if (this.fromIntakeQueue) {
            this.getSelectedRecords();
        }
        if (!(isFromAlreadyProceed && sessionStorage.getItem("ProcessingeRxsRecords")) && selectedRecords) {
            sessionStorage.setItem("totalSelectedeRxCount", JSON.stringify(selectedRecords));
            if (selectedRecords && selectedRecords.length) {
                selectedRecords.map((item, index) => {
                    item["indexItem"] = index + 1;
                });
            }
        }
        if (selectedRecords && selectedRecords.length) {
            if (selectedRecords.length > 1)
                sessionStorage.setItem("IsBatchProcess", "true");
            const item = selectedRecords[0];
            if (!this.isPopErxModel && this.checkStatusBeforeProcess(item)) {
                const item = selectedRecords.shift();
                this.getProcessInfo(item, false);
                sessionStorage.setItem("ProcessingeRxsRecords", (selectedRecords && selectedRecords.length) ?
                    JSON.stringify(selectedRecords) : null);
                sessionStorage.setItem("proccedeRxCount", item['indexItem'] ? item['indexItem'] : 0);
                if (!selectedRecords?.length)
                    sessionStorage.removeItem("IsBatchProcess");
            }
        } else if (!isFromAlreadyProceed) {
            this._alertServ.error("Please select at least one Record to Process.");
        } else if (!stopListcall) {
            this.generateErx(this.fromIntakeQueue ? this.rxInfo : this.eRxData);
        }
    }
    checkStatusBeforeProcess(item) {
        return (
            (item.ERxOpStatusId === ErxStatusAndMessageTypeEnums.ReadyToProcess ||
                (item.IsDeleted + "") === ErxStatusAndMessageTypeEnums.Removed) &&
            (item['Mesg CatId'] === ErxStatusAndMessageTypeEnums.NewRx || item['Mesg CatId'] === ErxStatusAndMessageTypeEnums.RxChangeResponse)
        ) ||
            (
                (item.ERxOpStatusId === ErxStatusAndMessageTypeEnums.ReadyToProcess || (item.IsDeleted + "") === ErxStatusAndMessageTypeEnums.Removed) &&
                (item['Mesg CatId'] === ErxStatusAndMessageTypeEnums.REFRES || item['Mesg CatId'] === ErxStatusAndMessageTypeEnums.RxChangeResponse)
            ) ||
            (
                (item.ERxOpStatusId === ErxStatusAndMessageTypeEnums.ReadyToProcess || (item.IsDeleted + "") === ErxStatusAndMessageTypeEnums.Removed) &&
                (item['Mesg CatId'] === ErxStatusAndMessageTypeEnums.CancelRx || item['Mesg CatId'] === ErxStatusAndMessageTypeEnums.RxChangeResponse)
            );
    }
    getSelectedRecords() {
        if (this.formattedResult && this.formattedResult.length) {
            return this.formattedResult.filter((elm, index) => {
                return (elm && elm.IsSelected && elm.ID && elm.ErxOperationId);
            });
        } else
            return null;
    }
    closeDigitalSignatureError(){
        if (this.modalRef) {
            this.modalRef.close();
        }
        if (this.fromIntakeQueue) {
            this.CloseErxModal.emit(true);
        }
    }
    loaderDisplay() {
        this.patientResp = true;
        if (this.patientResp) {
            setTimeout(()=>{
                this.emitEnableDisableEvnt.emit(true);
                this.loading = false;
                if (document.getElementById('loader'))
                    document.getElementById('loader').style.display = 'none';
            }, 200)
        }
    }
    resendTransferInReq(item) {
        this._erxService.reSendTransferInReq(item.ID,item.ErxOperationId).subscribe((resp: any) => {
            if(resp?.Result) {
                this.getData(this.erxFilterData);
              this._alertServ.success(MsgConfig.Transfer_In_Req);
            } else  if(resp?.Message && !resp?.Result) {
              this._alertServ.error(resp.Message);
            }
        });
    }
    openTransferInfor(item) {
        this.currentDetails = item;
        this.getDetails(item, true); // To get the Details for Awaiting response.
    }
    openTransferInReq(transferInReq) {
        this.IseRxTRxREQ = true;
    }
    closeTRxREQPopUpModal(records) {
        this.IseRxTRxREQ = false;
        this.selectedItemList = [];
        if(records?.length)
        {
            this.selectedItemList = records;
            this._cdr.detectChanges();
            this.IseRxTRxREQ = true;
        } else {
            this.generateErx(this.eRxData);
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
