
import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener,
    OnDestroy,
    ChangeDetectorRef
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxService, AlertService, RphDashboardService, DrugPickVerificationService, RphVerificationService, CommonService, UserService, ControlFileService, InsuranceService } from "../../../services";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import * as moment from "moment";
import { RxModel } from "src/app/models";
import { MsgConfig } from "src/app/app.messages";
import { AuditLogUtils, NRxSaveUtils, NRxUtils, ValidateFormFieldsUtils } from "src/app/utils";
import { RxStore } from "src/app/store";
import { RecallDeleteRxService } from "src/app/services/recall-delete-rx.service";
import { ControlRxSubmissionType } from "src/app/models/submission.enum";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { StaticVaribles } from "src/app/utils/static-varaibles.utils";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
@Component({
    selector: "app-file",
    templateUrl: "./file.component.html",
    styles: []
})
export class FileComponent implements OnInit, OnDestroy {
    modelRef: any;
    fileFG: FormGroup;
    activeModal = "";
    rxType: string;
    isFiledNew: boolean;
    minDate: any;
    similarFiledRx: any;
    modalRefSM: any;
    deferredReasonList = [];
    noneReason = [
        {
            Id: 0,
            Name: "None",
            TenantId: null,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null
        }
    ];

    fileToFillIsControlSub = false;
    @Input() FiledRx: any;
    @Input() rxFG: FormGroup;
    @Input() rxInfo: RxModel;
    @Input() openUpdate: boolean;


    @ViewChild("UnbilledRxError", { static: true })
    UnbilledRxError: any;

    @ViewChild("FILECONFIRMATION", { static: true })
    FILECONFIRMATION: any;

    @ViewChild("warnMsg", { static: true })
    warnMsg: any;

    @ViewChild("FILE", { static: true })
    FILE: any;

    @ViewChild("FILEDETAIL", { static: true })
    FILEDETAIL: any;

    @ViewChild("ResubmitConfirm", { static: true })
    ResubmitConfirm: any;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();
    @Output()
    IsPopUpClosedAfterrxExpired = new EventEmitter<any>();

    @Output()
    SaveFileInfo = new EventEmitter<any>();
    ordFillDaysStng: any;
    fillDateErrorMsg: string;
    isVerified = false;

    @Input()
    ActnType:any;
    rxExpired: any;
    expiredClassArray: any =[];
    formGrpInvld: boolean;
    unsubscribe$ : Subject<void> = new Subject();


    get RxFile(): FormGroup {
        return this.rxFG.get("RxFile") as FormGroup;
    }

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            if (this.activeModal === "file" || this.activeModal === "confirm") {
                event.preventDefault();
                this.closeModal();
                this.activeModal = null;
            }
        }
    }

    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _rxService: RxService,
        private _DfrdReasonServ: RphDashboardService,
        private _alrtServ: AlertService,
        private _drugPickVerf: DrugPickVerificationService,
        private _rphVerf: RphVerificationService,
        private _commonSvc: CommonService,
        private _saveRxUtls: NRxSaveUtils,
        private _userService: UserService,
        private _rxStore: RxStore,
        private _recallSer: RecallDeleteRxService,
        private _nrxUtils: NRxUtils,
        private _contrlSer:ControlFileService,
        private _insServ: InsuranceService,
        private _auditUtils: AuditLogUtils,
        private _colorCodeRx: ColorCodeRx,
        private _cdr: ChangeDetectorRef,
        private staticVaribles:StaticVaribles,
        private _rxServ: RxService, private _dateChecks: DateCheckUtil, private _formUtils: ValidateFormFieldsUtils
    ) {
        this.createFileFG();
        config.backdrop = "static";

        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
    }

    get dueDateTime(): FormControl {
        return this.rxFG.get("dueDateTime") as FormControl;
    }

    get description(): FormControl {
        return this.rxFG.get("description") as FormControl;
    }

    createFileFG() {
        this.fileFG = this._fb.group({
            dueDateTime: [],
            description: [],
            parentPage: [],
            prescId: [],
            prescRefillId: [],
            DeferredReason: null,
            DeferredReasonName: null,
        });

        this.fileFG.controls["dueDateTime"].setValue(moment(new Date()).format("MM/DD/YYYY") );
    }

    ngOnInit() {
        this.rxType = this.rxInfo["rxType"];
        if(this.rxInfo)
            this.rxExpired = this._colorCodeRx.showRxColorCodeAndHoverOver(this.rxInfo, "RxObject", "Rx","hoverOver","withHoverBack");
            this.expiredClassArray = this.staticVaribles.getRxExpiredClasses();
        if (this.openUpdate) {
            this.getDeferredReason(); 
        } else {
            if (this.rxType === "er" && (+this.rxInfo.PrescReFill.StatusId === 2 ||
                +this.rxInfo.Prescription.RxStatusId === 2 || this.rxInfo.RxBill.StatusId === 6)) {
                this.confirmToFile();
                this.checkMinMaxOrderDate();
            } else {
                this.checkRxStatus();
            }
        }
    }
    getDeferredReason() {
        const dfrdReasonFG = { SearchText: null, PageNumber: null, PageSize: null };
        this.getDeferredReasonList(dfrdReasonFG);
    }
    async getDeferredReasonList(dfrdReasonFG) {
        const resp: any = await this._DfrdReasonServ
        .getDeferredReasonList(dfrdReasonFG).toPromise();
        if (resp && resp.length > 0) {
            if (resp[0].TotalCount > 0) {
                dfrdReasonFG.PageSize = resp[0].TotalCount;
                    this.deferredReasonList = resp;
                    this.deferredReasonList.splice(0, 0, this.noneReason[0]);
            }
        } else {
            this.deferredReasonList = this.noneReason;
        }

        if (this.openUpdate) {
            if(this.ActnType !== 'FileUpdate') {
                if (!this.rxFG.value["RxFile"]["DeferredReason"] && this.rxFG.value["RxFile"]["DeferredReasonName"]) {
                    this.rxFG.controls["RxFile"].patchValue({DeferredReason: 0});
                }
                if (this.fileFG && this.fileFG.value.dueDateTime) {
                    const dueDtTm = new Date(this.fileFG.value.dueDateTime);
                    const dueDtTmFrmt = `${dueDtTm.getHours()} : ${dueDtTm.getMinutes()} : ${dueDtTm.getMilliseconds()}`;
                    this.rxFG.controls["RxFile"].patchValue({DueDtTm:(dueDtTmFrmt && dueDtTmFrmt !== "0 : 0 : 0" ? 
                    moment.utc(this.fileFG.value.dueDateTime).local().format("MM/DD/YYYY") : moment.utc(this.fileFG.value.dueDateTime).format("MM/DD/YYYY"))});
                }
            } else{
                if (!this.rxFG.value["RxFile"]["DeferredReason"] || !this.rxFG.value["RxFile"]["DeferredReasonName"]) {
                    this.rxFG.controls["RxFile"].patchValue({DeferredReason: 0});
                }
            }
            this.modelRef = this._modalService.open(this.FILEDETAIL, {centered: true, backdrop: false});
        }
    }

    checkRxStatus() {
        const paidRecords = (this.rxInfo && this.rxInfo.RxPay) ? this.rxInfo.RxPay.filter(v => v.RecType === "P") : null;
        if (this.rxInfo && paidRecords &&
            !(+this.rxInfo.PrescReFill.StatusId === 2 ||
                +this.rxInfo.Prescription.RxStatusId === 2 || +this.rxInfo.RxBill.StatusId === 6) &&
            (this.rxInfo.RxBill.StatusId === 2 || paidRecords.length > 0)) {
            this.modalRefSM = this._modalService.open(this.UnbilledRxError, {centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "large--content"
            });
        } else {
            setTimeout(() => {
                this.modelRef = this._modalService.open(this.FILECONFIRMATION, {centered: true,
                    keyboard: false, backdrop: false, windowClass: "large--content"});
                this.activeModal = "confirm";
            }, 0);
        }
    }


    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    confirmToFile() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.rxFG.value["rxType"] === "er" &&
        !(+this.rxInfo.PrescReFill.StatusId === 2 || +this.rxInfo.Prescription.RxStatusId === 2 || +this.rxInfo.RxBill.StatusId === 6) &&
         ((this.rxFG.value["rph2VerificationStatus"] && (this.rxFG.value["rph2VerificationStatus"]).trim().toLowerCase() === "v")
         || (this.rxFG.value["rph1VerificationStatus"] && (this.rxFG.value["rph1VerificationStatus"]).trim().toLowerCase() === "r"))) {
            this.isVerified = true;
        } else {
            this.confirmDEASubmission();
        }
    }

    async confirmDEASubmission() {
        this.isVerified = false;
        if (
            (
                (this.rxFG.value.Drug.drugclass > 1 && this.rxFG.value.Drug.drugclass < 6) ||
                (await this._recallSer.CheckIOCDrugorNot(this.rxFG.value.Drug.ndc, this.rxInfo.Patient.state).toPromise())
            )
            && this.rxFG.value.PrescReFill.IsControlSubmitted &&
            !(+this.rxInfo.PrescReFill.StatusId === 2 || +this.rxInfo.Prescription.RxStatusId === 2 || +this.rxInfo.RxBill.StatusId === 6)
        ) {
            this._modalService.open(this.ResubmitConfirm, {centered: true, keyboard: false, backdrop: "static", windowClass: "large--content" });
        } else {
            this.afterConfirmToFile();
        }
    }

    cancelResubmission() {
        this.afterConfirmToFile();
    }

    submitResubmission() {
        let rxInfo = this.rxFG.value;
        this._contrlSer.addPrescToControlResubList({ prescRefillIds: [rxInfo.PrescReFill.Id], isforFiledRx: true }).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.afterConfirmToFile();
        });
    }

    async afterConfirmToFile() {
        const today2 = moment().format("MM/DD/YYYY");
        const efctiveDate = (this.rxFG && this.rxFG.value && this.rxFG.value.eRxDetailsViewModel && this.rxFG.value.eRxDetailsViewModel.EffectiveDate)  ? (moment.utc(this.rxFG.value.eRxDetailsViewModel.EffectiveDate).local().format("MM/DD/YYYY")) : today2;
        let today = new Date(moment().format("MM/DD/YYYY"));
        let efctiveDate1 = new Date(moment(this.rxFG.value.eRxDetailsViewModel.EffectiveDate).format("MM/DD/YYYY"));
        this.isFiledNew = false;
        if (+this.rxFG.value.Prescription.RxStatusId !== 2) {
            this._rxService.checkSimilarRxs(this.rxFG.value.Patient.patientid,
                this.rxFG.value.Prescriber.prescriberid, this.rxFG.value.Drug.id).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this.similarFiledRx = resp;
                    const  data = this.rxFG.value;
                    if (data && data.PrescReFill && ["d", "D", "d ", "D "].indexOf(data.PrescReFill.OrderStatus) > -1) {
                        this._modalService.open(this.warnMsg, {backdrop: false, centered: true, windowClass: "large--content"});
                    } else {
                        if(this.rxFG.value.routeFrom == "/eprx/eRX/eRxActionList"){
                        this.fileFG.controls.dueDateTime.patchValue(efctiveDate)
                        }
            
                        if(this.rxExpired && this.rxExpired ?.isDeferredRxExpired) {
                                this.warnMessage(this.rxExpired.hoverOverDisplay);;
                        } else {
                            this.modelRef = this._modalService.open(this.FILE, { keyboard: false, backdrop: false, centered: true });
                           
                        }
                        this.getDeferredReason();
                        this.activeModal = "file";
                    }

                });
        } else {
            if ((this.rxFG.value.Drug.drugclass > 1 && this.rxFG.value.Drug.drugclass < 6) ||
                    (await this._recallSer.CheckIOCDrugorNot(this.rxFG.value.Drug.ndc, this.rxInfo.Patient.state).toPromise())) {
                if (this.rxFG.value.PrescReFill.SubmissionStatusId === ControlRxSubmissionType.Void) {
                    this.fileToFillIsControlSub = true;
                    this.createNewFiledRx(true);
                } else {
                    this.isFiledNew = this.checkCanFileNew();
                }
            } else {
                this.isFiledNew = this.checkCanFileNew();
            }
            const  data = this.rxFG.value;
            if (data && data.PrescReFill && ["d", "D", "d ", "D "].indexOf(data.PrescReFill.OrderStatus) > -1) {
                this._modalService.open(this.warnMsg, {backdrop: false, centered: true, windowClass: "large--content"});
            } else {
                if(this.rxFG.value.eRxDetailsViewModel && this.rxFG.value.eRxDetailsViewModel.EffectiveDate && this.rxFG.value && (efctiveDate1.getTime() > today.getTime()) && (this.ActnType == "FileTo")){
                    const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                        backdrop: false,
                        keyboard: false, windowClass: "large--content"
                        });
                        modelRef.componentInstance.warnMsg = "The prescriber has defined a future effective date" + " " +(efctiveDate)+" "+"for this prescription and should be deferred and processed on that day.";
                        modelRef.componentInstance.IsHeaderText = "Warning!!";
                        modelRef.componentInstance.Defer = false;
                        modelRef.componentInstance.IgnoreAndContinue = true;
                        modelRef.componentInstance.CancelButton = true;
                        modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
                            if(response && response == "ignoreAndContinue"){
                                if(this.rxExpired && this.rxExpired ?.getBackgroundForClass?.classForBackground && 
                                    this.expiredClassArray.includes(this.rxExpired ?.getBackgroundForClass?.classForBackground)) {
                                        this.warnMessage(this.rxExpired.hoverOverDisplay);;
                                } else {
                                    this.getDeferredReason();
                                    this.modelRef = this._modalService.open(this.FILE, {centered: true, keyboard: false, backdrop: false });
                                }
                            }else{
                                modelRef.close();
                            }
                            modelRef.close();
                        });
            } else{
                if(this.rxExpired && this.rxExpired ?.getBackgroundForClass?.classForBackground && 
                    this.expiredClassArray.includes(this.rxExpired ?.getBackgroundForClass?.classForBackground)) {
                    this.warnMessage(this.rxExpired.hoverOverDisplay);
                } else {
                    this.modelRef = this._modalService.open(this.FILE, {centered: true, keyboard: false, backdrop: false });
                    this.activeModal = null;
                    this._cdr.detectChanges();
                    this.activeModal = "file";
                }
            }
        }
        }
    }
    checkCanFileNew() {
        let result  = false;
        if (this.rxFG.value.PrescReFill.ReFillNum === 0 && this.rxFG.value.PrescReFill.PartialFillNo === 1) {
            result = this._commonSvc.getSetttingValue("RxSettings", "ASSIGN_RXNO_FILED") === "1" ? true : false;
        }
        return result;
    }

    createNewFiledRx(val) {
        this.isFiledNew = val;
    }

    closeModal(isFromClose: boolean = false) {
        // If We Give invalidate and  close it should be prevoius date only.
        const oldnfo = this._rxStore.rxInfo$["source"]["value"];
        if(isFromClose)
            this.rxFG.controls["RxFile"].patchValue({DueDtTm:moment.utc(oldnfo?.RxFile?.DueDtTm).format(MsgConfig.DATE_FORMAT)});
        if (this.modelRef) {
        this.modelRef.close();
        this.modelRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }
    warnMessage(message) {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg = message;
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if(modelRef)
                 modelRef.close();
        });
    }
    async updateFileDetails() {
        await this._formUtils.blurAndDelay();
        const dateChecks = [{
            control: "DueDtTm",
            minDate: "",
            maxDate: "",
            isRequired: true
        }];
        const DueDatesFG = this._fb.group({
            DueDtTm: this.rxFG.controls["DueDtTm"].value,
        }); 
        let isDateValidationChecks: boolean = this._dateChecks.checkDateValidationAtTimeofSave(DueDatesFG, dateChecks);
        if (isDateValidationChecks) {
                this.formGrpInvld = true;
                return;
            } else {
                if (this.rxFG.valid) 
                {
                    this.formGrpInvld = false;
                    if (this.modelRef) {
                        this.modelRef.close();
                    }
                    const rxData = this._saveRxUtls.setValuesBfrSave(this.rxFG, this.rxType, null, null);
                    const resp = await this._saveRxUtls.updateRxInfo(rxData);
                    if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
                        this.rxFG.controls["PrescReFill"].patchValue({"ModifiedDtTm": new Date()});
                        this._alrtServ.success("Defer Rx details saved successfully.");
                        this._rxStore.storeRxInfo(rxData);
                        this.closeModal();
                    } else if (resp && isNaN(+resp.Message)) {
                        if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                            this._alrtServ.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
                        } else if (typeof resp.Message === "string") {
                            this._alrtServ.error("Unable to update Rx. Missing " + resp.Message + " fields.");
                        }
                    } else {
                        this._alrtServ.error("Unable to update file Rx details.");
                    }
                } else {
                    this.formGrpInvld = true;
                }
            } 
    }

    async saveFileToInfo() {
        await this._formUtils.blurAndDelay();
        const dateChecks = [{
            control: "dueDateTime",
            minDate: "",
            maxDate: "",
            isRequired: true
        }];
        const DueDatesFG = this._fb.group({
            dueDateTime: this.fileFG.controls["dueDateTime"].value,
        }); 
        let isDateValidationChecks: boolean = this._dateChecks.checkDateValidationAtTimeofSave(DueDatesFG, dateChecks);
        if (isDateValidationChecks) {
            this.formGrpInvld = true;
            return;
        } else {
        if (this.fileFG.valid) {
            this.formGrpInvld = false;
            if (this.modelRef) {
                this.modelRef.close();
            }
            const rxInfo = this.rxFG.getRawValue();
            const prescription: FormGroup = this.rxFG.controls.Prescription as FormGroup;
            const prescRefill: FormGroup = this.rxFG.controls.PrescReFill as FormGroup;
            const rxBill: FormGroup = this.rxFG.controls.RxBill as FormGroup;
            const rxFile: FormGroup = this.rxFG.controls.RxFile as FormGroup;
            if(!(this.rxFG.value["rxFileType"] == 'NewFile'  &&
            this.rxFG.value["rxType"] == 'nrf')) {
                this.rxFG.controls["isDetOldRx"].setValue(null);
                 this.rxFG.controls["isDetOldRxRf"].setValue(null);
                  this.rxFG.controls["isDetOldRxRfFill"].setValue(null);
            }
            this.rxFG.controls["IsFromFile"].setValue(
                (this.rxFG.value["rxFileType"] == 'NewFile' && this.rxFG.value["rxType"] == 'nrf') ? true : false);
            if(this.rxFG?.value?.PrescReFill?.StatusId != 2
                && this.rxFG?.value?.Prescription?.RxStatusId != 2 && this.rxFG?.value?.RxBill?.StatusId != 6)
           {
            rxFile.controls.DueDtTm.patchValue(this.fileFG.value.dueDateTime);
                   rxFile.controls.DeferredReason.patchValue(this.fileFG.value.DeferredReason);
            rxFile.controls.DeferredReasonName.patchValue(this.fileFG.value.DeferredReasonName);
           }
            rxFile.controls.FileDtTm.patchValue(moment().format("MM/DD/YYYY"));
            if (this.rxFG.value.PrescReFill.StatusId === 2 || this.rxFG.value.Prescription.RxStatusId === 2
                || this.rxFG.value.RxBill.StatusId === 6) {
                    prescRefill.controls["FillDtTm"].setValue(this.fileFG.value["dueDateTime"] ?
                    this.fileFG.value["dueDateTime"] : prescRefill.value["FillDtTm"]);
                    prescRefill.controls["FillDtTmCopy"].setValue(prescRefill.value["FillDtTm"]);
                prescRefill.controls.RegPharmacist.patchValue(this._userService.getToken("PhUserName"));
                const resp = await this._rxServ.checkIsValidPharmacist(this._userService.getToken("PhUserName")).toPromise();
                const phUserId = this._userService.getToken("PharmacistId");
                prescRefill.controls['PharmacistId'].setValue( resp ? resp : phUserId);
                if (this._userService.getToken("TechUserName")) {
                    prescRefill.controls.TechInitials.patchValue(this._userService.getToken("TechUserName"));
                }
            }
            this.fileFG.controls["prescId"].setValue(rxInfo.Prescription.Id);
            this.fileFG.controls["prescRefillId"].setValue(rxInfo.PrescReFill.Id);
    
            if ((+prescRefill.value.StatusId === 2 || +prescription.value.RxStatusId === 2 ||
                rxBill.value.StatusId === 6) && !this.isFiledNew) {
               // prescription.controls.RxStatusId.setValue(1);
                // prescRefill.controls.StatusId.setValue(1);
                // if (rxBill.value.StatusId && +rxBill.value.StatusId === 6) {
                //     rxBill.controls["StatusId"].setValue(1);
                // }
                this.SaveFileInfo.emit();
            } else {
                if (this.isFiledNew) {
                    this.saveFileRxInfo();
                } else {
                    // prescription.controls.RxStatusId.setValue(2);
                    // prescRefill.controls.StatusId.setValue(2);
                    this.SaveFileInfo.emit();
                }
            }
        } else {
            this.formGrpInvld = true;
        }
        }
    }

    closeModalSM() {
        if (this.modalRefSM) {
        this.modalRefSM.close();
        this.modalRefSM = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    saveFileRxInfo() {
        this.activeModal = null;
        this.createNewRxFromFile();
    }

    async createNewRxFromFile() {
        if (this.rxFG) {
            const prescReFill: any = this.rxFG.controls[
                "PrescReFill"
            ] as FormGroup;

            const prescription: any = this.rxFG.controls[
                "Prescription"
            ] as FormGroup;

            const rxBill: any = this.rxFG.controls[
                "RxBill"
            ] as FormGroup;

            prescReFill.controls["GroupTransStatus"].setValue(null);
            prescReFill.controls["FillDtTm"].setValue(this.fileFG.value["dueDateTime"]);
            rxBill.controls["StatusId"].setValue(1);
            prescReFill.controls["StatusId"].setValue(1);
            prescription.controls["RxStatusId"].setValue(1);
            this.rxFG.controls["isDetOldRx"].setValue(this.rxInfo.Prescription.PrescNum);
            this.rxFG.controls["isDetOldRxRf"].setValue(this.rxInfo.PrescReFill.ReFillNum);
            this.rxFG.controls["isDetOldRxRfFill"].setValue(this.rxInfo.PrescReFill.PartialFillNo);
            this.SaveFileInfo.emit();
        }
    }

    getNdPatchNewPrescNo() {
            this._rxService
            .getPrescNoByDrugClass(this.rxInfo.Drug.drugclass).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.nextPrescNum && this.rxFG) {
                    const prescReFill: any = this.rxFG.controls[
                        "PrescReFill"
                    ] as FormGroup;

                    const prescription: any = this.rxFG.controls[
                        "Prescription"
                    ] as FormGroup;

                    const rxBill: any = this.rxFG.controls[
                        "RxBill"
                    ] as FormGroup;

                    prescReFill.controls["GroupTransStatus"].setValue(null);
                    const today = moment().format("MM/DD/YYYY");
                    prescReFill.controls["FillDtTm"].setValue(today);
                    prescReFill.controls["OrderDtTm"].setValue(
                    this._commonSvc.getSetttingValue("RxSettings", "MAKE_DT_ORDER_MNDT") === "0" ? today : null);
                    rxBill.controls["StatusId"].setValue(1);
                    prescReFill.controls["StatusId"].setValue(1);
                    prescription.controls["RxStatusId"].setValue(1);
                    this.rxFG.controls["isDetOldRx"].setValue(this.rxInfo.Prescription.PrescNum);
                    this.rxFG.controls["isDetOldRxRf"].setValue(this.rxInfo.PrescReFill.ReFillNum);
                    this.rxFG.controls["isDetOldRxRfFill"].setValue(this.rxInfo.PrescReFill.PartialFillNo);
                    this.SaveFileInfo.emit();
                } else {
                    this._alrtServ.error(MsgConfig.SOMETHING_WENT_WRONG);
                    this.IsPopUpClosed.emit();
                }
            });
    }

    valdiateFillDate() {
        const orderDtTm = this.rxFG.value.PrescReFill["OrderDtTm"];
        const fillDtTm = this.fileFG.value["dueDateTime"];
        if (!fillDtTm || moment(moment(orderDtTm).format("YYYY-MM-DD")).isAfter(moment(fillDtTm).format("YYYY-MM-DD"))) {
           this.fillDateErrorMsg = "Filled Date cannot be less than Ordered Date.";
           this.fileFG.controls["dueDateTime"].setErrors({minDate : true});
           this.fileFG.controls["dueDateTime"].markAsUntouched();
           setTimeout(() => {
                 this.fileFG.controls["dueDateTime"].markAsTouched();
           }, 100);
        } else {
            this.checkDaysDiff();
        }
    }

    checkDaysDiff() {
        const prescReFill = this.rxFG.controls.PrescReFill as FormGroup;
        const orderDtTm = prescReFill.value["OrderDtTm"];
        const fillDtTm = this.fileFG.value["dueDateTime"];
        if (orderDtTm && fillDtTm) {
            if (moment(fillDtTm).diff(orderDtTm, "days") > +this.ordFillDaysStng) {
                this.fillDateErrorMsg = "Order to fill date exceed Maximum Allowed of " + (+this.ordFillDaysStng);
                this.fileFG.controls["dueDateTime"].setErrors({minDate : true});
                this.fileFG.controls["dueDateTime"].markAsUntouched();
                setTimeout(() => {
                      this.fileFG.controls["dueDateTime"].markAsTouched();
                }, 100);
            } else {
                 this.fileFG.controls["dueDateTime"].setErrors(null);
                 this.fileFG.controls["dueDateTime"].markAsTouched();
                setTimeout(() => {
                      this.fileFG.controls["dueDateTime"].markAsUntouched();
                }, 100);
            }
        }
    }

    checkMinMaxOrderDate() {
        const rxInfo = this.rxFG.getRawValue();
        const cntrlRefllOptns = this._commonSvc.controlFileSettings$["source"]["value"];
        const insuSettings = this._insServ.insuSettings$["source"]["value"];
        if (+rxInfo.Drug.drugclass >= 2 && +rxInfo.Drug.drugclass <= 5) {
            if (cntrlRefllOptns && cntrlRefllOptns.length > 0) {
                cntrlRefllOptns.map(val => {
                    if (Number(val.DrugClass) === +rxInfo.Drug.drugclass) {
                        this.ordFillDaysStng = val.OrderFillDays ? val.OrderFillDays : 180;
                    }
                });
            }
        } else {
            if (insuSettings && insuSettings.InsuPlanOther) {
                this.ordFillDaysStng = insuSettings.InsuPlanOther.OrderToFillDays ?
                 insuSettings.InsuPlanOther.OrderToFillDays : 180;
            } else {
                this.ordFillDaysStng = 180;
            }
        }
    }
}
