import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: "[appNumberCurrency]"
})
export class NumberCurrencyDirective {
    // Allow decimal numbers and negative values
    private quantityRegex: RegExp = new RegExp(/^[-+]?\d*\.?\d{0,3}$/g);
    private currencyRegex: RegExp = new RegExp(/^[-+]?\d*\.?\d{0,4}$/g);
    regex: RegExp = new RegExp(/^[-+]?\d*\.?\d{0,2}$/g);
    decimalVal = 2;
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = [
        "Backspace",
        "Tab",
        "End",
        "Home",
        "ArrowLeft",
        "ArrowRight",
        "Delete"
    ];

    @Input() InputType: string;

    @Input() beforeDecimalVal: number;
    @Input() isRxNumber = false;
    @Input() EnteredMaxLength: number;
    @Input()
    set decimalValue(dv: any) {
        if (dv === 2) {
            this.regex = this.regex;
        } else if (dv === 3) {
            this.regex = this.quantityRegex;
            this.decimalVal = 3;
        } else {
            this.regex = this.currencyRegex;
            this.decimalVal = 4;
        }
    }
    constructor(private el: ElementRef) {}

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (this.InputType === "tel" || this.InputType === "currency") {
            // Allow Backspace, tab, end, and home keys
            if (this.specialKeys.indexOf(event.key) !== -1) {
                return;
            }
            let current: string = this.el.nativeElement.value;
            const position = this.el.nativeElement.selectionStart;
            const position1 = this.el.nativeElement.selectionEnd;
            const next: string = [
                current.slice(0, position),
                event.key,
                current.slice(position)
            ].join("");
            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            } else if (position === position1 && event.key !== "." &&
             current.split(".")[0].length === (this.beforeDecimalVal ? this.beforeDecimalVal : (this.isRxNumber? 19 : (this.EnteredMaxLength ? this.EnteredMaxLength : 7)))) {
                 if (current.indexOf(".") !== -1) {
                     if(current.split(".")[1] && current.split(".")[1] !== "") {
                         if (current.split(".")[1].length === this.decimalVal && current.split(".")[0].length >= (this.beforeDecimalVal ? this.beforeDecimalVal : (this.isRxNumber? 19 : 7))) {
                            event.preventDefault();
                         }
                     }
                 } else {
                    event.preventDefault();
                 }
            } else if (current.split(".")[0] != "" && current.split(".")[0].length > (this.beforeDecimalVal ? this.beforeDecimalVal : (this.isRxNumber? 19 : this.EnteredMaxLength ? this.EnteredMaxLength : 7))) {
                event.preventDefault();
            }
        }
    }
}
