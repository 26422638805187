<ng-template #TransferRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopup()"  >
        <h4 header>
            Rx Transfe In
        </h4>
        <div body class="eprx-transfer-req patient-edit--transfer-in">
            <!--Steppers-->
            <div class="row row-space1 " *ngIf="!awaitInfo">
                <div class="col-12 row m-0">
                    <div class="col-5 row m-0" style="padding-top: 0px !important;">
                        <div class="col-12 text-center">
                            <div> <i  [ngClass]="eRxModelFG.value['patientID'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-1-solid'"></i> Select Patient</div>
                            <div class="stepper1"
                            [ngClass]=" eRxModelFG.value['patientID'] ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-2 text-center row pl-0" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div>  <i  [ngClass]="(eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'] || RxTransferType === 'MarkTransferIn') ? 'fas fa-check-circle' : 'fa-kit fa-circle-2-solid'"></i> Select Transfer Type</div>
                            <div class="stepper2"
                            [ngClass]=" (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-5 text-center row" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div> <i  [ngClass]="eRxModelFG.value['sourceNPI'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-3-solid'"></i> Select Pharmacy</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Transfer In requested info -->
            <div class="row m-0 row-space1" *ngIf="awaitInfo">
                <div class="col-3">
                    <label>Transaction ID</label>
                    <div>{{(awaitInfo?.Jobject?.TransactionId) ? awaitInfo?.Jobject?.TransactionId : msgConfig.EMPTY_Data}}</div>
                </div>
                <div class="col-2">
                    <label>Message Date</label>
                    <div>{{(awaitInfo?.PrescriptionData?.PrescriptionData?.MessageDate) ? awaitInfo?.PrescriptionData?.PrescriptionData?.MessageDate : msgConfig.EMPTY_Data }}</div>
                </div>
                <div class="col-2">
                    <label>Message Type</label>
                    <div>{{(currentDetails ? (currentDetails['Message Type'] | uppercase) : msgConfig.EMPTY_Data)}}</div>
                </div>
                <div class="col-2">
                    <label>Transaction Status</label>
                    <div>{{(currentDetails ? (currentDetails['Status'] | uppercase) : msgConfig.EMPTY_Data)}}</div>
                </div>
                <div class="col-3">
                    <label>Error Description</label>
                    <div>{{(awaitInfo?.Jobject?.ErrorDescription) ? awaitInfo?.Jobject?.ErrorDescription : msgConfig.EMPTY_Data}}</div>
                </div>
            </div>
             <!--Patient & Transfer Type & Pharmacy selection-->
            <div class="row row-space1">
                <div class="col-5">
                    <app-patient-selection [RequestedInfo]="awaitInfo?.Patient" [IsfrmPatHis]="IsfrmPatHis" [SelectedPatientDetailsfrmRx]="selectedPatientDetails" (SelectedPatientID)="selectedPatientID($event)"></app-patient-selection>
                </div>
                <div class="col-2 px-0" >
                    <app-transfer-type [RequestedInfo]="awaitInfo" [TransferTypeWithSelRecord]="" [eRxModelFG]="eRxModelFG" [IsHistory]="true" [IsfrmPatHis]="IsfrmPatHis" (SelectedDrugDetails)="selectedDrugDetails($event)" (SelectedTransType)="selectedTransTypeDetails($event)"></app-transfer-type>
                </div>
                <div class="col-5">
                    <app-pharmacy-selection [RequestedInfo]="awaitInfo" (selectedPharm)="selectedToPharmacy($event)"></app-pharmacy-selection>
                </div>
            </div>
            <!--Transfer Type-->
            <div class="row row-space1">
                <div class="col-12"> 
                    <app-transfer-request-details [SelectedTransferTypeDetails]="selectedDruginformation ? selectedDruginformation : selectedTransferTypeDetails"></app-transfer-request-details>
                </div>
            </div>
               <!---Your Pharmacy details & Notes-->
            <div class="row row-space1">
                <div class="col-5">
                    <app-current-pharmacy (RequestPharmacyDetails)="requestPharmacyDetails($event)" [LabelName]="'Your Pharmacy'"></app-current-pharmacy>
                </div>
                <div class="col-4 px-0">
                    <app-single-notes [RequestedInfo]="awaitInfo"  (TransferNotes)="transferSingleNotes($event)" [IsNotPopup]="true" 
                        [MainCategory]="'eRx'"></app-single-notes>
                </div>
                <div class="col-3 text-center" style="vertical-align: middle;line-height: 15;">
                      <img src="/assets/Mark Transfer In.svg" *ngIf="TransferType">
                      <img src="/assets/Transfer In Req.svg" *ngIf="!TransferType">
                </div>
            </div>
        </div>
        <!--Footer-->
        <button *ngIf="!awaitInfo"   footer class="hotkey_success"
            [ngbTooltip]="eRxModelFG.invalid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'])?'':'Rx Transfer In Request.'" triggers="mouseover:mouseleave"
            [autoClose]="true" placement="top" tooltipClass="search-tooltip title-tooltip"
            [ngClass]="(eRxModelFG.valid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'])) ? 'hotkey_success' : 'inactive'"
            (click)="eRxModelFG.valid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? sendTransferInReq() : ''"  appShortcutKey [AltKey]="'q'"><span>Q</span> Send Transfer In Req
        </button>
        <button footer  class="hotkey_success" (click)="closePopup()" (click)="c('close')"  appShortcutKey
            [AltKey]="'c'"><span>C</span> {{ awaitInfo ? 'CLOSE' : 'CANCEL'}}
        </button>

    </app-custom-modal>
</ng-template>