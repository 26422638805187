import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MsgConfig } from 'src/app/app.messages';
import { PharmacyLabels } from 'src/app/models/labels.models';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';

@Component({
  selector: 'app-pharmacy-selection',
  templateUrl: './pharmacy-selection.component.html',
  styleUrls: ['./pharmacy-selection.component.scss']
})
export class PharmacySelectionComponent implements OnInit {
@Input() isfromPatientHistory:boolean;
sourcepharmacyInfo: any;
@Input()
set RequestedInfo(pharmacy : any){
  if(pharmacy?.PrescriptionData?.PrescriptionData?.PharmacyDetail?.SourcePharmacy)
   this.sourcepharmacyInfo = pharmacy.PrescriptionData.PrescriptionData.PharmacyDetail.SourcePharmacy;
    this.SelectedpharmDetails();
}
selectedpharmacyDetails: any;
IsFocusField: any;
rxTransferFG: FormGroup;
pharmacyabels = new PharmacyLabels();
public citySelected = null;
public stateSelected = null;
modalRef: any;
@Output() selectedPharm = new EventEmitter();
pharmacySearchValue: any = "";
addPharFG: FormGroup;
msgConfig = MsgConfig;
addPopUp = false;
isedit = false;
newPharId: any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _patientUtil: PatientInfoUtil
  ) {
   }

  ngOnInit(): void {
    
  }
  selectedPharmDetails(pharmacy) {
    this.selectedpharmacyDetails = pharmacy;
      this.selectedPharm.emit(pharmacy);
  }

  openPharmacyEdit() {
      this.isedit = false;
      this._cdr.detectChanges();
      this.isedit = true;
  }
  SelectedpharmDetails() {
    if(this.sourcepharmacyInfo)
    {
      this.selectedpharmacyDetails = {
        PharmacyName : this.sourcepharmacyInfo.BusinessName,
        NPINum  : this.sourcepharmacyInfo.Npi, 
        Fax  : this.sourcepharmacyInfo.Fax, 
        Phone1  : this.sourcepharmacyInfo.PhoneNumbers, 
        Address : this._patientUtil.getPatientAddress({
          address1: this.sourcepharmacyInfo.AddressLine1,
          address2:this.sourcepharmacyInfo.AddressLine2,
          city: this.sourcepharmacyInfo.City,
          state: this.sourcepharmacyInfo.State,
          zipcode: this.sourcepharmacyInfo.ZipCode,
        }),
      }
    }
  }




}
