import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Component, OnInit, ViewChild, HostListener, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccCategoryE, PaginationModel, RphFilters, RxE, SystemData } from "../../models";
import { RphVerificationService, CommonService, AlertService, RxService, PatPreDrugModalService } from "../../services";
import * as moment from "moment";
import { CollectionView } from "@grapecity/wijmo";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ColorCode, CommonUtil, NRxUtils, ValidateFormFieldsUtils, WijimoUtil } from "../../utils";
import { MsgConfig } from "src/app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonStore } from "src/app/store";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { CommonWarnorconfirmPopupComponent } from "../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { EdRxComponent } from "../ed-rx";
import { PrivMaskGuard } from "src/app/guards";
import { VerificationTabsListComponent } from "../verification-tabs/verification-tabs-list/verification-tabs-list.component";
import { FormatsUtil } from "src/app/utils/formats.util";
@Component({
    selector: "app-rph-verification",
    templateUrl: "./rph-verification.component.html"
})
export class RphVerificationComponent implements OnInit, OnDestroy {
    @ViewChild("RPHVerifylist")
    flex: wjcGrid.FlexGrid;
    @ViewChild("AlreadyVerif", {static: true})
    AlreadyVerif: any;
    @ViewChild("DiscontinuedRx", {static: true})
    DiscontinuedRx: any;

    rphVerifyFilters: RphFilters = new RphFilters();
    paginationModel: PaginationModel = new PaginationModel();

    rphFilterFG: FormGroup;
    systemData: SystemData;
    today: any;
    hideFilterForm = true;
    selectedPatient: any;
    rphListingWJ: any;
    actvHeaders: string[];
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    TotalRecords: any;
    totalCount: any;
    stdate: any;
    eddate: any;
    showActions = false;
    minDate: { year: number; month: number; day: number };
    verifyStage: { status: string; value: string }[];
    verifyStatus: { status: string; value: string }[];
    pharmacyFilters: { status: string; value: string }[];
    SuggestData: any;
    selectedUserId: string;
    selectedUser: string;
    stageName: any;
    selectedRxInfo: any;
    deliveryInfo: any;
    directRxNumber: string;
    optRxNumber: string;
    routeFrom: any;
    startdate: string;
    patVal: any;
    val: any;
    rphData: any;
    val1: any;
    patientSearchInputValue:any;
    VERIFIED_STATUS = "V";
    NOTVERIFIED_STATUS = "NV";
    appUsrData$: any;
    val2: any;
    _wijHeight: any;
    rxInfo: any;
    startIndex: number;
    verifiedAlready: any;
    wjHeaders: any;
    isRxVerification: boolean = true;
    btnType: string;
    model: any;
    totalRecordCnt: any;
    reqRxVerfStng: any;
    storedFilters: any;
    routeFromSidenav : any;
    selectedrxNo: any;
    selectedrxId: any;
    openOverride: boolean;
    privType: any;
    searchByRx: any;
    categoryId: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    patientResp: boolean;
    displayGrid: boolean;
    loading: boolean;
    progressValue: number;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 70) { // f
                event.preventDefault();
                this.getRphData();
            } else if (event.which === 76) { // l
                event.preventDefault();
                this.reset();
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _rphService: RphVerificationService,
        public _commonServ: CommonService,
        private _actvRoute: ActivatedRoute,
        private _colorCode: ColorCode,
        private _alertSer: AlertService,
        private _modalService: NgbModal,
        private _commonStore: CommonStore,
        private _formUtils: ValidateFormFieldsUtils,
        private _comnUtil: CommonUtil,
        public _router: Router,
        private _wijimoUtils: WijimoUtil,
        private _rxService: RxService,
        private _hoverOver: HoverOver,
        private _colorCodeRx: ColorCodeRx,
        private _nrxUtils: NRxUtils,
        private _modalSvc: NgbModal,
        private _patPreDrugService: PatPreDrugModalService,
        private _privMaskGuard: PrivMaskGuard,
        private _formatsUtil: FormatsUtil
    ) {
        this.val = -1;
    }

    ngOnInit() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemData = resp;
        });
        this.appUsrData$ = this._commonServ.appUserData$;
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.verifyStage = this.getVerifyStageValues();
        this.verifyStatus = this.getVerifyStatusValues();

        this._actvRoute.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(params => {
            this.getPresentUrl();
            if (params && params.from) {
                this.routeFrom = params.from;
                this.routeFromSidenav = params.fromSidenav;
                const dates = this._commonStore.dashFilters$["source"]["value"];
                if (dates) {
                  this.startdate = dates.StartDate;
                  this.today = dates.EndDate;
                }
            } else {
                this.startdate = this.today;
            }
        });
        this.createFG();
        this.storedFilters = this._commonStore.rphFilters$["source"]["value"];
        if (this.storedFilters) {
            this.rphFilterFG.patchValue(this.storedFilters);
            this._commonStore.storeRphFilters(null);
            if(!this.storedFilters.PatientId) {
                this.selectedPatient = "";
                this.patientSearchInputValue = true;
            } else {
                this._commonStore.patientInfo$
                .subscribe(res => {
                    if ((res.patientid) && (this.storedFilters.PatientId === res.patientid)) {
                        this.patientSearchInputValue = res.patientname;
                        this.selectedPatient = res.patientname;
                        this.patVal = res.patientname;
                    } else {
                        this.selectedPatient = "";
                        this.patientSearchInputValue = true;
                    }
                })
            }
        } else {
            this.setDefaultValues();
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === (this.isRxVerification ? "rxVerfWJ" : "rphVerfWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        this.reqRxVerfStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        if (!this.reqRxVerfStng && this.isRxVerification && !(this.storedFilters && this.storedFilters.alreadyInfoChckd)) {
            this.warnPopupChecks();
        }
        this.getRphData();
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData(this.isRxVerification ? "rxVerfWJ" : "rphVerfWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader(this.isRxVerification ? "rxVerfWJ" : "rphVerfWJ", storedWJ);
    }

    getPresentUrl() {
        let presentUrl: any = location.href.split("/");
        if ((presentUrl && presentUrl.length && ((presentUrl[5] === "RxVerification")) || presentUrl[5] === "RxVerification?from=Dashboard")) {
            this.isRxVerification = true;
        } else {
            this.isRxVerification = false;
        }
        if (this.isRxVerification) {
            this.verifyStatus = this.getVerifyStatusValuesForRxverification();
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    createFG() {
        this.rphFilterFG = this._fb.group(new RphFilters());
    }

    setDefaultValues() {
        this.patientSearchInputValue = true;
        this.rphFilterFG.controls["FromDate"].patchValue(this.startdate);
        this.rphFilterFG.controls["ToDate"].patchValue(this.today);
        this.rphFilterFG.controls["PageNumber"].patchValue(this.paginationModel.pageNumber);
        this.rphFilterFG.controls["PageSize"].patchValue(this.paginationModel.pageSize);
        this.rphFilterFG.controls["DDIOnly"].patchValue(false);
        this.rphFilterFG.controls["Rxonly"].patchValue(true);

        if (this.isRxVerification) {
            this.rphFilterFG.controls["PharmacyFilter"].patchValue((this.routeFrom === "Dashboard" && this.isRxVerification) ? null : "rdbOriginalOnly");
        }

        this.rphFilterFG.controls["VerifyStatus"].patchValue(this.routeFrom === "Dashboard" || this.routeFromSidenav ? this.NOTVERIFIED_STATUS : "a");
        this.rphFilterFG.controls["VerifyStage"].patchValue("a");
        this.rphFilterFG.controls["PatientId"].patchValue(null);
        this.rphVerifyFilters = this.rphFilterFG.value;
    }

    selectPatient(patInfo?: any) {
        if (patInfo) {
            this.patientSearchInputValue = patInfo.fullname ? patInfo.fullname.replace("_", " , ") : null;
            this.rphFilterFG.controls["PatientId"].patchValue(patInfo.patientid);
            this.selectedPatient = patInfo.fullname ? patInfo.fullname.replace("_", " , ") : null;
            this.patVal = patInfo.fullname ? patInfo.fullname.replace("_", " , ") : null;
            const storePatInfo = {patientid: patInfo.patientid, patientname: this.selectedPatient};
            this._commonStore.storePatientInfo(storePatInfo);
        } else {
            this.patientSearchInputValue = true;
            this.rphFilterFG.controls["PatientId"].patchValue(null);
            this.selectedPatient = null;
            this.patVal = "";
        }
    }

    toggleColorLegend() {
        this._alertSer.showHelpText({RxStatus: true, DrugName: true, Status: true});
    }

   async getRphData() {
         await this._formUtils.blurAndDelay();
            const resp = this._formUtils.checkDateErrors(this.rphFilterFG, "FromDate", "ToDate", 0);
          if (resp === "DATEISSUE") {
                this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
            } else if (resp === "INVALID") {
                this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
            } else {
            if (moment(this.rphFilterFG.value.FromDate, "MM/DD/YYYY") > moment(this.rphFilterFG.value.ToDate, "MM/DD/YYYY")) {
                this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
                return;
            }
            // this.rphFilterFG.value.UserId = this.selectedUserId;
            if (document.getElementById('loader'))
                document.getElementById('loader').style.display = 'block';
            this.patientResp = false;
            this.startProgress();
            if (this.isRxVerification) {
                const rxFilterFG: any = {
                    FromDate : this.rphFilterFG.value["FromDate"],
                    PageNumber : this.rphFilterFG.value["PageNumber"],
                    PageSize : this.rphFilterFG.value["PageSize"],
                    ToDate : this.rphFilterFG.value["ToDate"],
                    UserId : this.rphFilterFG.value["UserId"],
                    selectedIndex : this.rphFilterFG.value["selectedIndex"],
                    Rxonly: this.rphFilterFG.value["Rxonly"],
                    VerifyStatus: this.rphFilterFG.value["VerifyStatus"],
                    PharmacyFilter: this.rphFilterFG.value["PharmacyFilter"]
                };
                this._rphService
                    .sendRxVerifyFilters(rxFilterFG)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        if (resp) {
                            this.rphData = resp;
                            this.totalRecordCnt = this.rphData.data?.length;
                            if (this.rphData.data?.length) {
                                this.displayGrid = true;
                                this.isDataExists = true;
                                this.loaderDisplay();
                                this.val = this.rphData.data[0].Prescription.PrescNum;
                                this.val1 = this.rphData.data[0].PrescReFill.ReFillNum;
                                this.val2 = this.rphData.data[0].PrescReFill.PartialFillNo;
                                this.selectedRxInfo = this.rphData.data[0];
                                this.selectedRxInfo["row"] = 0;
                                this.rphFilterFG.controls["selectedIndex"].patchValue(0);
                            } else {
                                this.displayGrid = true;
                                this.loaderDisplay();
                                this.isDataExists = false;
                                this.rphListingWJ = new CollectionView(null)
                            }
                            this.generateRph(resp);
                        }
                    });
            } else {
            this._rphService
                .sendRphVerifyFilters(this.rphFilterFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.rphData = resp;
                        this.totalRecordCnt = this.rphData.data?.length;
                        if (this.rphData.data?.length) {
                            this.displayGrid = true;
                            this.isDataExists = true;
                            this.loaderDisplay();
                            this.val = this.rphData.data[0].Prescription.PrescNum;
                            this.val1 = this.rphData.data[0].PrescReFill.ReFillNum;
                            this.val2 = this.rphData.data[0].PrescReFill.PartialFillNo;
                            this.selectedRxInfo = this.rphData.data[0];
                            this.selectedRxInfo["row"] = 0;
                            this.rphFilterFG.controls["selectedIndex"].patchValue(0);
                        }  else {
                            this.displayGrid = true;
                            this.loaderDisplay();
                            this.isDataExists = false;
                            this.rphListingWJ = new CollectionView(null)
                        }
                        this.generateRph(resp);
                    }
                });
            }
        }
    }
    startProgress() {
        this.loading = true;
        this.progressValue = 0;
        const interval = setInterval(() => {
          if (this.patientResp) {
            this.progressValue = 100;
            clearInterval(interval);
          } else {
            this.progressValue += 25;
          }
        }, 100);
    }
    loaderDisplay() {
        this.patientResp = true;
        if (this.patientResp) {
            setTimeout(()=>{
                this.loading = false;
                if (document.getElementById('loader'))
                    document.getElementById('loader').style.display = 'none';
            }, 200)
        }
    }

    reset() {
        this.rphFilterFG.reset();
        this.rphFilterFG.markAsTouched();
        this.setDefaultValues();
        this.rphVerifyFilters.UserId = null;
        this.patVal = null;
        this.selectedUserId = null;
        this.selectedUser = null;
        this.directRxNumber =  "";
        this._commonStore.storePatientInfo(null);
        this.getRphData();
    }

    setPage(page: number) {
        this.pageNu = page;
        this.rphFilterFG.controls["pageNu"].patchValue(this.pageNu);
        this.startIndex = (this.Size * (this.pageNu - 1));
        this.rphFilterFG.controls["PageNumber"].patchValue(this.pageNu);
        this.getRphData();
    }

    setSize(size: any) {
        this.Size = size;
        this.pageNu = this.paginationModel.pageNumber;
        this.rphFilterFG.controls["PageSize"].patchValue(this.Size);
        this.setPage(this.paginationModel.pageNumber);
    }




    generateRph(rphFilterInfo: any) {
        this.totalCount = rphFilterInfo.TotalRecords;
        if (rphFilterInfo.data !== []) {
            this.showActions = true;
            const mapDataSet = [];
                rphFilterInfo.data.map((rph, i) => {
                    const j = {};
                    j["row"] = i;
                    j["data"] = rph;
                    j["Rx#"] = rph.Prescription && rph.Prescription.PrescNum ? rph.Prescription.PrescNum : null;
                    j["Ref#"] = rph.PrescReFill ?  rph.PrescReFill.ReFillNum : null;
                    j["Fill#"] = this._commonServ.checkIsPartialFill(rph.PrescReFill, rph.Prescription) ? rph.PrescReFill.PartialFillNo : " ";
                    j["Patient Name"] = rph.Patient ? (rph.Patient.lastname + ', ' + rph.Patient.firstname): null;
                    j["Drug Name"] = rph.Drug? (
                        rph.Drug.drugname +
                        " " +
                        rph.Drug.strength +
                        " " +
                        rph.Drug.drugformname) : null;
                    j["Qty"] = rph.PrescReFill && rph.PrescReFill.DispQty ? (rph.PrescReFill.DispQty).toFixed(3) : "0.000";

                    j["Filled Date"] = rph.PrescReFill && rph.PrescReFill.FillDtTm ?  moment.utc(rph.PrescReFill.FillDtTm).local().format(
                        "MM/DD/YYYY"
                    ) : null;
                    j["Days Supply"] = rph.PrescReFill && rph.PrescReFill.SupplyDays ? rph.PrescReFill.SupplyDays : null;
                    let rphVerify = rph["rPHVerifs"];
                    const rphVerfList = rphVerify && rphVerify.length>0 ? rphVerify.filter((v: any)=> !((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"])) : [];
                    const rxVerfList = rphVerify && rphVerify.length>0 ? rphVerify.filter((v: any)=> ((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"])) : [];
                    j["Verification Status"] = (!this.isRxVerification) ?
                    this._comnUtil.getRphStatus(rph.rph2VerificationStatus, rph.FollowUpStatus, (rphVerfList?.length ? rphVerfList[rphVerfList.length - 1] : null), rphVerfList) : this._comnUtil.getRXStatus(rph.rph1VerificationStatus, (rxVerfList?.length ? rxVerfList[rxVerfList.length - 1] : null), rxVerfList);
                    // if (
                    //     (rph.FollowUpStatus === null ||
                    //         rph.FollowUpStatus === "") &&
                    //     ((rph.rph2VerificationStatus === "V" && !this.isRxVerification)
                    //      || (rph.rph1VerificationStatus === "R" && (this.isRxVerification)))
                    // ) {
                    //     j["Verification Status"] = "Verified";
                    // } else if (
                    //     (rph.FollowUpStatus === null ||
                    //         rph.FollowUpStatus === "") &&
                    //         ((rph.rph2VerificationStatus === "RV" && !this.isRxVerification)
                    //         || (rph.rph1VerificationStatus === "RV" && (this.isRxVerification)))
                    // ) {
                    //     j["Verification Status"] = "Re-verify";
                    // } else if (
                    //     rph.FollowUpStatus === "F" &&
                    //     (((rph.rph2VerificationStatus === null ||
                    //         rph.rph2VerificationStatus === "") && !(this.isRxVerification)))
                    // ) {
                    //     j["Verification Status"] = "Follow up";
                    // } else {
                    //     j["Verification Status"] = "Not Verified";
                    // }
                    j["Is340B"] = rph.PrescReFill && rph.PrescReFill.Is340B ? "Y" : "N";
                    j["Status"] =
                        (rph.PrescReFill.StatusId === 4 || rph.Prescription.RxStatusId === 4) ? "T" :
                            (rph.PrescReFill.StatusId === 2 || rph.Prescription.RxStatusId === 2 || rph.RxBill.StatusId === 6) ? "D" :
                                (rph.PrescReFill.StatusId === 3
                                    || rph.Prescription.RxStatusId === 3) && rph.RxBill.StatusId === 2 ? "BI" :
                                    (rph.RxBill.StatusId === 2 && rph.PrescReFill.StatusId !== 4
                                        && rph.Prescription.RxStatusId !== 2) ? "B" :
                                        ((rph.PrescReFill.StatusId === 3 || rph.Prescription.RxStatusId === 3) &&
                                            rph.RxBill.StatusId === 1) ? "UI" : "U";
                    const primaryInsu = rph.Patient ? _.filter(rph.Patient.insurance, {
                        insuranceid: rph.RefillInsu.InsuId
                    })[0] : null;
                    j["IsBilled"] = this._comnUtil.checkIsGreenBilled(rph, this.systemData);
                    j["DrugClass"] = rph.Drug ? rph.Drug.drugclass: null ;
                    const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                    const isDrugDiscontinued = rph.Drug["discontdt"] && moment().startOf("day")
                        .isAfter(moment(rph.Drug["discontdt"], "MM/DD/YYYY").add(days, "days"));
                    j["IsDrugDis"] = isDrugDiscontinued ? "YES" : "NO";
                    j["Ins Name"] = rph.insuranceInfo
                        ? rph.insuranceInfo.insuPlan
                            ? rph.insuranceInfo.insuPlan.PlanCode
                            : null
                        : null;
                    j["Prescriber Name"] = rph.Prescriber
                        ? rph.Prescriber.prescriberlastname +
                          ", " +
                          rph.Prescriber.prescriberfirstname
                        : null;
                    j["Class"] = rph.Drug ? rph.Drug.drugclass : null;
                    j["Picked up"] = rph.PickupRx && rph.PickupRx.IsPickedUp ? "Y" : "N";

                    j["IsPicked"] = (rph.PickupRx && rph.PickupRx.IsPickedUp) ? true : false;
                    j["Facility Name"] = rph.Patient && rph.Patient.PatientFacilityInfo && rph.Patient.PatientFacilityInfo.Organization ?  rph.Patient.PatientFacilityInfo.Organization.Name : null;
                    j["DEA"] = j["DEA#"] = rph.Prescriber
                        ? this._formatsUtil.getDeaFormat(rph.Prescriber.prescriberdeanum)
                        : null;
                    j["Expected DtTm"] = rph.PickupRx
                        ? moment(rph.PickupRx.ExpDtTm).format(
                              "MM/DD/YY hh:mm:ss"
                          )
                        : null;
                    j["Qty Ordered"] = rph.PrescReFill && rph.PrescReFill.OrdQty ? (rph.PrescReFill.OrdQty).toFixed(3) : "0.000";
                    j["Qty Owed"] = rph.QtyOwed ? (rph.QtyOwed).toFixed(3) : "0.000";
                    this.deliveryInfo = _.filter(this.systemData.DeliveryMode, {
                        Id: rph.PrescReFill.DeliverModeId
                    });

                    j["Delivery Mode"] = this.deliveryInfo && this.deliveryInfo.length ? this.deliveryInfo[0].Name : "";
                    j["Filled By"] = rph.PrescReFill.TechInitials ?
                     rph.PrescReFill.TechInitials : rph.PrescReFill.RegPharmacist ? rph.PrescReFill.RegPharmacist : '';
                    const rphData = rph.rPHVerifs && rph.rPHVerifs.length ? rph.rPHVerifs : null;
                    const verify = rphData && rphData.filter(v =>
                        !((v["Status"] && (v["Status"]).trim().toLowerCase() === "r") || v["IsForRxVerification"]));
                    if (this.isRxVerification) {
                        const verify1 = rphData && rphData.filter(v =>
                             ((v["Status"] && (v["Status"]).trim().toLowerCase() === "r") || v["IsForRxVerification"]));
                        j["Verified By R.Ph"] = verify1 && verify1.length > 0 && verify1[verify1.length - 1]["Status"].trim().toUpperCase() === "R"
                            ? verify1[0].UserName : null;
                        j["Verified DtTm"] = (verify1 && verify1.length > 0 && verify1[verify1.length - 1]["Status"].trim().toUpperCase() === "R") ?
                            moment.utc(verify1[verify1.length - 1].VerifDtTm).local().format("MM/DD/YY hh:mm:ss a") : null;
                    } else {
                        j["Verified By R.Ph"] = verify && verify.length > 0 && (verify[verify.length - 1]["Status"].trim().toUpperCase() === "V" || verify[verify.length - 1]["Status"].trim().toUpperCase() === "F" || verify[verify.length - 1]["Status"].trim().toUpperCase() === "RV")
                        ? verify[verify.length - 1].UserName : null;
                        j["Verified DtTm"] = verify && verify.length > 0 && (verify[verify.length - 1]["Status"].trim().toUpperCase() === "V" || verify[verify.length - 1]["Status"].trim().toUpperCase() === "F" || verify[verify.length - 1]["Status"].trim().toUpperCase() === "RV") ?
                         moment.utc(verify[verify.length - 1].VerifDtTm).local().format("MM/DD/YY hh:mm:ss a") : null;
                   }


                    j["Follow-Up Reason"] = verify && verify.length ? verify[verify.length - 1]["Reason"] : null;
                    j["Auth Ref"] = rph.Prescription ? rph.Prescription.ReFillNum : null;
                    // j["Status"] = rph.RxBill.StatusId === 1 ? "UNBILLED" :
                    //  rph.RxBill.StatusId === 2 ? "BILLED" : rph.RxBill.StatusId === 6 ? "FILED" : "TRANSFERED";
                    j["Paid"] = !rph.PaidPrice || rph.PaidPrice === 0 ? "$0.00" : "$" + (parseFloat(rph.PaidPrice)).toFixed(2);
                    j["Billed"] = rph.PrescReFill.TotalAmt ? "$" + (parseFloat(rph.PrescReFill.TotalAmt)).toFixed(2) : "$0.00";
                    j["Days"] = Math.round(rph.PrescReFill.SupplyDays);
                    const duedays = Math.round(rph.PrescReFill.SupplyDays) -
                        moment().startOf("day").diff(moment.utc(rph.PrescReFill.FillDtTm).startOf("day"), "days");
                    j["Due Days"] = (j["Status"] === "T" || (j["Auth Ref"] === 0 && j["Ref#"] === 0)) ? 0 : duedays < 0 ? 0 : duedays;
                    j["DueDays"] = duedays;
                    j["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(j);
                    j["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(j);
                    const rowId = j["row"] >= 0 ? j["row"] : "";
                    const RxDetails = rowId >= 0 ? this.rphData.data[rowId] : null;
                    j["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                    // return j;
                    mapDataSet.push(j)
                })
                this.rphListingWJ = new CollectionView(mapDataSet,
                    {
                        sortComparer: (a: any, b: any) => {
                            return moment(a).isValid() && moment(b).isValid()
                                ? moment(a).diff(moment(b)) : null;
                        }
                    });
        } else {
            this.showActions = false;
        }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
               cell.style.fontWeight = "bold";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.textAlign = "center";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["row"] : "";
                const RxDetails = rowId >= 0 ? this.rphData.data[rowId] : null;
                const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Verification Status") {
                    let key = panel.columns[c].binding;
                    if (row.dataItem[key].toUpperCase() === "VERIFIED") {
                        cell.style.background = "darkgreen";
                        cell.style.color = "#fff";
                    } else if (row.dataItem[key].toUpperCase() === "RE-VERIFY") {
                        cell.style.background = "orange";
                        cell.style.color = "#fff";
                    } else {
                        cell.style.backgroundColor = "#fff";
                        cell.style.color = "#000";
                        cell.style.fontWeight = "normal";
                        cell.style.textAlign = "initial";
                    }
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }
    ngOnChanges() {
        this.getPresentUrl();
    }

    getVerifyStageValues() {
        return [
            {
                status: `All`,
                value: "a"
            },
            {
                status: `Rx Verification `,
                value: "R"
            },
            {
                status: `Pharmacist Verification`,
                value: "V"
            }
        ];
    }

    getVerifyStatusValuesForRxverification() {
        return [
            {
                status: `All`,
                value: "a"
            },
            {
                status: `Verified`,
                value: this.VERIFIED_STATUS
            },
            {
                status: `Not verified`,
                value: "NV"
            },
            {
                status: `Re-verify`,
                value: "RV"
            }
        ];
    }

    getVerifyStatusValues() {
        return [
            {
                status: `All`,
                value: "a"
            },
            {
                status: `Verified`,
                value: this.VERIFIED_STATUS
            },
            {
                status: `Follow up`,
                value: "F"
            },
            {
                status: `Not verified`,
                value: "NV"
            },
            {
                status: `Re-verify`,
                value: "RV"
            }
        ];
    }

    searchUsers = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            switchMap(term => this.searchUserID(term))
        );

    searchUserID(value: string) {
        this.SuggestData = [];
        if (value) {
            this.SuggestData = this._rphService.getRphVerifyUsers(value);
        } else {
            this.SuggestData = this._rphService.getEmptyObservable();
            this.selectedUserId = null;
            this.selectedUser = null;
        }
        return this.SuggestData;
    }

    Usersformatter = (x: any) => {
        return x.FullName + "," + x.UserName;
    };

    optedUserValue(val: any) {
        if (val) {
            this.selectedUserId = val.item.Id;
            this.selectedUser = val.item.FullName + "," + val.item.UserName;
        } else {
            this.selectedUserId = null;
            this.selectedUser = null;
        }
    }

    selectedRx(isChecked: boolean, checkedVal: any, index: any) {
        if (isChecked) {
            this.val = checkedVal.Prescription.PrescNum;
            this.val1 = checkedVal.PrescReFill.ReFillNum;
            this.val2 = checkedVal.PrescReFill.PartialFillNo;
            this.selectedRxInfo = checkedVal;
            this.selectedRxInfo["row"] = index;
            this.rphFilterFG.controls["selectedIndex"].patchValue(index);
        }
    }

    optedRxNumber(rxNo: string) {
        this.directRxNumber = rxNo;
    }

    verifyWithRxNumber() {
        if (this.directRxNumber && this.directRxNumber != null && this.directRxNumber !== "") {
            this.optRxNumber = this.directRxNumber;
            this.getRxDetails(this.optRxNumber, true);
            this.optRxNumber = null;
        }
    }

    getRxDetails(rxNo: any, frmRxSearch?:boolean) {
        if (rxNo) {
            const rxNoarr = rxNo.split("-");
            this._rphService
            .getRphVerifyDetails({
                PageNumber: this.paginationModel.pageNumber,
                PageSize: 1,
                RxId: rxNoarr[0] ? rxNoarr[0] : null,
                RefillNum: rxNoarr[1] ? rxNoarr[1] : null,
                PartialfillNum: rxNoarr[2] ? rxNoarr[2] : null,
                IsFromRxSearch: frmRxSearch
            }, this.isRxVerification
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.rxInfo = resp.data[0];
                    if (this.rxInfo) {
                        this.checkVerifications();
                    } else {
                        this._alertSer.error("Rx not found.");
                        this.directRxNumber = "";
                    }
                }
            });
        }
    }

    checkVerifications() {
        if (this.rxInfo["PrescReFill"]["StatusId"] === 2 || this.rxInfo["Prescription"]["RxStatusId"] === 2) {
            this._alertSer.error("Deferred Rx cannot be verified.");
            this.directRxNumber = "";
        } else if (this.rxInfo["PrescReFill"]["StatusId"] === 4 || this.rxInfo["Prescription"]["RxStatusId"] === 4) {
            this._alertSer.error("Transfered Rx cannot be verified.");
            this.directRxNumber = "";
        } else if (!this.isRxVerification && this.rxInfo["RxBill"]["StatusId"] === 1 && (this._commonServ.getSetttingValue("WorkFlowSettings","ALLOW_RPH_VERF_UNB_RX" ) === "0")) {
            this._alertSer.error("Unbilled Rx cannot be verified.");
        } else if (!this.isRxVerification) {
            this.IspharmacyVerified();
        } else {
                this.IsVerifiedAlready();
        }
    }

    IspharmacyVerified() {
        this._rphService.IsPharmacyDrugPickVerified()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.IsDrugPickVerified();
            } else {
                this._alertSer.error("Pharmacy verification unsuccessful.");
                this.directRxNumber = "";
            }
        });
    }

    IsDrugPickVerified() {
        if (
            this._commonServ.getSetttingValue(
                "WorkFlowSettings",
                "MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF"
            ) === "1"
        ) {
        this._rphService
            .IsDrugPickVerified(this.rxInfo.Prescription.PrescNum , this.rxInfo.PrescReFill.ReFillNum,
                this.rxInfo.PrescReFill.PartialFillNo)
                .pipe(takeUntil(this.unsubscribe$))
            .subscribe(drugVerified => {
                if (drugVerified) {
                    this.IsVerifiedAlready();
                } else {
                    this._alertSer.error(
                        "Drug pick verification is required before pharmacist verification."
                    );
                    this.directRxNumber = "";
                }
            });
        } else {
            this.IsVerifiedAlready();
        }
    }

    IsVerifiedAlready() {
        this._rphService
            .IsRphVerifiedAlready(
                this.rxInfo?.Prescription?.Id,
                this.rxInfo?.PrescReFill?.Id,
                this.isRxVerification
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(verfiRe => {
                if (verfiRe && verfiRe["Status"] && (verfiRe["Status"].trim() === "V" || verfiRe["Status"].trim() === "R")) {
                    this.verifiedAlready = verfiRe;
                    this._modalService.open(this.AlreadyVerif, { keyboard: false, backdrop: false, windowClass: "large--content",
                centered:true});
                this.directRxNumber = "";
                } else {
                    if (!this.reqRxVerfStng && this.isRxVerification && !(this.storedFilters && this.storedFilters.alreadyInfoChckd)) {
                        this.warnPopupChecks();
                    } else {
                        this.checkIsDiscontinue();
                    }
                }
            });
    }

    checkIsDiscontinue() {
        if (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.IsDiscontinued) {
            this.model = this._modalService.open(this.DiscontinuedRx, { centered: true, backdrop: false,
                 keyboard: false, windowClass: "large--content"});
            this.directRxNumber = "";
        } else {
            this.RxVerifyNavigate();
        }
    }

    async setOrderStatus() {
        await this._rxService.putRxDiscontinuedOrderStatus(this.rxInfo.Prescription.Id).toPromise();
        this.RxVerifyNavigate();
    }

    RxVerifyNavigate() {
        const type = this.isRxVerification ? "RxVerification" : "RphVerification";
        this._commonStore.storeRphFilters(this.rphFilterFG.value);
        const modalRefVerfTab = this._modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false});
        modalRefVerfTab.componentInstance.IsRxVerification = this.isRxVerification;
        modalRefVerfTab.componentInstance.RxInfo = this.rxInfo;
        modalRefVerfTab.componentInstance.RxID = this.rxInfo.Prescription.PrescNum ;
        modalRefVerfTab.componentInstance.RefilID = this.rxInfo.PrescReFill.ReFillNum ;
        modalRefVerfTab.componentInstance.FillID = this.rxInfo.PrescReFill.PartialFillNo;
        modalRefVerfTab.componentInstance.RefillNumId = this.rxInfo.PrescReFill.Id;
        modalRefVerfTab.componentInstance.activeTabId = this.isRxVerification ? "rxVerfTab" : "rphVerfTab";
        modalRefVerfTab.componentInstance.closeTabs
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((x: any) => {
            modalRefVerfTab.close();
            sessionStorage.removeItem("RphFmDPV");
            this.getGridRefresh(true);
            this.directRxNumber = "";
        });
        /*this._router.navigate([
            "/eprx/" + type + "/" +
                this.rxInfo.Prescription.PrescNum +
                "/" +
                this.rxInfo.PrescReFill.ReFillNum +
                "/" +
                this.rxInfo.PrescReFill.PartialFillNo +
                "/verifyRx"
        ]);*/
    }

    getGridRefresh(val: any) {
        if(document.getElementById("RxNumber")){
            document.getElementById("RxNumber").focus();
        }
        this.getRphData();
    }

    focusOutFrmCancel(event) {
        if (!this.totalCount) {
            this.focusToFirst(event);
        }
    }

    focusToFirst(event) {
        if (document.getElementById("RxNumber")) {
            event.preventDefault();
            document.getElementById("RxNumber").focus();

        }
    }

    selectRadioOnKeyEvents(grid) {
        if(grid &&  grid.selectedItems &&  grid.selectedItems.length)
        {
            this.selectedRx(
                true,
                grid.selectedItems[0].data,
                grid.selectedItems[0].row
            );
        }
    }

    warnPopupChecks() {
        const warnMsg = "Require Rx Verification setting is set to No. If you wish to verify, please navigate to the Account Settings" +
        " – Workflow tab and change the setting to Yes.";
        let modelRef: any = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, centered: true, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg = warnMsg;
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.IsHeaderText = "Information";
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            if (modelRef) {
                modelRef.close();
                modelRef = null;
                this.directRxNumber = "";
            }
        });
    }
    routeToEditRx(item: any) {
        if (item) {
            this.selectedrxNo = item["Rx#"];
            this.selectedrxId = item.data.PrescReFill.Id;
        }
        if (this.checkHasPrivsToAccess(AccCategoryE["Rx"], RxE["EditRx"])) {
            this.routeToRx();
        } else {
            this.openOveride("EditRx");
        }
        sessionStorage.removeItem("ActiveId");
    }

    routeToPatientHistory(item) {
        this._patPreDrugService._patPreDrugModal$.next("patHist");
        const modalRef = this._patPreDrugService.modalInstanceRef;
        modalRef.componentInstance.patientID = this.selectedRxInfo && this.selectedRxInfo.Patient ? this.selectedRxInfo.Patient.patientid : null;
        modalRef.componentInstance.popUpOpenFr = "rph";
        modalRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(closed => {
            modalRef.close();
            this._patPreDrugService._patPreDrugModal$.next(null);
            this.getGridRefresh(true);
        });
    }
    async routeToRx(rxNo?: any, rfId?: any, val?: string) {
        const selectedrxNo = rxNo ? rxNo : +this.selectedrxNo;
        const selectedrxId = rfId ? rfId : this.selectedrxId;
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true,null,null,null,true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugService._patPreDrugModal$.next("Rx");
            const modalRef = await this._patPreDrugService.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(x => {
                modalRef.close();
                this._patPreDrugService._patPreDrugModal$.next(null);
                this.getGridRefresh(true);
            });
            modalRef.componentInstance.CloseAndReopen
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    this._patPreDrugService._patPreDrugModal$.next(null);
                    if (d) {
                        this.routeToRx(d["rxNo"], d["rfId"]);
                    } else {
                        this.getGridRefresh(true);
                    }
                });
    }
    }
    checkHasPrivsToAccess(category: any, privType: any) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
            }
    }

    openOveride(content) {
        this.privType = content;
        this.categoryId = "Rx";
        this.openOverride = true;
    }

    ngOnDestroy() {
        this._commonStore.storeRphFilters(null);
    }
    

}
