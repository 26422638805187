import { ControlFileService } from "./../../../services/control-file.service";
import { ColorCode } from "./../../../utils/his-color.util";
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnChanges,
    HostListener,
    ChangeDetectorRef
} from "@angular/core";
import { RxService, AlertService, UserService, CommonService, DrugService, TransmissionService, PatPreDrugModalService, AdditionalInsuranceService, InsuranceService, EditDrugService } from "src/app/services";
import { CollectionView  } from "@grapecity/wijmo";
import {  NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    RxInputParams,
    SystemData,
    AccCategoryE,
    RefillRxE,
    Rx1E,
    ControlRxProcessingE,
    RxE,
    ERxDetailsViewModel,
    PartialCummulativeDetails
} from "src/app/models";
import * as moment from "moment";
import { QuickRefillService } from "src/app/services/quick-refill.service";
import { Router } from "@angular/router";
import {
    RxDeliveryInfoComponent,
    QuickVerfComponent,
    EquivalentDrugsComponent
} from "src/app/modules/shared";
import { PatHistoryComponent } from "../../patient-history/pat-history/pat-history.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { PrivMaskGuard } from "src/app/guards";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators";
import { empty as observableEmpty, Observable, Subscription, Subject } from "rxjs";
import { CommonStore, RxStore } from "src/app/store";
import { CommonPrintService } from "src/app/services/common-print.service";
import { NRxUtils, NRxSaveUtils, CommonUtil, PatchRXFGUtil,RxUtils } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { MsgConfig } from "src/app";
import { WijimoUtil } from "src/app/utils";
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { PPopupsComponent } from "../../rx-shared/rx-grid/ppd/npatient/p-popups/p-popups.component";
import { HoverOver } from "src/app/utils/hover-over.util";
import { PopupsCommonLogicUtil } from "src/app/utils/popups-common-logic.util";
import { CommonWarnorconfirmPopupComponent } from '../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { RxTotalQtyComponent } from "../rx-total-qty/rx-total-qty.component";
import { HoaComponent } from "../hoa/hoa.component";
import { environment } from "src/environments/environment";
import { VaccineImmunizationComponent } from "../vaccine-immunization/vaccine-immunization.component";
import { FormatsUtil } from "src/app/utils/formats.util";
import { CommonLogicRxInfoUtil } from "src/app/utils/commonLogic-rxInfo.utils";
@Component({
    selector: "app-quick-refill",
    templateUrl: "./quick-refill.component.html"
})
export class QuickRefillComponent implements OnInit, OnChanges {
    @Input() RxQtyScreenPage: string;
    quickModelRef: any;
    DateFrmGrp: FormGroup;
    frmWz = false;
    notPicked: boolean;
    DrugExpire: boolean;
    CheckDelv: boolean;
    pharName: any;
    techName: any;
    pharmacy: any;
    userFullName: any;
    techIniMNDT: boolean;
    pharmIniMNDT: boolean;
    LoginVerifyFG: FormGroup;
    disablePass: boolean;
    InvalidPassword1: boolean;
    InvalidPassword: boolean;
    TechVerifyFG: FormGroup;
    openPopType: any;
    formGroupInvalid: boolean;
    formGroupInvalid1: boolean;
    ctrlStngFile: any;
    fileFG: FormGroup;
    isFiledNew: any;
    newRxFileRxNo: any;
    newRxFileRfId: any;
    CashRx: boolean;
    systemData: SystemData;
    openOverride: boolean;
    overrideFor: string;
    categoryId: any;
    privType: any;
    accessPrivCate: any;
    refillRxPrivs: any;
    Rx1Priv: any;
    controlRxPrivs: any;
    rxPrivs: any;
    overrideEndpoint: any;
    adminPop: boolean;
    deliveryPop: boolean;
    quickVerpopup: boolean;
    credintialpopup: boolean;
    hasData: boolean;
    FileRxPopUp: boolean;
    frmQueue: any;
    inactiveDataType: string;
    inactiveDataName: any;
    equalentPrefDrugs: any[];
    equivalentDrugInfo: any;
    subscriptions = new Subscription();
    equivalentDrugWJ: CollectionView;
    transmitButton: boolean;
    conWhereStop: boolean;
    toQuickRef: boolean;
    successRefills = [];
    transmisnData$: any;
    fromFileRx: boolean;
    UserLocked = false;
    selectedRxForRef: any;
    appUsrData: any[] = [];
    minDate: any;
    lotfgInvalif: boolean;
    lotInfoSaved: boolean;
    filedNew: any;
    model: any;
    insuChanged = false;
    drugNotAllowPFill: any;
    quickRefillPartial: boolean;
    insuNotAllowPFill: boolean;
    RfOrRx: any;
    alreadyRxSerPatch = false;
    DeliveryModeId:number;
    CntrlRefillStng: any;
    defaulteRxSerialNum: any;
    unsubscribe$: Subject<void> = new Subject();
    HOADetails:any;
    vacnImuneModelRef: any;
    activeModal: any;
    processRefReqRx: number;
    selcRxInfo: any;
    showOthrBtns: boolean;
    refDetails: any;
    drugInfo: any;
    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
        this.selectedRxs = this.rxInfo;
    }

    // @Input() rxFG$: Observable<FormGroup>;

    @Input()
    set SuccessRef(data: any) {
        this.successRefills = data;
    }

    @Input()
    set ToQuickRef(data: any) {
        this.toQuickRef = data;
        this.conWhereStop = true;
    }

    @Input()
    set FrWz(data: any) {
        this.frmWz = data;
        this.selectedRxs = this.rxInfo;
        this.hasData = true;
    }



    @Input()
    set FrRefQueue(data: any) {
        if (data) {
            this.frmQueue = data;
        } else {
            this.frmQueue = false;
        }
    }

    @Input() transmisnData: any;
    @Input() FromIntakeQueue: any;
    @Input() FrmScrn: any;
    @Input() SearchParams : any;


    @ViewChild("QUICKREFILL", { static: true })
    QUICKREFILL: any;

    @ViewChild("IsPickedRx", { static: true })
    IsPickedRx: any;

    @ViewChild("INACTIVE", { static: true })
    INACTIVE: any;

    @ViewChild("DrugExpiryDate", { static: true })
    DrugExpiryDate: any;

    @ViewChild("DrugLotReq", { static: true })
    DrugLotReq: any;

    @ViewChild("NotEligibleForQuickRefill")
    NotEligibleForQuickRefill: any;

    @ViewChild(PatHistoryComponent)
    patHis: any;

    @ViewChild("warnDrugInactive", { static: true })
    warnDrugInactive: any;

    @ViewChild("PHARM", { static: true })
    pharm: any;

    @ViewChild("TECHVERIFY", { static: true })
    TechVerify: any;


    @ViewChild("FILE", { static: true })
    FILE: any;

    @ViewChild("RefillInsuInactive", {static: true})
    RefillInsuInactive: any;

    @ViewChild("confirmParOrFull", {static: true})
    confirmParOrFull: any;

    @ViewChild("RxDiscontinued", {static: true})
    RxDiscontinued: any;

    @ViewChild("TreatmentType", {static: true})
    TreatmentType: any;

    rxRefillInfo: any;
    rxRefillWJ: CollectionView;
    modelRef: any;
    refillRx: any;
    rxInfo: any;
    isEligibleForRefill: boolean;
    refillStatus: String;
    patientID: any;
    showRxDelivery = false;
    transmitStatus: string;
    rxInputParams: RxInputParams = new RxInputParams();
    slctdRxObj: any = {};
    selectedRxs: any[] = [];
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    refill: any;
    hasRefillsToProcess: boolean;
    activeIndex = 0;
    noMoreRefillsLeft: boolean = false;
    arrRefStats: Array<any> = [];
    modalRefStats: any;
    insuActiveStatus: number;
    refillRxWijmo: wjcGrid.FlexGrid;
    insuranceIsActive: boolean;
    systemInfo$: Observable<SystemData>;
    openBillToPopup: boolean;
    warnMessage: string = '';
    rxSerialFG: FormGroup;
    rxFG: FormGroup;
    frmIconClick = false;
    isReqTrtmntTpe = false;
    treatmentCheckd = false;
    modalRefTrtmnt: any;
    notToAllow = false;
    prefDrugSetting: any;
    isRxTransferred: any = false;
    cntrlFileStngs$: Observable<any>;
    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    RefreshRxHistory = new EventEmitter<any>();

    @Output()
    CloseParent = new EventEmitter<null>();

    @Output()
    Refillsuccess = new EventEmitter<any>();

    @Output()
    IsPopUpClosedFromEasyRefill = new EventEmitter<any>();


    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) { // esc
            if (!(this.deliveryPop || this.quickVerpopup || this.adminPop || this.credintialpopup || this.FileRxPopUp)) {
                event.preventDefault();
                if (this.modelRef) {
                    this.closeModalOnDismissAndIncrementIndex();
                } else if (!this.modelRef && this.quickModelRef) {
                    this.closeModal();
                }
            } else if (this.credintialpopup) {
                event.preventDefault();
                this.modelRef.close();
                this.resetLoginFG("Tech");
                this.resetLoginFG("Pharm");
            }
        }
    }


    constructor(
        private _modalService: NgbModal,
        private _userService: UserService,
        private _quickRefilsvc: QuickRefillService,
        private _router: Router,
        private _alertSvc: AlertService,
        private _nrxSaveUtil: NRxSaveUtils,
        private _fb: FormBuilder,
        private _colorCode: ColorCode,
        public _commonServ: CommonService,
        private _rxService: RxService,
        private _ctrlFileSvc: ControlFileService,
        private _privMaskGuard: PrivMaskGuard,
        private _drugService: DrugService,
        private _transmsnServ: TransmissionService,
        private _rxStore: RxStore,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _cmnPrintService: CommonPrintService,
        private _nrxUtls: NRxUtils,
        private _commUtil: CommonUtil,
        private _additionalInsu: AdditionalInsuranceService,
        private _insuraceSvc: InsuranceService,
        private _wijimoUtils: WijimoUtil,
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
        private _pRxFGUtls: PatchRXFGUtil,
        private _rxSvc: RxService,
        private _hoverOver: HoverOver,
        private _popupLogics: PopupsCommonLogicUtil,
        private _rxUtils: RxUtils,
        private _cdr: ChangeDetectorRef,
        private _formatsUtil:FormatsUtil,
        private edtDrgServ: EditDrugService,
        private _cmnLogicRxUtil: CommonLogicRxInfoUtil,
        private _commonStore: CommonStore
    ) {
        this._commonStore.storeRfStage(null);
        this.setPrivs();
        this.setMinDate();
        this.getSystemData();
        this.creatFileVAndDateerifyFG();
        this.creatTechVAndLoginerifyFG();
        this._ctrlFileSvc.getControlFileSettings()
        // .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.ctrlStngFile = resp;
        });
        this.fileFG.controls["dueDateTime"].setValue(moment(new Date()).format("MM/DD/YYYY"));
        this.transmisnData$ = this._rxStore.transmtPatientBS$;
        this._commonServ.appUserData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.appUsrData = resp;
        });
        this.systemInfo$ = this._commonServ.systemData$;
        // this.rxFG$ = this._rxStore.rxFG$;
    }

    ngOnInit() {
        this.RfOrRx = this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
        this.pharName = this._userService.getToken("PhUserName");
        this.techName = this._userService.getToken("TechUserName");
        this.techIniMNDT = this._commonServ.getSetttingValue("RxSettings", "MAKE_TECH_INITIALS_MNDT") === "1" ? true : false;
        this.pharmIniMNDT = this._commonServ.getSetttingValue("RxSettings", "MAKE_PHARMA_INITIALS_MNDT") === "1" ? true : false;
        this.userFullName = this.techName ? (this.techName + "").toUpperCase() : (this.pharName + "").toUpperCase();
        this.prefDrugSetting =  +this._commonServ.getSetttingValue("RxSettings", "PRF_DRUG_SLCT_ON_RX_FILL");
        this.showRxDelivery = true;
        this.getRefillData();
            this._transmsnServ.isTransmnsCmpltd$
            .subscribe(x => {
                if (x === "quickRef") {
                    this.closeModalAndIncrementIndex();
                }
            });
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "quickRefillWJ") {
                this.patchDefaultValHeaders();
            }
        });
        this.rxSerialFG = this._fb.group({rxSerial: ""});
        // if(this.techName){
        //     this.setTechFG(this.techName)
        // }
    }
    creatTechVAndLoginerifyFG() {
        this.TechVerifyFG = this._fb.group({
            Intial: [],
            password: []
        });
        this.LoginVerifyFG = this._fb.group({
            Intial: [],
            password: []
        });
    }
    creatFileVAndDateerifyFG() {
        this.DateFrmGrp = this._fb.group({
            lotNumber: [],
            expDate: []
        });
        this.fileFG = this._fb.group({
            dueDateTime: [],
            description: [],
            parentPage: [],
            prescId: [],
            prescRefillId: [],
            createNewRefill: false
        });

    }
    setPrivs() {
        this.accessPrivCate = AccCategoryE;
        this.refillRxPrivs = RefillRxE;
        this.Rx1Priv = Rx1E;
        this.rxPrivs = RxE;
        this.controlRxPrivs = ControlRxProcessingE;
    }
    getSystemData() {
        this._commonServ.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.systemData = resp;
            }
        });
    }
    setMinDate() {
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
    }
    openSecurity(type) {
     //  this.closeModal();
        this.openPopType = type;
        if (this.pharmIniMNDT) {
            this.credintialpopup = true;
            this.setTechFG(this.LoginVerifyFG,this.pharName);
            this.modelRef = this._modalService.open(this.pharm, {backdrop: false, keyboard: false, centered: true});
        } else if (this.techIniMNDT && this.techName) {
            this.credintialpopup = true;
            this.setTechFG(this.TechVerifyFG,this.techName);
            this.modelRef = this._modalService.open(this.TechVerify, {backdrop: false, keyboard: false, centered: true });
        } else if(!this.pharmIniMNDT && !this.techName){
            this.credintialpopup = false;
            this.initiateRefillProcess();
            this.setTransmissionStatus(this.openPopType);
        }
    }
    closeQuickRefillPop() {
        if(this.quickModelRef) {
            this.quickModelRef.close();
            this.quickModelRef = null;
        }
    }

    toggleColorLegend() {
        this._alertSvc.showHelpText({RxStatus: true, DrugName: true, Status: false});
    }


    async verifyPharmIntial() {
        this.LoginVerifyFG.controls["Intial"].setErrors(null);
        this.updateMarkTouch(this.LoginVerifyFG, "Intial");
        if (this.LoginVerifyFG.value["Intial"]) {
            if ((this.LoginVerifyFG.value["Intial"]).toLowerCase() === (this.pharName).toLowerCase()) {
                // if (this.modelRef) {
                //     this.modelRef.close();
                //     this.modelRef = null;
                // }
                // if (this.techIniMNDT && this.techName) {
                //     this.setTechFG(this.TechVerifyFG,this.techName);
                //     //this.modelRef = this._modalService.open(this.TechVerify, { backdrop: false, keyboard: false, centered: true });
                // } else {
                //     // this.credintialpopup = false;
                //     // this.initiateRefillProcess();
                //     // this.setTransmissionStatus(this.openPopType);
                // }
            } else {
                const IsValid = await this._rxService.checkIsValidPharmacist(this.LoginVerifyFG.value["Intial"]).toPromise();
                if (IsValid) {
                    this.disablePass = false;
                } else {
                    this.LoginVerifyFG.controls["Intial"].setErrors({ invalidUser: true });
                    this.updateMarkTouch(this.LoginVerifyFG, "Intial");
                }
            }
        } else {
            this.disablePass = false;
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async verifyTechIntial(event) {
        this.TechVerifyFG.controls["Intial"].setErrors(null);
        this.updateMarkTouch(this.TechVerifyFG, "Intial");
        if (this.TechVerifyFG.value["Intial"]) {
            if ((this.TechVerifyFG.value["Intial"]).toLowerCase() === (this.techName).toLowerCase()) {
            //     if (this.modelRef) {
            //         this.modelRef.close();
            //         this.modelRef = null;
            //     }

            //     this.credintialpopup = false;
            //    this.initiateRefillProcess();
            //    this.setTransmissionStatus(this.openPopType);
            } else {
                const IsValid = await this._rxService.checkIsValidTechician(this.TechVerifyFG.value["Intial"]).toPromise();
                if (IsValid) {
                    this.disablePass = false;

                } else {
                    this.TechVerifyFG.controls["Intial"].setErrors({ invalidUser: true });
                    this.updateMarkTouch(this.TechVerifyFG, "Intial");
                }
            }
        } else {
            this.disablePass = false;
        }
    }

    updateMarkTouch(FG, control) {
        FG.controls[control].markAsUntouched();
        this._cdr.detectChanges();
        FG.controls[control].markAsTouched();
    }

    async verifyPassword(valueFG, type) {
        this.warnMessage = "";
        if (valueFG.valid) {
            if (type === "Pharm") {
                this.formGroupInvalid = false;
            } else {
                this.formGroupInvalid1 = false;
            }
            const resp = await this._rxService.checkTechicianPassword(valueFG.value.password,
                (valueFG.value.Intial).toLowerCase()).toPromise();
            if (resp && resp.Status === 1) {
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
                if (type === "Pharm" && this.techIniMNDT && this.techName) {
                    this.setTechFG(this.TechVerifyFG,this.techName);
                    this.modelRef = this._modalService.open(this.TechVerify, { backdrop: false, keyboard: false, centered: true });
                } else {
                    this.credintialpopup = false;
                    this.initiateRefillProcess(true);
                    this.setTransmissionStatus(this.openPopType);
                }
            } else {
                if (resp && resp.Status === 2) {
                    this.warnMessage = resp.IsAdminUser ?
                    "Admin user account is locked due to multiple attempts with wrong password. Please contact support team to unlock your account."  :
                    "Pharmacist/Technician user account is locked due to multiple attempts with wrong password. Please contact your Admin to unlock your account.";
                } else {
                    this.warnMessage = "Please enter correct password.";
                }
                //this.TechVerifyFG.reset();
            }
        } else {
            if (type === "Pharm") {
                this.formGroupInvalid = true;
            } else {
                this.formGroupInvalid1 = true;
            }
        }
    }

    ngOnChanges() {
        this.patientID = sessionStorage.getItem("PatientId");
    }

    getRefillData() {
        if (this.rxInfo && this.rxInfo.length > 1) {
            const arrPrescNum = new Array();
            if (!this.frmQueue) {
                for (const i in this.rxInfo) {
                    if (this.rxInfo[i]) {
                        if (this.rxInfo[i] && this.rxInfo[i].Prescription && this.rxInfo[i].Prescription.PrescNum) {
                            arrPrescNum.push(this.rxInfo[i].Prescription.PrescNum);
                        }
                    }
                }
            } else {
                for (const i in this.rxInfo) {
                    if (this.rxInfo[i]) {
                        if(this.rxInfo && this.rxInfo[i]  && this.rxInfo[i]["PrescNum"]) {
                            arrPrescNum.push((this.rxInfo[i]["PrescNum"]).toString());
                        }
                    }
                }
            }
            this.rxInputParams.prescNum = arrPrescNum.toString();
        } else {
            if (!this.frmQueue) {
                this.rxInputParams.prescNum = (this.rxInfo && this.rxInfo.length) ? this.rxInfo[0].Prescription.PrescNum : null;
            } else {
                this.rxInputParams.prescNum = (this.rxInfo && this.rxInfo.length) ? this.rxInfo[0]["PrescNum"] : null;
            }

        }
        this._quickRefilsvc
            .postRefillData(this.rxInputParams)
            // .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp["length"] > 0) {
                    this.rxRefillInfo = resp;
                    this.refillStatus = "READY TO REFILL";
                    this.generateRefillWJ(resp);
                    this.quickModelRef = this._modalService.open(
                        this.QUICKREFILL,
                        {
                            backdrop: false,
                            keyboard: false,
                            windowClass: "quickRefill--modal--Expand",
                            // size: "lg",
                            centered : true
                        }
                    );
                } else if (resp === null) {
                    this.IsPopUpClosed.emit(null);
                }
            });
    }
    checkControlDrugConfirmPopup() {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content", centered : true
        });
        modelRef.componentInstance.IsHeaderText ="Warning!!";
        modelRef.componentInstance.warnMsg = "Partial fill cannot be filled as it is past 72 hours, filling such prescriptions may lead to an audit.";
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            modelRef.close();
            this.initiateRefillProcess();
        });
    }
    generateRefillWJ(refillInfo) {
        this.rxRefillWJ = new CollectionView(
            refillInfo.map((Rx, i) => {
                const k = {};
                const patRx = Rx && Rx.Refdetail && Rx.Refdetail.Refilldetails && Rx.Refdetail.Refilldetails[0]
                 ? Rx.Refdetail.Refilldetails[0] : null;
                this.isRxTransferred = patRx.isRxTransferred;
                k["isRxTransferred"] = patRx.isRxTransferred
                k["RowId"] = i;
                k["Status"] =
                this._commUtil.getRxStatus(patRx.Prescription.RxStatusId, patRx.PrescReFill.StatusId, patRx.RxBill.StatusId);
                if (patRx.ResponseStatus === "Early To Refill" ||
                    patRx.ResponseStatus === "READYTOREFILL" ||
                    patRx.ResponseStatus === "Regular" || patRx.ResponseStatus) {

                    if ((patRx.Prescription.RxStatusId === 4 ||
                        patRx.PrescReFill.StatusId === 4)) {
                        if (this._commonServ.getSetttingValue(
                            "PharmacySettings",
                            "ALLOW_REFILL_TRANS_OUT"
                        ) === "1") {
                            patRx.ResponseStatus = "Transferred";
                        } else {
                            patRx.ResponseStatus = "transferred";
                        }
                    } else if (patRx.Prescription.RxStatusId === 2 ||
                        patRx.PrescReFill.StatusId === 2 || patRx.RxBill.StatusId === 6) {
                        patRx.ResponseStatus = "Deferred Rx";
                    }
                }
                k["Refill Status"] =  this._colorCode.checkRefillStatus(Rx);
                k["Patient Name"] =
                    patRx.Patient.lastname + ", " + patRx.Patient.firstname;
                k["Rx#"] = patRx.Prescription.PrescNum;
                k["Ref#"] = patRx.PrescReFill.ReFillNum;
                k["Fill#"] = this._commonServ.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription) ? patRx.PrescReFill.PartialFillNo : " ";
                k["Auth#"] = patRx.Prescription.ReFillNum;
                k["Auth Ref"] = patRx.Prescription.ReFillNum;
                if (
                    patRx.insuranceInfo &&
                    patRx.insuranceInfo.organization &&
                    patRx.insuranceInfo.organization.Name
                ) {
                    k["Insurance Name"] = patRx.insuranceInfo.organization.Name;
                }
                k["Qty Disp"] = patRx.PrescReFill.DispQty ? this.decimalPipe.transform(patRx.PrescReFill.DispQty, '1.3-3') : 0;
                k["PartialQtyRemaining"] = Rx ? Rx.RefillCount * patRx.PrescReFill.OrdQty - Rx.TotalDispQtyIncludingFiledRx : 0;
                k["QtyRem"] = patRx.partialCummulativeDetails && patRx.partialCummulativeDetails.TotRemQty ?
            patRx.partialCummulativeDetails.TotRemQty : 0;
            k["Qty Rem Partial fill"] = (patRx.PrescReFill.PartialIndStatus === 'C') ?  "" : (patRx.islatestpartialrefill ?
                (patRx && patRx.partialCummulativeDetails ? patRx.partialCummulativeDetails.TotRemQty : 0).toFixed(3) : "");
            k["Qty Rem P.fill"] =
            (patRx.PrescReFill.IsOverFillRx) ? Rx.TotalRemainingQty : k["Qty Rem Partial fill"] ;
                k["T.Rx Qty Rem"] = Rx.TotalRemainingQty ? Rx.TotalRemainingQty.toFixed(3) : 0;
                k["TotalRemainingQty"] = Rx.TotalRemainingQty ? Rx.TotalRemainingQty : 0;
                k["TQty Remaining"] = Rx && Rx.TotalRemainingQty && ((!patRx.isRxTransferred && +this.RfOrRx) || !(+this.RfOrRx)) ?
                Rx.TotalRemainingQty : 0;
                k["Drug Name"] = (patRx.Drug.drugname ? (patRx.Drug.drugname).toUpperCase() : "") + " " +
                    (patRx.Drug.strength ? patRx.Drug.strength : "") +
                    (patRx.Drug.drugformname ? patRx.Drug.drugformname : "");
                k["Fill Date"] = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
                    "MM/DD/YYYY"
                );
                k["Copay"] = patRx.PrescReFill.CoPay ? this.currencyPipe.transform(patRx.PrescReFill.CoPay) : "$0.00";
                k["Price"] = patRx.PrescReFill.Price ? this.currencyPipe.transform(patRx.PrescReFill.Price) : "$0.00";
                k["Prescriber Name"] =
                    patRx.Prescriber.prescriberlastname +
                    ", " +
                    patRx.Prescriber.prescriberfirstname;
                const refillStatus = ['Expired(User)' , "No Quantity" , "Refill Expired", "Expired Rx", "Deferred and Expired(User) Rx", "Deferred and Expired Rx","Expired and Discontinued Rx", "Expired(User) and Discontinued Rx",'No More Refills Left'];
                k["Future Order Date"] = (moment(moment().format(MsgConfig.DATE_FORMAT)).isBefore(moment(moment.utc(patRx.PrescReFill.OrderDtTm).local().format(MsgConfig.DATE_FORMAT)))) ? true : false;
                if (k["Refill Status"] && (refillStatus.includes(k["Refill Status"]) || (k["Refill Status"] === "Transferred" && (patRx.isRxTransferred)) || k["Future Order Date"])) {
                    k["IsSelected"] = false;
                    this.showOthrBtns = this.rxRefillInfo?.length === 1 ? true : false;
                } else {
                    this.hasData = true;
                    k["IsSelected"] = true;
                }
                if (patRx.PrescReFill && patRx.PrescReFill.DeliverModeId) {
                    k["DeliveryMode"] =
                        patRx.PrescReFill.DeliverModeId === 1
                            ? "Deliver"
                            : patRx.PrescReFill.DeliverModeId === 2
                                ? "Ship"
                                : patRx.PrescReFill.DeliverModeId === 3
                                    ? "Pick"
                                    : patRx.PrescReFill.DeliverModeId === 4
                                        ? "Waiting"
                                        : "Not Specified";
                    const duedays = Math.round(patRx.PrescReFill.SupplyDays) -
                        moment().startOf("day").diff(moment.utc(patRx.PrescReFill.FillDtTm).startOf("day"), "days");
                    k["Due Days"] = (k["Status"] === "T" || (k["Auth Ref"] === 0 && k["Ref#"] === 0)) ? 0 : duedays < 0 ? 0 : duedays;
                    k["DueDays"] = duedays;
                    k["Next Fill Date"] = moment(
                        patRx.PrescReFill.NextFillDt
                    ).format("MM/DD/YYYY");
                    k["DrugClass"] = patRx.Drug.drugclass;
                    const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                    const isDrugDiscontinued = patRx.Drug["discontdt"] && moment().startOf("day")
                        .isAfter(moment.utc(patRx.Drug["discontdt"]).local().add(days, "days"));
                    k["IsDrugDis"] = isDrugDiscontinued ? "YES" : "NO";
                    k["IsHoldRx"] = patRx.PrescReFill.IsRxHold;
                    k["RxExpirationDate"] = patRx.RxExpirationDate ?
                        patRx.RxExpirationDate === "0001-01-01T00:00:00" ? null :
                            moment(patRx.RxExpirationDate).format("MM/DD/YYYY") : null;
                    k["RxExpDate"] = patRx.RxExpirationDate ?
                    patRx.RxExpirationDate === "0001-01-01T00:00:00" ? null :
                    patRx.RxExpirationDate : null;
                    k["RxExpirationDue"] = k["RxExpirationDate"] ? moment().diff(
                        k["RxExpirationDate"],
                        "days"
                    ) : null;
                    k["IsPicked"] = patRx.pickupRx ? patRx.pickupRx.IsPickedUp === true ? true : false : false;
                    k["IsDiscountinued"] = k["IsDiscontinued"] = patRx.Prescription.IsDiscontinued;
                    k["IsDisctFromErx"] = patRx.Prescription.IsDisctFromErx;
                    k["Supply Days"] = patRx.PrescReFill.SupplyDays;
                    k["InsRefillDueConsumptionThreshold"] = (patRx.primaryInsurance && patRx.primaryInsurance.refillDueConsumptionThreshold) ? patRx.primaryInsurance.refillDueConsumptionThreshold : 0;
                    k["RxExpDateByUser"] = patRx.rxExpiryDateByUser ? patRx.rxExpiryDateByUser :  null;
                    k["Qty Rem Partial.fill"] = (patRx.PrescReFill.PartialIndStatus === 'C') ?  0 : (patRx.islatestpartialrefill ?
                        (patRx && patRx.partialCummulativeDetails ? patRx.partialCummulativeDetails.TotRemQty : 0).toFixed(3) : 0);
                    k["T.Rx Qty Rem"] = Rx.TotalRemainingQty ? Rx.TotalRemainingQty.toFixed(3) : 0;
                    k["Qty Rem P.fill"] = (patRx.PrescReFill.IsOverFillRx) ? Rx.TotalRemainingQty : k["Qty Rem Partial.fill"]  ;
                    k["rxExpiryDateByInsuranceOrControlSettings"] = patRx.rxExpiryDateByInsuranceOrControlSettings ? patRx.rxExpiryDateByInsuranceOrControlSettings === "0001-01-01T00:00:00" ? null : patRx.rxExpiryDateByInsuranceOrControlSettings :  null;
                }
                this.optedQuickRefill(k["IsSelected"], k);
                const OrdDate =  new Date(moment(patRx.PrescReFill.OrderDtTm).local().format("MM/DD/YYYY"));
                const FillDate =  new Date(moment(patRx.PrescReFill.FillDtTm).local().format("MM/DD/YYYY"));
                    k["rxThreshold"] = patRx.rxThreshold ? patRx.rxThreshold : "";
                    k["OrdFillDateDiff"] = (FillDate.getTime() - OrdDate.getTime()) / (1000 * 3600 * 24);
                    k["primaryInsurance"] = patRx.primaryInsurance ? patRx.primaryInsurance : null;
                    k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
                    k["RxHoverOver"] = this._hoverOver.getRxHoverOver(k, "notRx");
                    k["rxColorCoding"] = this._colorCode.showRxColorRefactoring(k,"notRx");
                    k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                return k;
            })
        );
    }


    openRxTotalQtyComponent(item) {
        const modalRef = this._modalService.open(
            RxTotalQtyComponent,
            {
                backdrop: false,
                size: "sm",
                windowClass: "mode_width_34",
                keyboard: false, centered : true
            }
        );
        modalRef.componentInstance.RxNumber = item["Rx#"];
        modalRef.componentInstance.AuthNumber = item["Auth#"];
        modalRef.componentInstance.RefNumber = item["Ref#"];
        modalRef.componentInstance.FromScreen = this.RxQtyScreenPage;
        modalRef.componentInstance.IsQuickRefill = item["IsQuickRefill"];
        modalRef.componentInstance.RxStatusObj ={RxHoverOver : item["RxHoverOver"] , RxColorCoding : item["rxColorCoding"]['classForBackground']};
        modalRef.componentInstance.CloseRxTotalQtyComponentPop
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(x => {
            modalRef.close();
            if(!x) {
                if(this.quickModelRef) {
                    this.quickModelRef.close();
                    this.quickModelRef = null;
                }
            }
        });
        modalRef.componentInstance.CloseRxTotalQtyForQuickRefill
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((quickrefillRxs: any) => {
            modalRef.close();
            if(quickrefillRxs && quickrefillRxs.length) {
                quickrefillRxs.map((itemRx,index) => {
                        if(!index) {
                            this.selectedRxs.splice(this.activeIndex,1,itemRx);
                        } else
                                 this.selectedRxs.splice(this.activeIndex+1,0,itemRx);
                });
            }  else{
                this.selectedRxs.splice(this.activeIndex,1);
            }
            if(this.selectedRxs && this.selectedRxs.length){
                this.initiateRefillProcess(true,"redFlag");
            }

        });
    }
    optedQuickRefill(isChecked: boolean, checkedVal: any) {
        if (isChecked) {
            if (this.rxRefillInfo && this.rxRefillInfo[checkedVal.RowId] && this.rxRefillInfo[checkedVal.RowId]["Refdetail"] &&
            this.rxRefillInfo[checkedVal.RowId]["Refdetail"]["Refilldetails"] &&
            this.rxRefillInfo[checkedVal.RowId]["Refdetail"]["Refilldetails"][0]) {
                const rxItem =   this.rxRefillInfo[checkedVal.RowId]["Refdetail"]["Refilldetails"][0];
                if(rxItem && rxItem['PrescReFill']) {
                    rxItem['PrescReFill']['SupplyDays'] = (this.rxRefillInfo[checkedVal.RowId] && this.rxRefillInfo[checkedVal.RowId]['PartialFillSupplyDays']) ? this.rxRefillInfo[checkedVal.RowId]['PartialFillSupplyDays']  : rxItem['PrescReFill']['SupplyDays'];
                    rxItem['PrescReFill']['DispQty'] = 
                    (this.rxRefillInfo[checkedVal.RowId].TotalRemainingQty < rxItem['PrescReFill']['DispQty']) ? this.rxRefillInfo[checkedVal.RowId].TotalRemainingQty : 
                    rxItem['PrescReFill']['DispQty'];
                }
                this.slctdRxObj[checkedVal.RowId] = this.rxRefillInfo[checkedVal.RowId]["Refdetail"]["Refilldetails"][0];;
            }
        } else {
            delete this.slctdRxObj[checkedVal.RowId];
        }
        const convrtdArray = Object.keys(this.slctdRxObj).map(
            i => this.slctdRxObj[i]
        );
        this.selectedRxs = convrtdArray;
    }

    setTransmissionStatus(transmitStatus) {
        this.transmitStatus = transmitStatus;
        if (this.transmitStatus === "Transmit" || this.transmitStatus === "ALL") {
            this.transmitButton = true;
        } else {
            this.transmitButton = false;
        }
    }

    createNewFiledRx(val) {
        this.isFiledNew = val;
    }

    async saveFileToInfo() {
        const fileV = this.fileFG.getRawValue();
        this.isFiledNew = fileV["createNewRefill"];
        this.fromFileRx = true;
        const rxInfo = this.refillRx;
        const oldInfo = JSON.parse(JSON.stringify(this.refillRx));
        this.FileRxPopUp = null;
        if (!this.isFiledNew) {//Assign new No
            rxInfo.Prescription.RxStatusId = 1;
            rxInfo.PrescReFill.StatusId = 1;
            rxInfo.RxBill.StatusId = 1;
            const resp = await this._nrxSaveUtil.updateRxInfo(rxInfo, oldInfo);
            if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
                if (this.modelRef) {
                    this.modelRef.close();
                }
                this.refillRx["PrescReFill"]["ModifiedDtTm"] = new Date();
                this.refillRx.Prescription.RxStatusId = 1;
                this.refillRx.PrescReFill.StatusId = 1;
                this.warnDrugExpiry();
            } else if (resp && isNaN(+resp.Message)) {
                if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                    this._alertSvc.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
                } else if (typeof resp.Message === "string") {
                this._alertSvc.error(resp.Message);
                }
            } else {
                this._alertSvc.error(
                    "Rx filing unsuccessful.", false, "long"
                );
            }
        } else {//Assign new Yes
            this._rxService
                .deleteNewFile(
                    rxInfo.Prescription.PrescNum,
                    rxInfo.PrescReFill.ReFillNum,
                    rxInfo.PrescReFill.Id,
                    rxInfo.PrescReFill.PartialFillNo
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && resp.PrescNum !== "0") {
                        if (this.modelRef) {
                            this.modelRef.close();
                        }
                        this._commonServ
                            .getRxDetailsByPrescNos([resp.PrescNum], null)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(rxdet => {
                                if (rxdet && rxdet.length > 0) {
                                    this.refillRx = rxdet[0];
                                    this.refillRx["PrescReFill"]["ReFillNum"] = 0;
                                    this.warnDrugExpiry();
                                }
                            });
                    } else {
                        this._alertSvc.error(
                            "Rx filing unsuccessful.", false, "long"
                        );
                    }
                });
        }
    }

    checkHasPrivsToAccess(category, privType) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "EditRx") {
                this.routeToRx();
            } else {
                this.checkIsRxDiscontinued();
            }
        } else {
            if (this.overrideFor !== "EditRx") {
                this.closeModalOnDismissAndIncrementIndex();
            }
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    initiateRefillProcess(isFromButton: boolean = false,IsFromRedFlag?) {
        if (this.selectedRxs && this.selectedRxs.length === 0) {
            this._alertSvc.error("Please select atleast one Rx.");
        }
        else {
            if(!IsFromRedFlag)
                  this.noMoreRefillsLeft = false;
            if (this.activeIndex < this.selectedRxs.length) {
                this.refillRx = this.selectedRxs[this.activeIndex];
                this.selectedRxForRef = (this.refillRx && this.refillRx.Prescription && this.refillRx.Prescription.PrescNum ) ?
                this.refillRx.Prescription.PrescNum : 0;;
                // const selectedItem = this.rxRefillWJ.items.find((item: any) => {return item['Rx#'] === this.selectedRxForRef});
                //As per EP-4459 Handling Overfill Scenarios    
                // if(!IsFromRedFlag && selectedItem && selectedItem['Refill Status'] === 'No More Refills Left With R.Qty') {
                //     this.noMoreRefillsLeft = true;
                //     this.openRxTotalQtyComponent({
                //         "Rx#": selectedItem['Rx#'],
                //         "Auth#": selectedItem['Auth#'],
                //         "Ref#": selectedItem['Ref#'],
                //         "RxHoverOver": selectedItem['RxHoverOver'],
                //         "rxColorCoding": selectedItem['rxColorCoding'],
                //         "IsQuickRefill": true
                //     });
                // } else {
                   
                // }
                this.supplyDaysCheck(isFromButton);

            } else {
                if(IsFromRedFlag && this.quickModelRef) {
                        this.quickModelRef.close();
                        this.quickModelRef = null;
                          this.IsPopUpClosed.emit(null);
                    this.CloseParent.emit(null);
                    this.RefreshRxHistory.emit();
                    }
                if (this.modalRefStats) {
                    this.modalRefStats.close();
                    this.modalRefStats = null;
                }
            }
        }
    }

    async supplyDaysCheck(isFromButton) {
        const languageCode = this.systemInfo$["source"]["value"].Lang.filter(
            (person) => person.Name === this.refillRx.Patient.langname
        )[0];
        const sigCodeList: string[] = ("" +this.refillRx.SigCodes.SigCode).toLowerCase().split(" ");
        const altCode = languageCode?.LangCode;
        const sigData = {
            sigcodes: sigCodeList,
            langcodes: ["enhyus", altCode?.replace("-", "hy").toLowerCase()],
        };
        const resp = await this._rxService.getSigCodeListByLangCode(sigData).toPromise();
        if (resp && !Object.keys(resp).length) {
            let rxList = null;
            let data = null;
            if (this.FrmScrn == 'RxHist') {
                rxList = this.selectedRxs;
                rxList.splice(0, this.activeIndex+1);
                data = { selectedRxs: [this.refillRx?.Prescription?.PrescNum], frm: this.FrmScrn, completeData: [this.refillRx?.Prescription?.PrescNum],patHistData: this.SearchParams?.value, quickRefillList: rxList};
                this.SearchParams?.controls?.["refillFrmHist"].patchValue(true);
            } else if (this.FrmScrn == 'RefillQueue') {
                rxList = this.rxInfo;
                rxList.splice(0, this.activeIndex+1);
                const refQueueData = this.rxInfo.map(val => {
                    return {Prescnum: val["Prescnum"], PrescrefillId: val["PrescrefillId"], isRefilled: false};
                });
                data = {selectedRxs: [this.refillRx?.Prescription?.PrescNum], frm: "RefQueue", queueData: refQueueData, completeData: [this.refillRx?.Prescription?.PrescNum], quickRefillList: rxList };
            } else if (this.FrmScrn == 'RefWzrd') {
                rxList = this.selectedRxs;
                rxList.splice(0, this.activeIndex+1);
                data = {selectedRxs: [this.refillRx?.Prescription?.PrescNum], frm: "ERW", completeData: [this.refillRx?.Prescription?.PrescNum], quickRefillList: rxList};
            } else if (this.FromIntakeQueue) {
                rxList = this.rxInfo;
                rxList.splice(0, this.activeIndex+1);
                const refQueueData = this.rxInfo.map(val => {
                    return {Prescnum: val["Prescnum"], PrescrefillId: val["PrescrefillId"], isRefilled: false};
                });
                data = {selectedRxs: [this.refillRx?.Prescription?.PrescNum], frm: "intakeQueue", completeData: [this.refillRx?.Prescription?.PrescNum], queueData: refQueueData, quickRefillList: rxList};
                this._commonStore._prevoiusScreenInRefill$.next("intakeQueue");
            }
            this._commonStore.storeRefInfo(data);
            this._commonStore.storeRfStage("Start");
            this.closeModal();
        } else {
            const getHours =  (this.refillRx && this.refillRx["PrescReFill"] && this.refillRx["PrescReFill"].FillDtTm) ?  moment().startOf("day").diff(moment(this.refillRx["PrescReFill"].FillDtTm).startOf("day"), "hours") : null;
                if(isFromButton && getHours && this.refillRx && this.refillRx["Drug"] && this.refillRx["Drug"]["drugclass"] &&
                +this.refillRx["Drug"]["drugclass"] == 2 && getHours > 72) {
                        this.checkControlDrugConfirmPopup();
                } else if (this.refillRx && this.refillRx.Drug && this.refillRx.Drug.drugclass &&
                    this.refillRx.Drug.drugclass >= 2 && this.refillRx.Drug.drugclass <= 5 &&
                    !this.checkHasPrivsToAccess(AccCategoryE["RefillRx"], RefillRxE["AllowControlRxProcessing"])
                ) {
                    this.openOveride("Rx1", "ControlRxProcessing", "PUT_Rx_QuickRefill");
                } else if (this.refillRx.PrescReFill.IsRxHold) {
                    const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                        backdrop: false,
                        keyboard: false, 
                        windowClass: "large--content"
                    });
                    modelRef.componentInstance.warnMsg = 'Hold Rx cannot be refilled. To unhold and refill this Rx, Please click on YES.'
                    modelRef.componentInstance.IsHeaderText = "Confirmation";
                    modelRef.componentInstance.yesButton = true;
                    modelRef.componentInstance.noButton = true;
                    modelRef.componentInstance.IsPopUpClosed
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((resp: any) => {
                        modelRef.close();
                        if(resp)
                            this.unHoldRx();
                        else
                            this.closeModalOnDismissAndIncrementIndex();
                        });
                }
                else {
                    this.checkIsRxDiscontinued();
                }
        }
    }

    async unHoldRx() {
        this.refillRx["PrescReFill"]["IsRxHold"] = false;
        await this._rxService.updateRxInfo(this.refillRx).toPromise();
        this.checkIsRxDiscontinued();
    }

    checkIsRxDiscontinued() {
        if (this.refillRx && this.refillRx.Prescription && this.refillRx.Prescription.IsDiscontinued) {
            this.modelRef = this._modalService.open(this.RxDiscontinued, { centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
        } else {
            this.processQuick();
        }
    }

    openInActive(Name, Type) {
        this.inactiveDataType = Type;
        this.inactiveDataName = Name.replace("_", ",");
        this.modelRef = this._modalService.open(this.INACTIVE, { backdrop: false, keyboard: false, centered: true, windowClass: "large--content"});
    }

    checkInsuranceIsInactive() {
        if (this.refillRx && this.refillRx.Patient && this.refillRx.Patient.insurance && this.refillRx.Patient.insurance.length) {
            this.refillRx.Patient.insurance.map((item: any) => {
                if (this.refillRx.RefillInsu.InsuId === item["insuranceid"]) {
                    this.insuActiveStatus = item["ActiveStatus"];
                    this.insuranceIsActive = item["insurerActiveStatus"];
                }
            });
        }
    }

   async processQuick(isAlreadyActive: boolean = false) {
    if (this.rxFG && !this.rxFG.controls["TreatmentTypeId"].value && this.treatmentCheckd) {
        this.notToAllow = true;
        this.rxFG.controls["TreatmentTypeId"].markAsTouched();
        this.rxFG.controls["TreatmentTypeId"].setErrors({ required: true });
    } else if (this.modalRefTrtmnt) {
        this.notToAllow = false;
        this.modalRefTrtmnt.close();
        this.modalRefTrtmnt = null;
    }
    if (!this.notToAllow) {
    const stateVal = this._commonServ.getSetttingValue("TenantSettings", "TNT_STATE");
    if(this.ctrlStngFile?.ControlSetting?.length) {
        this.ctrlStngFile.ControlSetting.map((item: any) => {
            if ((this.refillRx && this.refillRx["Drug"] && this.refillRx["Drug"]["drugclass"] && this.refillRx["Drug"]["drugclass"] >= 2 && this.refillRx["Drug"]["drugclass"] <= 5) &&
            item.IsTreatmentTypeRequired && ((item.State === this.refillRx.Patient["state"]) || (item.State === stateVal))) {
                this.isReqTrtmntTpe = true;
            }
        });
    }
    if (!this.frmIconClick && this.rxFG) {
        this.refillRx.Prescription.PrescSN = this.rxFG.controls["Prescription"].value["PrescSN"];
    }
    if (this.treatmentCheckd && this.rxFG) {
        this.treatmentCheckd = true;
        this.refillRx.TreatmentTypeId = this.rxFG.controls["TreatmentTypeId"].value;
    }
        if (!isAlreadyActive) {
           await this.checkInsuranceIsInactive();
        }
        if (this.refillRx.Patient["actstatus"] !== 1) {
            this.openInActive(this.refillRx.Patient["fullname"], "Patient");
        } else if (this.refillRx.Prescriber["activestatusid"] !== 1) {
            this.openInActive(this.refillRx.Prescriber["fullname"], "Prescriber");
        } else if (this.refillRx && this.refillRx.Drug && (!this.refillRx.Drug["isactive"]) ) {
            this.inactiveDataType = "Drug";
            this.inactiveDataName = this.refillRx.Drug["drugname"];
            this.getEquivalentDrugInfo(this.refillRx.Drug);
        } else if (!this.insuranceIsActive && !isAlreadyActive) {
                this.openBillToPopup = true;
                let rxFG = this._pRxFGUtls.createNewRxFG();
                if (this.rxRefillInfo && this.rxRefillInfo.length && this.rxRefillInfo[0] && this.rxRefillInfo[0].Refdetail && this.rxRefillInfo[0].Refdetail.Refilldetails && this.rxRefillInfo[0].Refdetail.Refilldetails.length && this.rxRefillInfo[0].Refdetail.Refilldetails[0]) {
                rxFG = this._pRxFGUtls.patchRxInfo(this.rxRefillInfo[0].Refdetail.Refilldetails[0], rxFG);
                }
                this.rxRefillInfo.shift();
                const modalRef = this._modalService.open(PPopupsComponent, {
                    size: "lg",
                    backdrop: false,
                    keyboard: false
                });
                modalRef.componentInstance.rxFG = rxFG;
                modalRef.componentInstance.systemDataList = this.systemInfo$["source"]["value"];
                modalRef.componentInstance.fromQuickRefill = true;
                modalRef.componentInstance.InactIsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async resp => {
                    modalRef.close();
                        this.refillRx.RefillInsu.InsuId = resp.InsuId;
                        this.insuChanged = true;
                        this.processQuick(true);
                });
        } else if (this.insuActiveStatus === 2 && !isAlreadyActive) {
            this.model = this._modalService.open(this.RefillInsuInactive, { centered: true, backdrop: false,
                 keyboard: false, windowClass: "large--content"});
        } else if (this.checkRxSerialCond() && !this.alreadyRxSerPatch) {
            this.patchRxSerial();
            // this.frmIconClick = true;
        } else if (this.isReqTrtmntTpe && !this.treatmentCheckd) {
            this.treatmentCheckd = true;
            this.rxFG =  this._pRxFGUtls.createNewRxFG();
            this.rxFG.patchValue(this.refillRx);
            const resp = await this._rxSvc.getRxExtraDefaultValues(this.rxFG.value["PrescReFill"]["Id"] ?
            this.rxFG.value["PrescReFill"]["Id"] : 0).toPromise();
            if (resp && resp.rxExtra.TreatmentTypeId) {
                this.rxFG.controls["TreatmentTypeId"].patchValue(resp.rxExtra.TreatmentTypeId);
            }
            if (this.rxFG && !this.rxFG.value["TreatmentTypeId"]) {
            this.modalRefTrtmnt = this._modalService.open(this.TreatmentType, {backdrop: false, keyboard: false,
                centered: true,
                windowClass: "medium--content",});
            } else {
                this.notToAllow = false;
                this.processQuick();
            }
        } else if (this.refillRx.PrescReFill.StatusId === 2 || this.refillRx.Prescription.RxStatusId === 2) {
            this.checkIsFiled();
        } else if (!(this.refillRx.pickupRx && this.refillRx.pickupRx.IsPickedUp)) {
            this.modelRef = this._modalService.open(this.IsPickedRx, {backdrop: false, keyboard: false,
                 centered: true, windowClass: "large--content"});
        } else if (this.refillRx.pickupRx.IsPickedUp && this.refillRx.pickupRx.IsPickedUp !== null) {
            this.checkIsFiled();
        }
    }
    }

    async getEquivalentDrugInfo(drugInfo: any) {
        this.equivalentDrugInfo = await this._drugService.getEquivalentDrug(drugInfo.id, drugInfo.drugcode, false).toPromise();
        if (this.equivalentDrugInfo && this.equivalentDrugInfo.length > 0) {
                this.generateEquivalentDrugWJ(drugInfo);
        } else {
            this.modelRef = this._modalService.open(this.INACTIVE, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content" });
        }
    }

    generateEquivalentDrugWJ(drugInfo) {
        const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        this.equivalentDrugInfo.map(drug => {
            const isDrugDiscontinued = drug["discontdt"] && moment().startOf("day")
                .isAfter(moment.utc(drug["discontdt"]).local().add(days, "days"));
            drug["isdiscontdate"] = isDrugDiscontinued ? "YES" : "NO";
        });
        this.equivalentDrugWJ = new CollectionView(this.equivalentDrugInfo);
        const modalRef: any = this._modalService.open(EquivalentDrugsComponent,
            { backdrop: false, size: "lg", keyboard: false, centered: true, windowClass: "md-xx-lg" });
        modalRef.componentInstance.EquivalentDrugWJ = this.equivalentDrugWJ;
        console.log( drugInfo["isdiscontdate"]);
        modalRef.componentInstance.EqulDrugPopType = drugInfo["isdiscontdate"] === "YES"  ? "Disc": "QuickInactive";
        modalRef.componentInstance.DiscDrugInfo = this.refillRx.Drug;
        this.subscriptions.add(
            modalRef.componentInstance.OptedDrugInfo
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                const dataForMMS = {
                    NDCCode : resp?.item?.ndc ? resp?.item?.ndc : null
                }
                this._drugService.addDrugAutomaticallyFrmMMS(dataForMMS).pipe(takeUntil(this.unsubscribe$)).subscribe((resp)=>{
                    if(resp && resp.id){
                        this.refillRx.Drug =(resp && resp['item']) ?  resp['item'] : resp;
                        this.processQuick();
                    } else {
                        this._alertSvc.error("Drug Not found in the MMS Search");
                    }
               });
            })
        );
        this.subscriptions.add(
            modalRef.componentInstance.CloseAdminRxPopUp
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.closeModalOnDismissAndIncrementIndex();
            })
        );
    }

    async setInsuranceActive() {
        await this._additionalInsu.insuranceStatusSet(this.refillRx.RefillInsu.InsuId, 1).toPromise();
        this.processQuick(true);
    }

    async setPatientPrimaryInsurance() {

        if(this.refillRx && this.refillRx.Patient && this.refillRx.Patient.insurance && this.refillRx.Patient.insurance.length) {
            const primaryInsu = this.refillRx.Patient.insurance.find( x => x.insuranceid === +this.refillRx.Patient.insuranceid);
            this.refillRx.RefillInsu.InsuId = primaryInsu.insuranceid;
            this.insuChanged = true;
             this.processQuick(true);
        }
    }

    async setOrderStatus() {
        await this._rxService.putRxDiscontinuedOrderStatus(this.refillRx.Prescription.Id).toPromise();
        this.processQuick();
    }

    async checkIsFiled() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.refillRx["ResponseStatus"] === "Deferred Rx") {
            this.FileRxPopUp = true;
            const result = await this._commUtil.checkCanAssignNewRxFrFile(this.refillRx);
            this.filedNew = result["control"];
            this.fileFG.controls["createNewRefill"].patchValue(result["filed"]);
            this.modelRef = this._modalService.open(this.FILE, { centered: true, backdrop: false, keyboard: false});
        } else {
            this.fromFileRx = false;
            this.FileRxPopUp = null;
            this.checkIsPartial();
        }
    }

    async checkIsPartial() {
        if (this.refillRx && this.refillRx.PrescReFill && this.refillRx.PrescReFill.PartialIndStatus === "P") {
            if (this.refillRx.Prescription.ReFillNum <= this.refillRx.PrescReFill.ReFillNum) {
                    this.quickRefillPartial = true;
                    this.warnDrugExpiry();
            } else {
                this.quickRefillPartial = false;
                this.warnDrugExpiry();
            }
        } else {
            this.quickRefillPartial = false;
            this.warnDrugExpiry();
        }
    }

    afterPartialFillCheck(type) {
        this.quickRefillPartial = type;
        this.warnDrugExpiry();
    }

    checkDrugAllowPartial() {
        const drug = this.refillRx["Drug"];
        let result = false;
        if (drug && drug["drugclass"] && drug["drugclass"] >= 2 && drug["drugclass"] <= 5) {
            const resp = this._commonServ.controlFileSettings$["source"]["value"];
            if (resp && resp["ControlRefillOptions"] && resp["ControlRefillOptions"].length > 0) {
                const stng = resp["ControlRefillOptions"].find(x => x["DrugClass"] === drug["drugclass"]);
                result = stng && !stng["IsPartialFillAllowed"];
            }
        }
        return result;
    }

    async checkInsuAllowPartial() {
        let result = false;
        if (this.refillRx && this.refillRx.Patient && this.refillRx.Patient.insurance && this.refillRx.Patient.insurance.length) {
            const insurance = this.refillRx.Patient.insurance.find(item => this.refillRx.RefillInsu.InsuId === item["insuranceid"]);
            const insuStng = await this._insuraceSvc.getInsSettingsInfo(insurance.insuplanId).toPromise();
            if (insuStng && insuStng["InsuPlanPrice"] && !insuStng["InsuPlanPrice"]["IsPartialFillAllowed"]) {
                result = true;
            }
        }
        return result;
    }

    warnDrugExpiry() {
        this.lotInfoSaved = false;
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.DateFrmGrp.reset();
        if (!this.refillRx.PrescReFill.LotNum && this._commonServ.getSetttingValue("RxSettings", "MAKE_DRUG_LOT_MNDT") === "1") {
            this.modelRef = this._modalService.open(this.DrugLotReq, { backdrop: false, keyboard: false, centered: true});
        } else if ((this.refillRx.PrescReFill.LotNum && !this.refillRx.PrescReFill.LotExpiryDate) ||
            (this.refillRx.PrescReFill.LotExpiryDate && new Date(this.refillRx.PrescReFill.LotExpiryDate) < new Date())) {
            this.DateFrmGrp.controls["lotNumber"].patchValue(this.refillRx.PrescReFill.LotNum);
            this.modelRef = this._modalService.open(this.DrugExpiryDate, { backdrop: false, keyboard: false, centered: true, windowClass: "large--content"});
        } else {
            this.checkDeliveryInfo();
        }
    }

    async saveExpireDate() {
        if (!this.DateFrmGrp.valid) {
            this.lotfgInvalif = true;
        } else {
            this.lotfgInvalif = false;
            const data = {rxId: this.refillRx.PrescReFill.Id, lotNum: this.DateFrmGrp.value["lotNumber"],
            ExpDt: moment(this.DateFrmGrp.value["expDate"]).format("MM/DD/YYYY")};
            this.lotInfoSaved = true;
            // await this._quickRefilsvc.putRxExpiryDate(data).toPromise();
            this.refillRx.PrescReFill.LotNum = this.DateFrmGrp.value["lotNumber"];
            this.refillRx.PrescReFill.LotExpiryDate = moment(this.DateFrmGrp.value["expDate"]).format("MM/DD/YYYY");
            this.modelRef.close();
            this.checkDeliveryInfo();
        }
    }

    searchDrugLot = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(250),
            distinctUntilChanged(),
            switchMap((term) => {
                if (term) {
                    return this.searchByDrugLotTerm(term);
                } else {
                    return observableEmpty();
                }
            }))

    searchByDrugLotTerm(value: string) {
        if (value) {
            const data = {
                term: value,
                drugId: this.refillRx.Drug.id,
                bucketId: this.refillRx.PrescReFill.BucketId,
                fetchInActiveRecords: false
            }
            const drugLot = this._commonServ.getDrugLots(data);
            const resp: any = drugLot.toPromise();
            let finalLotList = [];
            if (resp && resp.length > 0) {
                finalLotList = resp.filter(val => val.IsActive &&
                    new Date(moment(val.ExpDt).format("MM/DD/YYYY")) >= new Date(moment().format("MM/DD/YYYY")));
            }
            if (resp && resp.length === 0) {
                this.DateFrmGrp.controls["lotNumber"].setValue(value);
            } else {
                this.optedDrugLot(value);
            }
            return drugLot;
        } else {
            return observableEmpty();
        }
    }

    optedDrugLot(val) {
        if (val && val.item && val.item.LotNum) {
            this.DateFrmGrp.controls["lotNumber"].setValue(val.item.LotNum);
            this.DateFrmGrp.controls["expDate"].setValue(moment(val.item.ExpDt).format("MM/DD/YYYY"));
        } else {
            this.DateFrmGrp.controls["lotNumber"].setValue(val);
            this.DateFrmGrp.controls["expDate"].setValue(null);
        }
    }

    drugLotFormatter = (x: any) => {
        return x.LotNum;
    }

    async checkDeliveryInfo() {
        //  ( this.refillRx.PrescReFill.DeliverModeId === 1 || this.refillRx.PrescReFill.DeliverModeId === 2 )
        if (false) {
            this.deliveryPop = true;
            const modalRef = this._modalService.open(RxDeliveryInfoComponent, {backdrop: false, keyboard: false, size: "lg"});
            modalRef.componentInstance.RxInfo = this.refillRx;
            modalRef.componentInstance.CloseDeliveryModal.subscribe(resp => {
                this.deliveryPop = false;
                modalRef.close();
                this.openRxAdmin();
            });
        } else {
            const data = await this.edtDrgServ.getDrugInfo(this.rxFG.value["Drug"]["id"]).toPromise();
            this._rxStore.storeEditDrugInfo(data);
            this.drugInfo = this._rxStore.edtDrugInfo$["source"]["value"];
            if (this.drugInfo?.Drug?.IsVaccine) {
                let rxFG = this._pRxFGUtls.createNewRxFG();
                if (this.refillRx) {
                rxFG = this._pRxFGUtls.patchRxInfo(this.refillRx, rxFG);
                }
                this.vacnImuneModelRef = this._modalService.open(VaccineImmunizationComponent, {
                    centered: true,
                    backdrop: "static",
                    keyboard: false,
                    size: "lg"
                });
                this.vacnImuneModelRef.componentInstance.rxFG = rxFG;
                this.vacnImuneModelRef.componentInstance.rxInfo = this.refillRx;
                this.vacnImuneModelRef.componentInstance.frmQuickRef = true;
                this.vacnImuneModelRef.componentInstance.IsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(async response => {
                        this.vacnImuneModelRef.close();
                        this.openRxAdmin();
                }); 
            } else {
                this.openRxAdmin();
            }
            
        }
    }

    async openRxAdmin() {
           if(!this.refillRx.Drug['ispreferred'] && this.prefDrugSetting && this.prefDrugSetting === 1) {
            const drugInfo = this.refillRx.Drug;
             this.equivalentDrugInfo = await this._drugService.getEquivalentDrug(drugInfo.id, drugInfo.drugcode, false).toPromise();
             const equalentPrefDrugs = this.equivalentDrugInfo.filter(x => x.ispreferred);
             const prescribedDrug = drugInfo['isbranded'];
             const equalentPrefDrugsPrescribed =
             equalentPrefDrugs.filter((item: any) =>  (prescribedDrug === item['isbranded'])  );
             if(equalentPrefDrugsPrescribed && equalentPrefDrugsPrescribed.length) {
                 this.prefDrugConfirmationPopUp((equalentPrefDrugsPrescribed.length == 1)? true: false);
             } else {
                 this.openHoaPopup();
             }
         } else if(!this.refillRx.Drug['ispreferred'] && this.prefDrugSetting && this.prefDrugSetting === 2) {
             const drugInfo = this.refillRx.Drug;
             this.equivalentDrugInfo = await this._drugService.getEquivalentDrug(drugInfo.id, drugInfo.drugcode, false).toPromise();
             const equalentPrefDrugs = this.equivalentDrugInfo.filter(x => x.ispreferred);
             const prescribedDrug = drugInfo['isbranded'];
             const equalentPrefDrugsPrescribed =
             equalentPrefDrugs.filter((item: any) =>  (prescribedDrug === item['isbranded'])  );
             if(equalentPrefDrugsPrescribed && equalentPrefDrugsPrescribed.length == 1) {
                 this.refillRx.Drug = equalentPrefDrugsPrescribed[0];
                 this.singlePreferredDrugAvaiableForAutoReplace(equalentPrefDrugsPrescribed );
             } else if(equalentPrefDrugsPrescribed && equalentPrefDrugsPrescribed.length > 1) {
                this.prefDrugsEquilentDrugs(this.equivalentDrugInfo);
             }  else {
                 this.openHoaPopup();
             }

         } else {
            this.openHoaPopup();
        }
    }
    openHoaPopup(){
        if (this.refillRx && this.refillRx.Patient && (this.refillRx?.Patient['isPkgAllowed'])  && environment.isHoa && !this.drugInfo?.Drug?.IsVaccine && 
        (this.refillRx.PrescReFill.IsHOADefined || this._commonServ.checkIsPartialFill(this.refillRx.PrescReFill)) && this.rxFG?.value?.Patient?.InterfaceId === 3) {
            const modalRef: any = this._modalService.open(HoaComponent, {
                backdrop: false,
                size: "lg",
                windowClass: "max-modal-78 modal-center-to-page",
                keyboard: false,
            });
            modalRef.componentInstance.OpenFrom = 'qrf';
            modalRef.componentInstance.RxInfo = this.refillRx;
            modalRef.componentInstance.rxfg = this.rxFG;
            modalRef.componentInstance.CloseAdminRxPopUp
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    (resp) => {
                        if(resp){
                            this.HOADetails = resp;
                        }
                        this.checkVerReq();
                    }
                );
        } else{
            this.checkVerReq();
        }
    }
    async checkVerReq() {
        // const alwQuckVerf = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "ALLOW_QUICK_VERIFICATION"), 0);
        // if (alwQuckVerf) {
        //     this.quickVerification();
        // } else {
            const message: any = await this._popupLogics.rxPriceValsInitiate(this.refillRx, "rf", this.quickRefillPartial);
            if (message) {
                this.modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content"
                });
                this.modelRef.componentInstance.warnMsg = message.messageText;
                this.modelRef.componentInstance.okButton = true;
                this.modelRef.componentInstance.IsPopUpClosed.subscribe((resp: any) => {
                    if (resp) {
                        this.closeModalAndIncrementIndex();
                    }
                });
            } else if (this.transmitStatus === "Hold" || this.transmitStatus === "Transmit") {
                this.processQuickRefill("R", true);
            } else {
                this.processQuickRefill("R", false);
            }
        // }
    }

    async quickVerification() {
        // price calculations
        const message: any = await this._popupLogics.rxPriceValsInitiate(this.refillRx, "rf", this.quickRefillPartial);
        if (message) {
            this.modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                backdrop: false,
                keyboard: false, windowClass: "large--content"
            });
            this.modelRef.componentInstance.warnMsg = message.messageText;
            this.modelRef.componentInstance.okButton = true;
            this.modelRef.componentInstance.IsPopUpClosed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp: any) => {
                if (resp) {
                    this.closeModalAndIncrementIndex();
                }
            });
        } else {
            this.quickVerpopup = true;
            const modalRef: any = this._modalService.open(QuickVerfComponent,
                {backdrop: false, keyboard: false, windowClass: "max-modal-80"});
            modalRef.componentInstance.RxNumber = this.refillRx.Prescription.PrescNum;
            modalRef.componentInstance.RxInfo = this.refillRx;
            modalRef.componentInstance.appUserData = this.appUsrData;
            modalRef.componentInstance.QuickVerfRes
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(v => {
                this.quickVerpopup = false;
                modalRef.close();
                this.processQuickRefill(v, (this.transmitStatus === "Hold" || this.transmitStatus === "Transmit") ? true : false);
            });
        }
    }

    checkIsCashRx(refillRx) {
        let isCashRx = false;
        let primaryInsu = null;
        if (refillRx.Patient.insurance && refillRx.Patient.insurance.length > 0) {
            primaryInsu = refillRx.Patient.insurance.find(val => val["insuranceid"] === refillRx.RefillInsu.InsuId);
        }
        if ((primaryInsu &&
            (primaryInsu["IsCash"] === true || primaryInsu["insurerid"] === this.systemData["DefaultValuese"][0]["CashInsurerId"]))) {
                isCashRx = true;
            // type = false;
        } else {
            isCashRx = false;
        }
        return isCashRx;
    }
    async processQuickRefill(result, type) {
        this.adminPop = false;
        this.deliveryPop = false;
        this.quickVerpopup = false;
        let primaryInsu = null;
        if (this.fromFileRx) {
            this.CashRx = this.checkIsCashRx(this.refillRx);
        }
        if(this.refillRx && this.refillRx.Patient && this.refillRx.Patient.PatientInfo && this.refillRx.Patient.PatientInfo.DeliveryModeId && this.refillRx.Patient.PatientInfo.DeliveryModeId !== 5){
            this.DeliveryModeId = this.refillRx.Patient.PatientInfo.DeliveryModeId;
        } else {
           this.DeliveryModeId =  +this._nrxUtls.getSettingValue("RxSettings", "DEF_DELIVERY_METHOD") ?
                        +this._nrxUtls.getSettingValue("RxSettings", "DEF_DELIVERY_METHOD") : this._rxUtils.getDelModeDefVal();
        }

        //this.DeliveryModeId =   this._commUtil.getDeliveryMethodId(this.refillRx);

        if (!this.fromFileRx) {
            const data = {
                PrescRefillId: this.refillRx.PrescReFill.Id,
                PrescNum: this.refillRx.Prescription.PrescNum,
                isVerified: result  && result !== false ? "V" : "R",
                isHold: type,
                drugId: this.refillRx.Drug.id,
                TechUsername: this.techIniMNDT ? this.TechVerifyFG.value["Intial"] :
                this.techName && this.techName.trim() !== "" ? this.techName : null,
                PharmacistUsername: this.pharmIniMNDT ? this.LoginVerifyFG.value["Intial"] :
                this.pharName && this.pharName.trim() !== "" ? this.pharName : null,
                insuId: this.refillRx.RefillInsu.InsuId,
                isInsuChanged:  this.insuChanged,
                IsPartialfill: this.quickRefillPartial,
                IsOverFillRx: this.refillRx.PrescReFill.IsOverFillRx,
                TotalRemainingQty: ( this.rxRefillInfo &&  this.rxRefillInfo.length) ? this.rxRefillInfo[0].TotalRemainingQty : 0,
                RxSerialNum: this.refillRx.Prescription.PrescSN,
                TreatmentTypeID: this.refillRx.TreatmentTypeId,
                DeliveryModeId: this.DeliveryModeId,
                HOADetails: this.HOADetails
            };
            const quickResp = await this._quickRefilsvc.saveQuickRefill(data).toPromise();
            if (quickResp && quickResp.isDeletedFromQueue) {
                const dateParams = {
                    StartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
                    EndDate: moment().subtract(0, "days").format("YYYY-MM-DD")
                };
                this._commonServ.getSideNavCounts(dateParams);
            }
            if (quickResp && quickResp.result === -1) {
                let errorMessage = "Refill is being created for the selected Rx#:" + this.refillRx.Prescription.PrescNum + " by a different user on the same pharmacy.";
                this._alertSvc.error(errorMessage);
            } else if (quickResp && quickResp.result) {
                this._alertSvc.success("Refill completed successfully for Rx:" + this.refillRx.Prescription.PrescNum);
                this.Refillsuccess.emit({Prescnum : +this.refillRx.Prescription.PrescNum, PrescrefillId: +this.refillRx.PrescReFill.Id});
              /*
                const resp = await this._rxSvc.deleteFromQueue([{Prescnum : +this.refillRx.Prescription.PrescNum, PrescrefillId: +this.refillRx.PrescReFill.Id}],false).toPromise();
                if(resp) {
                    this.IsPopUpClosed.emit(null);
                } */
                this.refillRx.PrescReFill.Id = quickResp.result;
                if (this.lotInfoSaved) {
                    const lotdata = {
                        rxId: quickResp.result, lotNum: this.DateFrmGrp.value["lotNumber"],
                        ExpDt:  moment(this.DateFrmGrp.value["expDate"]).format("MM/DD/YYYY")
                    };
                    await this._quickRefilsvc.putRxExpiryDate(lotdata).toPromise();
                }
                const refDet = await this._commonServ.getRxDetailsByPrescNos([this.refillRx.Prescription.PrescNum], null).toPromise();
                this.refillRx = refDet[0];
                this.CashRx = this.checkIsCashRx(this.refillRx);
                if (result && result !== false) {
                    this.refillRx["PrescReFill"]["isVerified"] = true;
                } else {
                    this.refillRx["PrescReFill"]["isVerified"] = false;
                }
                const vacnImmuneDet = this._rxStore.vacnImmune$["source"]["value"];
                if (vacnImmuneDet) {
                    vacnImmuneDet.PatientId = this.refillRx.Patient.patientid;
                    vacnImmuneDet.PrescRefillId = this.refillRx["PrescReFill"]["Id"];
                    vacnImmuneDet.PrescNum =  this.refillRx["Prescription"]["PrescNum"];
                    vacnImmuneDet.RefillNum = this.refillRx["PrescReFill"]["ReFillNum"];
                    vacnImmuneDet.FillNo = this.refillRx["PrescReFill"]["PartialFillNo"];
                    vacnImmuneDet.BucketId = this.refillRx["PrescReFill"]["BucketId"];
                    vacnImmuneDet.DrugId = this.refillRx["PrescReFill"]["DrugId"];
                    const payLoad = {
                        VaccineImmunization: vacnImmuneDet
                      }
                      this._commonServ.updateVaccineImmunization(payLoad).subscribe((resp: any) => {
                        if (resp) {
                          this._rxStore.storeDurNewRxFlow(null);
                          this._alertSvc.success("Vaccine Immunization Details saved successfully.");
                        }
                      });
                }
                // await this._nrxUtls.saveQuikcVerfication(this.refillRx, result, null);
                if (this.transmitStatus === "ALL" && !this.CashRx) {
                    this.transmitRx();
                } else {
                    if (this.CashRx) {
                        const showLabelPopup = this._commonServ.getSetttingValue("LabelSettings", "SHOW_LABEL_PRINT_POPUP_WHILE_FILLING_RX") === "1" ? true : false;
                        // this.refillRx.PrescReFill.PartialFillNo  =  this._commonServ.checkIsPartialFill(this.refillRx.PrescReFill.PartialFillNo,
                        //     this.refillRx.PrescReFill.PartialIndStatus) ? this.refillRx.PrescReFill.PartialFillNo : " - ";
                        this._cmnPrintService.printLabelToRx(this.refillRx, !showLabelPopup);
                        if (showLabelPopup) {
                            this._cmnPrintService.isLabelPrintCompleted()
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(resp => {
                                if (resp && resp.PrescNum === this.refillRx.Prescription.PrescNum && resp.PrescReFill === this.refillRx.PrescReFill.Id) {
                                    this.closeModalAndIncrementIndex();
                                }
                            });
                        } else {
                            this.closeModalAndIncrementIndex();
                        }
                    } else {
                        this.closeModalAndIncrementIndex();
                    }
                }
            } else {
                this._alertSvc.error("Quick refill save unsuccessful.");
                this.closeModalOnDismissAndIncrementIndex();
            }
        } else {
            if (result && result !== false) {
                this.refillRx["PrescReFill"]["isVerified"] = true;
            } else {
                this.refillRx["PrescReFill"]["isVerified"] = false;
            }
            // await this._nrxUtls.saveQuikcVerfication(this.refillRx, result, null);
            if (this.transmitStatus === "ALL" && !this.CashRx) {
                this.refillRx["Prescription"]["RxStatusId"] = this.refillRx["Prescription"]["RxStatusId"] === 3 ? 3 : 1;
                this.refillRx["PrescReFill"]["StatusId"] = this.refillRx["PrescReFill"]["StatusId"] === 3 ? 3 : 1;
                this.refillRx["RxBill"]["StatusId"] = 1;
                const resp = await this._nrxSaveUtil.updateRxInfo(this.refillRx);
                this.Refillsuccess.emit({Prescnum : +this.refillRx.Prescription.PrescNum, PrescrefillId: +this.refillRx.PrescReFill.Id});
               /*
                const resp1 = await this._rxSvc.deleteFromQueue([{Prescnum : +this.refillRx.Prescription.PrescNum, PrescrefillId: +this.refillRx.PrescReFill.Id}],false).toPromise();
                if(resp1) {
                    this.IsPopUpClosed.emit(null);
                } */
                this.transmitRx();
            } else {
                if (this.transmitStatus === "Hold" && !this.CashRx) {
                    this.refillRx.PrescReFill["IsRxHold"] = true;
                } else if (this.CashRx) {
                    this.refillRx["Prescription"]["RxStatusId"] = 1;
                    this.refillRx["PrescReFill"]["StatusId"] = 1;
                    this.refillRx["RxBill"]["StatusId"] = 2;
                }
                const resp = await this._nrxSaveUtil.updateRxInfo(this.refillRx);
                if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
                    this._alertSvc.success("Rx Filled and Saved");
                    this.Refillsuccess.emit({Prescnum : +this.refillRx.Prescription.PrescNum,
                         PrescrefillId: +this.refillRx.PrescReFill.Id});
                    this.refillRx["PrescReFill"]["ModifiedDtTm"] = new Date();
                    if (this.CashRx) {
                        const showLabelPopup = this._commonServ.getSetttingValue("LabelSettings", "SHOW_LABEL_PRINT_POPUP_WHILE_FILLING_RX") === "1" ? true : false;
                        // this.refillRx.PrescReFill.PartialFillNo  =  this._commonServ.checkIsPartialFill(this.refillRx.PrescReFill.PartialFillNo,
                        //     this.refillRx.PrescReFill.PartialIndStatus) ? this.refillRx.PrescReFill.PartialFillNo : " - ";
                        this._cmnPrintService.printLabelToRx(this.refillRx, !showLabelPopup);
                        if (showLabelPopup) {
                            this._cmnPrintService.isLabelPrintCompleted()
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(resp => {
                                if (resp && resp.PrescNum === this.refillRx.Prescription.PrescNum && resp.PrescReFill === this.refillRx.PrescReFill.Id) {
                                    this.closeModalAndIncrementIndex();
                                }
                            });
                        } else {
                            this.closeModalAndIncrementIndex();
                        }
                    } else {
                        this.closeModalAndIncrementIndex();
                    }
                    // if (this.CashRx) {
                    //     this._cmnPrintService.printLabelToRx(this.refillRx, true);
                    // }
                    // this.closeModalAndIncrementIndex();
                } else if (resp && isNaN(+resp.Message)) {
                    if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                        this._alertSvc.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
                    } else if (typeof resp.Message === "string") {
                    this._alertSvc.error(resp.Message);
                    }
                    this.closeModalOnDismissAndIncrementIndex();
                } else {
                    this._alertSvc.error("Save Unsuccessfull.");
                    this.closeModalOnDismissAndIncrementIndex();
                }
            }
        }
    }

    transmitRx() {
        if (this.transmitStatus === "Transmit" || this.transmitStatus === "ALL") {
            this.refillRx.RefillInsu.InsuPriId = 1;
            if (this.CashRx) {
                this.closeModalAndIncrementIndex();
            } else {
                this._transmsnServ._transmsnModal$.next({
                    "type": this.transmitStatus, "Fm": "quickRef"
                });
                this._rxStore.storeRxInfo(this.refillRx);
            }
        } else if (this.transmitStatus === "Hold") {
            this.closeModalAndIncrementIndex();
        }
    }

    checkSelectedCompleted() {
        this._transmsnServ.setTranmsnComplted(null);
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        if (this.selectedRxs && this.activeIndex < this.selectedRxs.length) {
            this.activeIndex += 1;
            if (this.activeIndex < this.selectedRxs.length) {
                this.initiateRefillProcess();
            } else {
                if (!this.frmWz) {
                    this.RefreshRxHistory.emit();
                    this.closeModal();
                }  else if(this.frmWz) {
                    this.RefreshRxHistory.emit();
                    this.closeModal(true);
                } else {
                 this.CloseParent.emit(null);
                }
            }
        }
    }

    closeModalOnDismissAndIncrementIndex() {
        this.checkSelectedCompleted();
    }

    closeModalAndIncrementIndex() {
        this.checkSelectedCompleted();
    }

    routeToEditRx() {
        if (this.selectedRxs && this.selectedRxs.length === 0) {
            this._alertSvc.error("Please select at least one Rx.");
        } else if (this.selectedRxs && this.selectedRxs.length !== 1) {
            this._alertSvc.error("Please select only one Rx.");
        } else {
            if (this.checkHasPrivsToAccess(
                this.accessPrivCate.Rx,
                this.rxPrivs.EditRx)) {
                this.routeToRx();
            } else {
                this.openOveride("EditRx", "EditRx", "PUT_Rx");
            }
        }
    }

    async routeToRx(rxNo?: any , rxId?: any) {
        const selectedrxNo = rxNo ? rxNo : +this.selectedRxs[0].Prescription.PrescNum;
        const selectedrxId = rxId ? rxId : +this.selectedRxs[0].PrescReFill.Id;
        let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
            this._nrxUtls.createPatchStoreRxFG(rxInfo);
            this._nrxUtls.getCommonEndPoints(rxInfo);
            this._patPreDrugModalServ._patPreDrugModal$.next("Rx");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    modalRef.close();
                    this.postRefillData();
                    this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
            modalRef.componentInstance.CloseAndReopen
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    if (d) {
                        this.routeToRx(d["rxNo"], d["rfId"]);
                    } else {
                        this._patPreDrugModalServ._patPreDrugModal$.next(null);
                    }
                });
        }
    }

    closeModal(type?, isCloseType?) {
        if (this.modalRefStats) {
            this.modalRefStats.close();
            this.modalRefStats = null;
        }
        if (this.quickModelRef && (!type || !isCloseType)) {
            this.quickModelRef.close();
            this.quickModelRef = null;
        }
        if(type) {
            this.activeIndex = 0;
        }
        if((!isCloseType && this.frmWz) || !this.frmWz) {
            this.IsPopUpClosed.emit(null);
            this.CloseParent.emit(null);
        } else {
            this.IsPopUpClosedFromEasyRefill.emit(isCloseType);
        }
    }

    RefreshRxHistoryGrid() {
        if (!this.frmWz) {
            this.RefreshRxHistory.emit(true);
        } if (this.FromIntakeQueue) {
            this.IsPopUpClosed.emit();
        }
    }

    resetLoginFG(type) {
        if (type === "Pharm") {
            this.LoginVerifyFG.reset();
            this.LoginVerifyFG.markAsUntouched();
            this.formGroupInvalid = false;
        } else {
            this.formGroupInvalid1 = false;
            this.TechVerifyFG.reset();
            this.TechVerifyFG.markAsUntouched();
        }
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const colors = this._colorCode.showRxColorRefactoring(row.dataItem, "notRx");
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Refill Status") {
                const colors = this._colorCode.colorCodingForRefillStatus(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                   } else {
                        cell.style.background = "#FFF";
                        cell.style.color = "#000";
                    }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "";
            }
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.refillRxWijmo = flex;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("quickRefillWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("quickRefillWJ", storedWJ);
    }
    postRefillData() {
        this._quickRefilsvc
            .postRefillData(this.rxInputParams)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp["length"] > 0) {
                    this.rxRefillInfo = resp;
                    this.refillStatus = "READY TO REFILL";
                    this.generateRefillWJ(resp);
                } else if (resp === null) {
                    this.IsPopUpClosed.emit(null);
                }
            });
        }

    checkRxSerialCond() {
        let isRequired: any;
        this.rxFG =  this._pRxFGUtls.createNewRxFG();
        this.rxFG.patchValue(this.refillRx);
        const stateVal = this._commonServ.getSetttingValue("TenantSettings", "TNT_STATE");
        if (this.rxFG && this.rxFG.controls["Prescription"] && !this.rxFG.controls["Prescription"].value["PrescSN"]) {
        // const forceRxSerStng = this._commonServ.getSetttingValue("RxSettings", "Force_Rx_Serial_entry");
        // const CntrlRxSerReq = this._commonServ.getSetttingValue("ControlFileSettings", "Control_Rx_Serial_Required");
        let CntrlRxSerReqList = this._commonServ.getSetttingValue("ControlFileSettings", "Control_Rx_Serial_Required");
        // const insuSetngs = this._insuraceSvc.insuSettings$["source"]["value"];
        isRequired = false;
        // if (insuSetngs && insuSetngs.InsuPlanOther && insuSetngs.InsuPlanOther.IsRxSNReqd ) {
        //     isRequired = true;
        // } else
       /* let CntrlRxSerReq = [];
        if(typeof(CntrlRxSerReqList) == "string" && CntrlRxSerReqList.includes(",")){
            CntrlRxSerReqList = CntrlRxSerReqList.replace(/ /g, "");
            CntrlRxSerReq = CntrlRxSerReqList.split(",");
          }else{
            CntrlRxSerReq.push(CntrlRxSerReqList);
          }
         if (CntrlRxSerReq[0] === "C"  && (this.rxFG.value["Drug"]["drugclass"] >= 2 && this.rxFG.value["Drug"]["drugclass"] <= 5)) {
            isRequired = true;
        } else if (CntrlRxSerReq.includes(this.rxFG.value["Drug"]["drugclass"].toString())) {
            isRequired = true;
        } else {
            isRequired = false;
        }*/
        isRequired = ((stateVal === "NY" && CntrlRxSerReqList === "a")|| (stateVal === "TX" && this.rxFG.value["Drug"]["drugclass"] === 2 && CntrlRxSerReqList === "2")) ? true : false;
    } else {
        isRequired = false;
    }
        return isRequired;
    }

    patchRxSerial() {
        this.defaulteRxSerialNum = this._commonServ.getSetttingValue(
            "RxSettings",
            "Erx_default_serial_number"
        );
        this.cntrlFileStngs$ =
            this._commonServ.controlFileSettings$["source"]["value"];
        const controlRefillOptions = this.cntrlFileStngs$ && this.cntrlFileStngs$["ControlRefillOptions"] ? 
            this.cntrlFileStngs$["ControlRefillOptions"] : null;
        this.CntrlRefillStng = controlRefillOptions && controlRefillOptions.length &&
            controlRefillOptions.find(
                (item: any) =>
                    +item["DrugClass"] === +this.rxFG.value["Drug"]["drugclass"]
            );
        /*this.rxFG.controls["Prescription"].patchValue({PrescSN:
                this.CntrlRefillStng && this.CntrlRefillStng["ERxSerialNo"]
                ? this.CntrlRefillStng["ERxSerialNo"]
                : this.defaulteRxSerialNum
                ? this.defaulteRxSerialNum
                : null});*/
        this._cmnLogicRxUtil.patchRxSerialCmnLogic(this.rxFG, false, this.systemData,this.CntrlRefillStng)
        this.alreadyRxSerPatch = true;
        this.processQuick();
    }
    focusOnSave(event) {
        if (document.getElementById("saveButton")) {
            event.preventDefault();
            document.getElementById("saveButton").focus();
        }
    }
    patchtreatmentType(e) {
        if (e) {
            this.rxFG.controls["TreatmentTypeId"].patchValue(e.value.Id);
        }
    }
    prefDrugConfirmationPopUp(isSingle) {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.IsHeaderText = "Confirmation";
        modelRef.componentInstance.warnMsg =
        (!isSingle) ? "<h4>Preferred drugs are available but not selected. </h4> <div>There are multiple preferred drugs available. Would you like to replace?  </div>" :
        "<h4>Preferred drug is available but not selected.</h4><div> There is a preferred drug available. Would you like to replace?</div>";
        modelRef.componentInstance.yesButton = true;
        modelRef.componentInstance.noButton = true;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            modelRef.close();
            if(resp)
                    this.prefDrugsEquilentDrugs(this.equivalentDrugInfo);
             else
                   this.openHoaPopup();
        });
    }
    prefDrugsEquilentDrugs(equalentPrefDrugs) {
        const equalentPrefDrugsForDisplay = this.equivalentDrugInfo.filter(x => x.ispreferred);
        const modalRef: any = this._modalService.open(EquivalentDrugsComponent,
            { backdrop: false, size:"lg", keyboard: false, centered: true, windowClass: "md-xx-lg" });
        modalRef.componentInstance.EquivalentDrugWJ = new CollectionView(equalentPrefDrugsForDisplay);
        modalRef.componentInstance.EqulDrugPopType = "notPref";
        modalRef.componentInstance.IsFrom = "nDrug";
        modalRef.componentInstance.rxFG = this.rxFG.value;
        modalRef.componentInstance.DiscDrugInfo = this.refillRx.Drug;
        modalRef.componentInstance.emitValuesForeRx
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            modalRef.close();
            if(resp == 'IGNORE' ||  resp == 'Cancel') {
                this.openHoaPopup();
            } else {
                this.refillRx.Drug = resp;
                this.openHoaPopup();
            }
        });
        modalRef.componentInstance.CloseAdminRxPopUp
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if(resp)
                this.openHoaPopup();
        });
    }
    singlePreferredDrugAvaiableForAutoReplace(equivalentDrugInfo) {
        const oldDrug = this.rxFG.value["Drug"];
        const newNDC: any =    equivalentDrugInfo[0];
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg = "The selected Drug <b>'" +
        ((oldDrug['drugname'] ? oldDrug['drugname'] : '') + ' ' +(oldDrug['strength'] ? oldDrug['strength'] : '') +
        ' ' + (oldDrug['drugformname'] ? oldDrug['drugformname'] : "")).toUpperCase() + " - " +  (this._formatsUtil.getNDCFormat(oldDrug["ndc"])) +
        "'</b> , is auto replaced with the available single preferred drug <b>'"+
         ((newNDC['drugname'] ? newNDC['drugname'] : '') + ' ' + (newNDC['strength'] ? newNDC['strength'] : '') +
        ' ' + (newNDC['drugformname'] ? newNDC['drugformname'] : "")).toUpperCase() + " - " + (this._formatsUtil.getNDCFormat(newNDC['ndc'])) +  "'</b>.";
        modelRef.componentInstance.IsHeaderText = "Information!!";
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            modelRef.close();
            this.openHoaPopup();
        });
    }

    setTechFG(frmGrp:FormGroup,name:string){
        frmGrp.controls["Intial"].patchValue(name);
        //this.TechVerifyFG.controls["password"].patchValue()
    }
    
    openRefillReq(val) {
        if (this.selectedRxs && this.selectedRxs.length === 0) {
            this._alertSvc.error("Please select the Rx.");
        }
        else {
            this.activeModal = val;
            this.processRefReqRx = 0;
            this.selcRxInfo = this.selectedRxs[this.processRefReqRx];
        }
    }
    closeRefilReq() {
        this.processRefReqRx++;
        if (this.processRefReqRx < this.selectedRxs.length) {
            this.openRefillReq(this.activeModal)
        } else {
            this.activeModal = null;
            this.generateRefillWJ(this.rxRefillInfo);
            this.selectedRxs  = [];
            this.processRefReqRx = 0;
        }
    }

    processCreateNew() {
        if (this.selectedRxs && this.selectedRxs.length === 0) {
            this._alertSvc.error("Please select the Rx.");
        }
        else {
            if (!this._privMaskGuard.canActivate(AccCategoryE["RefillRx"], RefillRxE["CreateNew"])) {
                this.openOveride("RefillRx", "CreateNew");
            } else {
                this.changeRxData("New");
            }
        }
    }
    changeRxData(type) {
        this.closeModal();
        this.refDetails = this.selectedRxs[0]
        this.refDetails["RxPay"] = null;
        this.refDetails["PrescReFill"]["FillDtTm"] = moment(new Date());
        this.refDetails["PrescReFill"]["OrderStatus"] = null;
        this.refDetails["Prescription"]["IsDiscontinued"] = false;
        this.refDetails["PrescReFill"]["IsNewPrescRefill"] = false;
        this.refDetails["PrescReFill"]["ReFillNum"] = 0;
        this.refDetails["PrescReFill"]["NumberOfFills"] = 0;
        this.refDetails["TotalRemainingQty"]= 0;
        this.refDetails["PrescReFill"]["PartialFillNo"] = 1;
        this.refDetails["PrescReFill"]["DispQty"] = this.refDetails["PrescReFill"]["OrdQty"];
        this.refDetails["PrescReFill"]["SupplyDays"] = this.refDetails["PrescReFill"]["OrderDays"];
        this.refDetails["PrescReFill"]["Id"] = 0;
        this.rxRefillInfo[0].TotalRemainingQty  = 0;
        this.refDetails["PrescReFill"]["PrescId"] = 0;
        this.refDetails["Prescription"]["Id"] = 0;
        this.refDetails["PrescReFill"]["PartialFillNo"] = 1;
        this.refDetails["RefillInsu"]["RefillId"] = 0
        this.refDetails["PrescReFill"]["ImmunizationId"] = null;
        this.refDetails["drugPickVerif"]= [];
        this.refDetails["rPHVerifs"]= [];
        this.refDetails["drugPickVerificationStatus"] = null;
        this.refDetails["rph1VerificationStatus"] = null;
        this.refDetails["rph2VerificationStatus"] = null;
        this.refDetails["PrescReFill"]["IsEquivalentDisp"] = false;
        this.refDetails["PrescReFill"]["OrderDtTm"] = moment(new Date());
        this.refDetails["Prescription"]["PrescNum"] = null;
        this.refDetails["eRxDetailsViewModel"] = new ERxDetailsViewModel();
        this.refDetails["PrescReFill"]["IsHOADefined"] = false;
        this.refDetails["partialCummulativeDetails"] = new PartialCummulativeDetails();
        this.refDetails["PrescReFill"]["Is340B"] = false;
        this.refDetails["PrescReFill"]["StatusId"] = 1;
        this.refDetails["TotalRemainingQty"] = this.rxRefillInfo[0].TotalRemainingQty;
        this.refDetails["Prescription"]["RxStatusId"] = 1;
        this.refDetails["RxBill"]["StatusId"] = 1;
        this.refDetails["PrescReFill"]["GroupTransStatus"] = null;
        this.refDetails["RefillInsu"]["InsuPriId"] = 1;
        this.refDetails["PrescReFill"]["IsControlSubmitted"] = null;
        this.refDetails["PrescReFill"]["IsIou"] = false;
        this.createAndPatchRxFG(type);
     }
     async createAndPatchRxFG(type?: any) {
        const rxFG = await this._nrxUtls.createPatchStoreRxFG( null, "rf");
        if(this.refDetails["RxOtherAmount"]) {
            rxFG.controls["RxOtherAmount"].patchValue(this.refDetails["RxOtherAmount"]);
        }
        rxFG.controls["rxFileType"].patchValue(type);
        rxFG.controls["TreatmentTypeId"].patchValue(this.rxRefillInfo[0]["TreatmentTypeId"]);
        this.refDetails['TreatmentTypeId'] = this.rxRefillInfo[0]["TreatmentTypeId"];
        rxFG.controls["selectedRxFromRefill"].patchValue(this.selectedRxs[0]["Prescription"]["PrescNum"]);
        rxFG.controls["FromNavigationForBenfitCheck"].patchValue(this.FrmScrn);
        this.refDetails["Prescription"]["PrescNum"] = this.refDetails["Prescription"]["PrescNum"] ?
        this.refDetails["Prescription"]["PrescNum"] : rxFG.value["Prescription"]["PrescNum"];
        rxFG.controls["rxType"].patchValue("nrf");
        rxFG.controls["isNewFrmExisting"].patchValue(true);
        const rxTechType = rxFG.controls["rxType"].value;
        this._pRxFGUtls.patchRxInfo(this.refDetails, rxFG, null, rxTechType);
        const otherAmount = (+this.refDetails["TaxAmt"]) + (+this.refDetails["OtherFee"]) + (+this.refDetails["OtherAmount"]);
        rxFG.controls["OtherAmount"].patchValue(otherAmount);
        this._nrxUtls.getCommonEndPoints(this.refDetails,'rf');
        rxFG.controls["rxType"].patchValue("nrf");
        this._router.navigate(["eprx/dashboard"]);
        setTimeout(() => {
            this._router.navigate(["/eprx/rx"], {
                queryParams: { fm: "nr" }
            });
        },0)
                
    }
}
